define("apollo/translations/tr", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "CARRIER": {
      "label": "Taşıyıcı",
      "panel": {
        "label": "Taşıyıcı kartı"
      }
    },
    "CLIENT": {
      "panel": {
        "label": "Müşteri kartı"
      }
    },
    "COMPANY": {
      "panel": {
        "label": "Şirket kartı"
      }
    },
    "OPERATOR": {
      "label": "Operatör",
      "panel": {
        "label": "Operatör kartı"
      }
    },
    "SERVICE": {
      "panel": {
        "label": "Hizmet kartı"
      }
    },
    "SERVICEMAN": {
      "panel": {
        "label": "Hizmet görevlisi kartı"
      }
    },
    "SUPPLIER": {
      "label": "Tedarikçi",
      "panel": {
        "label": "Tedarikçi kartı"
      }
    },
    "address": {
      "addNewAddress": "Yeni bir adres ekle",
      "addressUnloadingTime": {
        "daysToUnload": "Boşaltma günleri",
        "friday": "Cuma",
        "label": "Boşaltma zamanı",
        "monday": "Pazartesi",
        "saturday": "Cumartesi",
        "sunday": "Pazar",
        "thursday": "Perşembe",
        "tuesday": "Salı",
        "wednesday": "Çarşamba"
      },
      "autoCompletePlaceholder": "Bir şehir, sokak vb. aramak için yazmaya başlayın",
      "chooseCompanyFirst": "Lütfen önce bir şirket seçin",
      "city": "Şehir",
      "country": "Ülke",
      "deleteAddress": "Bu adresi silmek istediğinizden emin misiniz?",
      "errors": {},
      "label": "Adres",
      "newAddress": "Yeni adres",
      "postal": "Posta kodu",
      "province": "İl",
      "recipient": "Alıcı",
      "removeAddress": "Bir adresi kaldır",
      "street": "Sokak",
      "voivodeship": "Voyvodalık"
    },
    "administration": {
      "newPrincipal": {
        "aliasHint": "Rumuz tek kelime olmalı (tercihen müşterinin'adı), küçük harflerden oluşmalıdır (ör. logintegra)",
        "baseData": "Temel şirket verileri",
        "companyAddress": "Genel Merkez",
        "create": "Yeni ana şirket oluştur",
        "creation": "Yeni ana şirket oluşturuluyor",
        "successMsg": "Yeni ana şirket başarıyla kaydedildi. Artık hesabınızda oturum açabilirsiniz: {username}",
        "userData": "Kullanıcı verileri (SUPER_ADMIN)"
      }
    },
    "advice": {
      "add": "Bir ihbar ekle",
      "carRegistrationNumber.placeholder": "ör. GDA12345",
      "carRegistrationNumberLabel": {
        "TRUCK": "Kamyon ruhsat numarası",
        "VEHICLE": "Araç ruhsat numarası"
      },
      "carRegistrationNumberSimple": "Ruhsat numarası",
      "creator": "Bir ihbarı oluşturan",
      "dateCreated": "Oluşturma:",
      "documentNumber": "Sürücünün'belge numarası",
      "documentNumber.placeholder": "ör. 78646003",
      "documentType": {
        "ID_CARD": "Kimlik Kartı No.",
        "PASSPORT": "Pasaport"
      },
      "driverId": "Sürücünün'kimlik numarası",
      "driverLanguage": "Sürücünün'dili",
      "driverMobilePhoneNumber": "Cep telefonu numarası",
      "driverMobilePhonePrefix": "Sürücünün'cep telefonu numarasının alan kodu",
      "driverName": "Bir sürücünün adı ve soyadı",
      "driverName.placeholder": "ör. Ahmet Yılmaz",
      "errors": {
        "carRegistrationNumber": {
          "regex": "Ruhsat numarası, 4 ila 50 karakter olmalıdır ve yalnızca harf, rakam ve şu karakterleri içerebilir: , ; / \\\\ & _"
        },
        "driverMobilePhoneNumber": {
          "regex": "Polonya'da kayıtlı telefon numarası, dokuz rakamdan oluşmalıdır"
        },
        "rodoAccepted": {
          "notSelected": "Veriler yasal olarak işlenmelidir"
        },
        "saveAsResource": {
          "info": "Sürücüyü kaynak listesine kaydetmek için ihbar kısmındaki zorunlu alanların doldurulması gerekir."
        },
        "termsOfConditionsAccepted": {
          "notSelected": "İhbar göndermek için şartları ve koşulları kabul etmelisiniz"
        },
        "trailerRegistrationNumber": {
          "regex": "Römork/yarı römork ruhsat numarası, 4 ila 50 karakter olmalıdır ve yalnızca harf, sayı ve şu karakterleri içerebilir: , ; / \\\\ & _"
        }
      },
      "label": "İhbar",
      "pasteFromExcel": "İhbar verilerini yapıştır",
      "rodo": "Forma girilen veriler, Logintegra sp. z o.o. tarafından yasal olarak işlenebilir.",
      "saveAsResource": "Sürücü verilerini ve araç verilerini kaynak listesine kaydet",
      "termsOfConditionsAccepted": "Şunu kabul ediyorum: ",
      "trailerRegistrationNumber": "Römork/Yarı Römork ruhsat numarası",
      "trailerRegistrationNumber.placeholder": "ör. GDA6789",
      "transport": "Nakliye"
    },
    "afterRegistration": {
      "addWarehouseButton": "Bir depo ekle",
      "info": "Kendi deponuzu açın ve nakliyeler ile ihbarları planlamak için iş arkadaşlarınızı davet edin.",
      "invitationButton": "Davet listesi",
      "label": "Hoş geldiniz!",
      "title": "Kuyruklara veda etmek için doğru yoldasınız!"
    },
    "algorithmSettings": {
      "deleteSetting": "Bu ayarı silmek istediğinizden emin misiniz?",
      "deletionSuccessMessage": "{n} paletin algoritma ayarlarını başarıyla sildik",
      "label": "Zaman diliminin boyutunu hesaplayan algoritmanın ayarları",
      "loadTimeInMinutes": "Dakika cinsinden yükleme süresi",
      "numberOfPallets": "Palet sayısı (üst sınır)",
      "saveSuccessMessage": "Algoritma ayarlarını başarıyla kaydettik."
    },
    "ana-kısım": {
      "errors": {
        "hasErrors": "Ana veriler kısmında hatalar var. Lütfen zorunlu alanları doğru şekilde doldurun.",
        "krsFile": "Bir doğrulama talebi göndermek için zorunlu belgeyi bir KRS kanıtı olarak eklemeniz gerekiyor.",
        "nipFile": "Bir doğrulama talebi göndermek için zorunlu belgeyi bir NIP kanıtı olarak eklemeniz gerekiyor.",
        "regonFile": "Bir doğrulama talebi göndermek için zorunlu belgeyi bir REGON kanıtı olarak eklemeniz gerekiyor."
      }
    },
    "announcement": {
      "errors": {
        "companies": {
          "chooseAtLeastOneCompany": "En az bir şirket seçin"
        },
        "roleGroups": {
          "chooseAtLeastOneRoleGroup": "En az bir rol grubu seçin"
        }
      }
    },
    "announcements": {
      "add": "Bir duyuru ekle",
      "announcementInEnglish": "İngilizce duyuru",
      "companies": "Duyuruyu görecek şirketler:",
      "content": "Mesaj içeriği",
      "contentEn": "Mesaj içeriği (EN)",
      "dateCreated": "Oluşturulma tarihi",
      "dateFrom": "Son geçerlilik tarihinin başlangıcı:",
      "dateTo": "Son geçerlilik tarihinin bitişi:",
      "delete": "Bu duyuruyu silmek istediğinizden emin misiniz?",
      "edit": "Bir duyuruyu düzenle",
      "expirationDates": "Son geçerlilik tarihleri",
      "label": "Duyurular",
      "lastEditor": "Son düzenlemeyi yapan:",
      "lastUpdated": "Son düzenleme",
      "manage": "Duyuruları yönet",
      "noActiveAnnouncements": "Şu anda etkin duyuru yok.",
      "noAnnouncements": "Duyuru yok",
      "range": "Kullanıcı seçimi",
      "ranges": {
        "COMPANY": "Kullanıcıların şirketine' göre",
        "ROLE_GROUP": "Kullanıcıların rollerine' göre"
      },
      "roleGroups": "Duyuruyu görecek uygun rollerdeki kullanıcılar:",
      "subject": "Mesaj konusu",
      "subjectEn": "Mesaj konusu (EN)",
      "type": "İdari duyuru"
    },
    "apiIntegrationLogs": {
      "event": {
        "CHECKPOINT_CONFIRMED": "Kontrol noktası onaylandı",
        "TRANSPORT_CREATED": "Genel API tarafından nakliye oluşturma",
        "TRANSPORT_UPDATED": "Genel API tarafından nakliye güncellemesi",
        "TRANSPORT_UPDATED_MANUAL": "Nakliye güncellemesi"
      }
    },
    "appliesSettings": {
      "add": "Uygulama ayarları ekle",
      "description": {
        "createAntiCorruptionSection": "Yeni uygulamalara yolsuzlukla mücadele kısmı ekle",
        "showPatternToDownload": "İndirilecek formu göster",
        "showStatementToDownload": "İndirilecek imzalı ana şirket beyanını göster"
      },
      "label": "Uygulama ayarları",
      "noSettings": "Henüz uygulama ayarı yok"
    },
    "artrSettings": {
      "add": "ARTR ayarları ekle",
      "fields": {
        "entryGateCheckpointAlias": "Giriş görevinin rumuzu (bariyer)",
        "entryWhiteListCheckpointAlias": "Ruhsat numarasını beyaz listeye göndermesi gereken görevin rumuzu - giriş",
        "exitGateCheckpointAlias": "Çıkış görevinin rumuzu (bariyer)",
        "exitWhiteListCheckpointAlias": "Ruhsat numarasını beyaz listeye göndermesi gereken görevin rumuzu - çıkış",
        "expirationTimeInHours": "Beyaz listenin son geçerlilik zamanı (saat)"
      },
      "label": "ARTR ayarları",
      "noSettings": "Henüz ARTR ayarı yok"
    },
    "assortmentGroup": {
      "add": "Tasnif grubu ekle",
      "adviceFromTime": "İhbar başlangıcı:",
      "adviceToTime": "İhbar bitişi:",
      "confirmDeletion": "Tasnif grubunu silmek istediğinizden emin misiniz?",
      "confirmEdition": "Dikkat. Tasnif gruplarının ihbar saatleri değiştirildi. Devam etmek istediğinizden emin misiniz?",
      "errors": {
        "adviceFromTime": {
          "invalidAssortmentGroupAdviceFromTime": "İhbar, deponun çalışma saatlerinin içinde olmalıdır."
        },
        "adviceToTime": {
          "invalidAssortmentGroupAdviceToTime": "İhbar, deponun çalışma saatlerinin içinde olmalıdır."
        },
        "nameEn": {
          "minLength": "Giriş en az 3 karakter uzunluğunda olmalıdır"
        },
        "namePl": {
          "minLength": "Giriş en az 3 karakter uzunluğunda olmalıdır"
        }
      },
      "label": "Tasnif grubu",
      "listLabel": "Tasnif grupları",
      "transportTypes": "Tasnif grubunun kullanılabileceği nakliye türleri"
    },
    "auction": {
      "AUCTION": {
        "actualFinishTime": "İhalenin gerçek kapanış zamanı",
        "buttonLabel": "+ İhale",
        "creator": "İhaleyi'oluşturan",
        "currency": "İhalenin para birimi",
        "dateCreated": "İhalenin oluşturulması:",
        "endedWithoutOffers": "İhale teklif olmadan bitti",
        "finish": "İhaleyi bitir",
        "inProgress": "İhalenin bitişi:",
        "initialFinishTime": "İhalenin ilk kapanış zamanı",
        "label": "İhale",
        "noPreviousAuctions": "Önceki ihale yok",
        "participants": "İhalede yer alan şirketler",
        "plannedFinishTime": "İhalenin bitiş tarihi",
        "previousAuctions": "Önceki ihaleler",
        "reject": {
          "alert": "Bu ihaleyi reddetmek istediğinizden emin misiniz?",
          "fail": {
            "CannotRejectOffersExist": "Bu ihaleyi reddedemezsiniz; şirketiniz zaten bir fiyat teklifi verdi."
          },
          "label": "İlgilenmiyorum",
          "success": "İhale başarıyla reddedildi"
        },
        "save": "İhaleyi kaydet",
        "savingInProgress": "İhale kaydediliyor...",
        "selectParticipants": "İhaleye katılanları seçin"
      },
      "INQUIRY": {
        "actualFinishTime": "Gerçek bitiş zamanı",
        "buttonLabel": "Sorgu",
        "creator": "Sorguyu oluşturan",
        "currency": "Para birimi",
        "dateCreated": "Oluşturma:",
        "endedWithoutOffers": "Sorgu teklif olmadan bitti",
        "finish": "Sorguyu bitir",
        "inProgress": "Sorgunun son tarihi",
        "initialFinishTime": "İlk bitiş zamanı",
        "label": "Sorgu",
        "noPreviousAuctions": "Önceki sorgu yok",
        "participants": "Sorguda yer alan şirketler",
        "plannedFinishTime": "Sorgunun bitiş tarihi",
        "previousAuctions": "Önceki sorgular",
        "reject": {
          "alert": "Bu sorguyu reddetmek istediğinizden emin misiniz?",
          "fail": {
            "CannotRejectOffersExist": "Bu sorguyu reddedemezsiniz; şirketiniz zaten bir fiyat teklifi verdi."
          },
          "label": "İlgilenmiyorum",
          "success": "Sorgu başarıyla reddedildi"
        },
        "save": "Bir sorgu oluştur",
        "savingInProgress": "Sorgu kaydediliyor...",
        "selectParticipants": "Sorgu için katılımcı seçin"
      },
      "VALUATION": {
        "actualFinishTime": "Fiyat teklifi vermek için son kapanış zamanı",
        "buttonLabel": "+ Değerleme",
        "creator": "Değerleme'oluşturan",
        "currency": "Para birimi",
        "dateCreated": "Oluşturma:",
        "endedWithoutOffers": "Değerleme teklif olmadan bitti",
        "finish": "Değerlemeyi bitir",
        "inProgress": "Değerlemenin bitişi:",
        "initialFinishTime": "Fiyat teklifi vermek için ilk kapanış zamanı",
        "label": "Değerleme",
        "noPreviousAuctions": "Önceki değerleme yok",
        "participants": "Fiyat teklifi verme prosedürüne katılan şirketler",
        "plannedFinishTime": "Fiyat teklifi vermek için son tarih:",
        "previousAuctions": "Önceki değerlemeler",
        "reject": {
          "alert": "Bu değerlemeyi reddetmek istediğinizden emin misiniz?",
          "fail": {
            "CannotRejectOffersExist": "Bu değerlemeyi reddedemezsiniz; şirketiniz zaten bir fiyat teklifi verdi."
          },
          "label": "İlgilenmiyorum",
          "success": "Değerleme başarıyla reddedildi"
        },
        "save": "Değerlemeyi kaydet",
        "savingInProgress": "Değerleme kaydediliyor...",
        "selectParticipants": "Fiyat teklifi verme prosedürüne katılanları seçin"
      },
      "bid": "Fiyat teklifi",
      "blockedCarriers": "Engellenen taşıyıcı şirketler",
      "buttonLabel": "+ İhale",
      "buyNow": "Kabul et",
      "buyNowConfirmation": "{transportIdentifier} siparişini {minPrice} {currencySymbol} ücretinden kabul etmek istediğinizden emin misiniz?",
      "buyNowHeader": "Teklifi hemen kabul et ve minimum ücretten siparişi al",
      "buyNowLabel": "Minimum teklif",
      "create": "İhale oluştur",
      "createTitle": "{transportIdentifier} nakliyesi için bir ihale oluşturuluyor",
      "currentOffer": "Güncel teklif",
      "details": "Geçmiş",
      "errors": {
        "companies": {
          "empty": "Lütfen en az bir şirket seçin"
        },
        "maxPrice": {
          "invalidNumber": "Maksimum fiyat, sıfırdan büyük bir sayı olmalıdır.",
          "maxPriceLowerThanMinPrice": "Maksimum fiyat, minimum fiyattan küçük olamaz."
        },
        "minPrice": {
          "invalidNumber": "Minimum fiyat, sıfıra eşit veya sıfırdan büyük bir sayı olmalıdır."
        },
        "plannedFinishTime": {
          "isInPast": "Bitiş tarihi ileride olmalıdır."
        }
      },
      "finishTimeChanged": "{date} olan bitiş tarihi değiştirildi.",
      "label": "İhale",
      "lastUpdated": "Son güncelleme",
      "maxPrice": "Maks. fiyat",
      "minPrice": "Min. fiyat",
      "noOffers": "Geçerli teklif yok",
      "plannedFinishTimeAbbr": "planı.",
      "priceRange": "Fiyat aralığı",
      "selectGroup": "Grubu seçin ",
      "transport": "Nakliye"
    },
    "auth": {
      "accountActivated": "Hesap etkinleştirildi",
      "accountExpired": "Uzun süre etkinlik görülmediği için hesabınız askıya alınmış olabilir. Hesabı etkinleştiren bağlantı, hesapla ilişkili e-posta adresine gönderildi. Sorun yaşarsanız lütfen {supportEmail} adresinden bize ulaşın veya şu numarayı arayın: {supportPhone}.",
      "accountLocked": "Birden çok oturum açma denemesi nedeniyle hesabınız {arg} dakika boyunca kilitlendi. {arg} dakika sonra tekrar deneyin veya parolayı sıfırlayın.",
      "accountLockedByAdmin": "Hesabınız sistem yöneticisi tarafından engellendi",
      "activateAccount": "Hesabı etkinleştir",
      "alreadyRegistered": "Zaten hesabınız var mı?",
      "changePassword": {
        "false": "Parolayı değiştir",
        "true": "Parolayı değiştirme"
      },
      "confirmPassword": "Parolayı onayla",
      "description": "Lojistik platformu",
      "email": "E-posta adresi",
      "emailPlaceholder": "E-posta adresi girin",
      "fail": "Oturum açma başarısız oldu",
      "footer": "Hazırlayan:",
      "impersonate": "Kullanıcı hesabında oturum açın",
      "invalidLoginOrPassword": "Geçersiz oturum açma adı veya parola.",
      "login": "Oturum aç",
      "loginPlaceholder": "E-posta girin",
      "loginWithActiveDirectory": "Active Directory ile oturum aç",
      "logout": "Oturumu kapat",
      "logoutByConcurrentSession": "Oturumunuz kapatıldı çünkü bu hesabı başka bir cihazda kullanıyorsunuz.",
      "logoutByService": "Yönetim çalışmaları nedeniyle veya sistemi uzun süredir kullanmadığınız için oturumunuz kapatıldı.",
      "newPassword": "Yeni parola",
      "noLoginAndPassword": "Lütfen oturum açma adını ve parolayı girin.",
      "notRegistered": "Henüz hesabınız yok mu?",
      "oldPassword": "Eski parola",
      "or": "Veya",
      "password": "Parola",
      "passwordChanged": "{username} kullanıcısının parolası değiştirildi",
      "passwordPlaceholder": "Parola girin",
      "passwordRemindedMessage": "Belirtilen {email} adresi varsa bir mesaj gönderilir.",
      "remind": "Hatırlat",
      "remindEmail": "Hesabınızla ilişkili e-postayı girin, parola sıfırlama bağlantısını bu adrese göndereceğiz:",
      "remindFail": "İşlem başarısız oldu",
      "remindHeader": "Parolayı hatırlat",
      "remindLink": "Parolanızı mı unuttunuz?",
      "remindingInProgress": "E-posta gönderiliyor",
      "repeatNewPassword": "Yeni parolayı tekrar edin",
      "repeatPassword": "Parolayı tekrar edin",
      "serverError": "Sunucu yanıt vermiyor. Birkaç dakika içinde tekrar deneyin veya info@logintegra.com adresinden bize ulaşın.",
      "signin": "Giriş yap",
      "username": "Kullanıcı adı"
    },
    "authorityGroup": {
      "ADVICE": "İhbar",
      "ANNOUNCEMENT": "Duyurular",
      "API": "API",
      "APPLIES": "Taşıyıcı uygulamaları",
      "ASSORTMENT_GROUP": "Tasnif grupları",
      "AUCTION": "Sipariş aktarımı, ihaleler",
      "COMPANY": "Şirketler",
      "COURIER": "Kuryeler",
      "CUSTOM_FIELD": "Özel alanlar",
      "DELIVERY": "Teslimat planlama",
      "INDEX": "Dizinler",
      "MAIN_TRANSPORT_DATA": "Sipariş formunun ana alanları",
      "MODE_OF_TRANSPORTATION": "Nakliyat biçimi doğrulaması",
      "NOTES_AND_DOCUMENTS": "Notlar, belgeler",
      "OTHERS": "Diğerleri",
      "PACKAGE": "Paketler",
      "PRICE_LIST": "Fiyat listeleri",
      "PRODUCT": "Ürünler",
      "QUEUE": "Kuyruklar",
      "SETTINGS": "Ayarlar",
      "TASKS": "Kontrol noktaları",
      "TIME_WINDOW": "Zaman dilimleri",
      "TRANSPORT": "Nakliye, sipariş",
      "USER": "Kullanıcılar",
      "WAREHOUSE": "Depolar",
      "WEIGHT": "Tartım"
    },
    "blockades": {
      "invalidSection": "Reddedilmiş veya geçersiz kısım",
      "noActiveBlockades": "Etkin blokaj yok"
    },
    "checkpoint": {
      "actualExecutionDate": "Gerçek onay tarihi",
      "actualExecutionDateLabel": "Durumun onaylandığı gerçek tarihi girin",
      "blockLabel": "Engelle",
      "blockText": "Bu durumu onaylama seçeneği {name} tarafından engellendi",
      "blockTextShort": "{name} tarafından engellendi",
      "confirmator": "Onaylayan",
      "errors": {
        "blockadeReason": {
          "length": "Maksimum karakter sayısı 255'tir"
        },
        "executionDate": {
          "isInPast": "Verilen tarih geçmişte olmalı ve önceki durumun onay tarihinden sonra olmalıdır."
        }
      },
      "executionDate": "Durum onay tarihi",
      "reject": "Reddet",
      "rejectConfirm": "Bu durumu reddetmek istediğinizden emin misiniz? Reddi onaylarsanız sürücü geri gönderilecek ve müşteri ile taşıyıcıya e-posta bildirimleri gönderilecek.",
      "rejectionDate": "Son ret tarihi: ",
      "unblockLabel": "Engelini kaldır"
    },
    "checkpoints": {
      "confirm": "Onayla",
      "confirmAfterTime": "Durum gecikmeyle onaylandı",
      "confirmBeforeTime": "Durum son tarihten önce onaylandı",
      "confirmStatus": "Durumu onayla",
      "confirmedMessage": "{date} tarihindeki onay",
      "confirmedMessageBy": "{person} tarafından",
      "empty": "Durum yok",
      "plan": "planı.",
      "processFinished": "İşlem bitti!",
      "revokeConfirm": "Bu durumun onayını iptal etmek istediğinizden emin misiniz?",
      "revokeManyConfirm": "Not: Son onaylanan durum bu olmadığı için sonraki tüm durumların onaylarını da iptal edeceğiz. Devam etmek istediğinizden emin misiniz?"
    },
    "classifications": {
      "successMessage": "{registrationNumber} numaralı araç şu kuyruğa atandı: {squareName} - {queueName}"
    },
    "cmr-ayarı": {
      "errors": {
        "senderInstructionText": {
          "maxLineCountIs8": "Bu alandaki maksimum satır sayısı 8"
        }
      }
    },
    "cmrSettings": {
      "add": "CMR ayarları ekle",
      "fields": {
        "appendedDocsSpecificationCustomField": "5 numaralı alana (Eklenen belgeler) özel alan değeri ekle",
        "appendedDocsSpecificationNumber": "5 numaralı alana (Eklenen belgeler) sayı ekle",
        "appendedDocsText": "5 numaralı alanda (Eklenen belgeler) sabit metin",
        "featuresAndNumbersCustomField": "6 numaralı alana (İşaretler ve Numaralar) özel alan değeri ekle",
        "featuresAndNumbersText": "6 numaralı alanda (İşaretler ve Numaralar) sabit metin",
        "packageTypeText": "8 numaralı alanda (Paketleme yöntemi) sabit metin",
        "quantityCustomField": "7 numaralı alana (Paket sayısı) özel alan değeri ekle",
        "quantityText": "7 numaralı alanda (Paket sayısı) sabit metin",
        "senderInstructionCustomField": "13 numaralı alanda (Gönderen Talimatları) özel alan değerini göster",
        "senderInstructionText": "13 numaralı alanda (Gönderenin talimatları)'sabit metin",
        "showPrincipalName": "1 numaralı alanda (Gönderen) ana şirketin adını göster",
        "showWarehouseName": "1 numaralı alanda (Gönderen) deponun adını göster",
        "weightGrossCustomField": "11 numaralı alana (kg cinsinden brüt ağırlık) özel alan değeri ekle"
      },
      "label": "CMR ayarları",
      "noSettings": "Henüz CMR ayarı yok",
      "senderInstructionTextHint": "Bu alanda en fazla 8 satır olabilir. Çok uzun satırlar ayarlamamanız gerektiğini unutmayın, bunlar''yazdırılan CMR'de görünmez!"
    },
    "columns": {
      "ADVICE": "İhbar",
      "ALT_IDENTIFIER": "Alternatif kimlik",
      "ARRIVAL_DATE": "Teslimat",
      "CAR": "Araç",
      "CARRIER": "Taşıyıcı",
      "CONTRACTOR": "Yüklenici",
      "CONTRACTOR_FULL_NAME": "Yüklenici - tam ad",
      "CONTRACTOR_SHORT_NAME": "Yüklenici - kısa ad",
      "CREATION_DATE": "Oluşturma tarihi",
      "CUSTOM_FIELDS": "Özel alanlar",
      "DELIVERY_INCOTERMS": "Incoterms",
      "DELIVERY_NETTO_WEIGHT": "Net ağırlık [kg]",
      "DOCUMENTS": "Belgeler",
      "DOCUMENT_IDENTIFIER": "Belge Kimliği",
      "DRIVER": "Sürücü",
      "DRIVER_IDENTIFIER": "Sürücünün'Kimliği",
      "DRIVER_PHONE": "Telefon",
      "EMAIL_ADVICE": "E-posta ihbarı",
      "EMAIL_ADVICE_EMAIL_ADDRESS": "E-posta adresi",
      "IDENTIFIER": "Kimlik",
      "INDEX_DOCUMENT_NUMBER": "Sipariş numarası",
      "INDEX_PRODUCTS": "Ürünler",
      "INDEX_TRANSPORT_NUMBER": "Nakliye no.",
      "INSPECTION": "İnceleme",
      "INVOICE": "Fatura",
      "LOAD_CITY": "Şehir",
      "LOAD_COUNTRY": "Ülke",
      "LOAD_PACKAGES_QUANTITY": "Yüklenen paket",
      "LOAD_PLACE": "Yükleme yeri",
      "LOAD_QUANTITY": "Yüklenen palet sayısı",
      "LOAD_TIME_WINDOW": "Yükle",
      "LOAD_WAREHOUSE": "Depo",
      "LOCATION": "Konum",
      "MANUAL_NOTIFICATION": "Bildirimler",
      "NOTES": "Notlar",
      "PERIODIC_TRANSPORT_INTERNAL_INDEX": "Düzenli ihbar no.",
      "PRICE": "Fiyat",
      "QUANTITY": "Palet sayısı",
      "RAMP": "Rampa no.",
      "REQUIRES_INSPECTION": "İnceleme gerekiyor",
      "SERVICE_COMPANY_SHORT_NAME": "Hizmet şirketi",
      "STATUS": "Durum",
      "STORAGE_ZONE": "Tür",
      "SUM_LOAD_QUANTITY": "Yüklenen toplam palet",
      "SUM_UNLOAD_QUANTITY": "Boşaltılan toplam palet",
      "TOTAL_WEIGHT": "Ağırlık[kg]",
      "TRAILER": "Römork/Yarı Römork",
      "TRANSPORT_DESCRIPTION": "Notlar",
      "TRANSPORT_RELATION": "İlişki numarası",
      "TRANSPORT_SUPERVISOR": "İletişim kurulacak kişi",
      "UNLOAD_CITY": "Şehir",
      "UNLOAD_COUNTRY": "Ülke",
      "UNLOAD_PACKAGES_QUANTITY": "Boşaltılan paket",
      "UNLOAD_PLACE": "Teslimat yeri",
      "UNLOAD_QUANTITY": "Boşaltılan palet sayısı",
      "UNLOAD_TIME_WINDOW": "Boşalt",
      "UNLOAD_WAREHOUSE": "Depo",
      "WORKER": "Çalışanlar",
      "disableCustomFieldInfo": "Bu ögenin etkinleştirilmesi/devre dışı bırakılması, tabloda görünen tüm customField alanlarını etkinleştirir/devre dışı bırakır",
      "label": "Sütun görünürlüğü"
    },
    "common": {
      "accept": "Kabul et",
      "accepted": "Kabul edildi",
      "active": "Etkin",
      "add": "Ekle",
      "addedBy": "ekleyen:",
      "addingBlockedWindows": "Duraklama dilimleri ekleniyor",
      "address": "Adres",
      "allRightsReserved": "Tüm hakları saklıdır",
      "and": "ve",
      "andBetween": "ve",
      "appVersion": "Sürüm",
      "back": "Geri",
      "browseAndUploadDocuments": "Göz at ve belge yükle",
      "by": "tarafından:",
      "byYou": "sizin tarafınızdan",
      "cancel": "İptal",
      "cancelProcess": "İşlemi iptal et",
      "changeOfField": "Alan değişikliği",
      "check": "kontrol et",
      "choosingReason": "Neden",
      "clear": "Temizle",
      "clearFilters": "Filtreleri temizle",
      "clearTextArea": "Bu metin alanındaki içeriği temizle",
      "client": "Müşteri",
      "close": "Kapat",
      "company": "Şirket",
      "confirmMessage": "Emin misiniz?",
      "createSimilar": "Benzerini oluştur",
      "currency": "Para birimi",
      "dateCreated": "Oluşturma tarihi",
      "day": "Gün",
      "days2": "2 Gün",
      "days4": "4 Gün",
      "daysOfWeek": {
        "1": "Pzt",
        "2": "Sal",
        "3": "Çar",
        "4": "Per",
        "5": "Cum",
        "6": "Cmt",
        "7": "Paz",
        "label": "şu günler için:"
      },
      "dedicatedWindow": "Özel dilim",
      "defaultError": "Operation failed. Please try again or contact us at info@logintegra.com.",
      "delete": "Sil",
      "deleteTitle": "Bu nakliyeyi sil (silinen nakliye arşivde görünür).",
      "deletingProgress": "siliniyor",
      "desc": "Açıklama",
      "description": "Açıklama",
      "details": "ayrıntılar",
      "disable": "Devre dışı bırak",
      "disableXLS": "Dosyayı XLS olarak dışa aktarmak için tarih aralığını belirtin veya arama sonuçlarını filtreleyin.",
      "download": "Şunu indir: {name}",
      "downloadFile": "Dosyayı indir",
      "downloadManualHeader": "Kılavuzu indir",
      "driverMobilePhoneNumber.placeholder": "ör. 111222333",
      "driverMobilePhonePrefix.placeholder": "ör. 48",
      "edit": "Düzenle",
      "enable": "Etkinleştir",
      "english": {
        "abbr": "ing.",
        "label": "ingilizce"
      },
      "error": "Error",
      "errorCode": "Error code",
      "errorList": "Error list",
      "export": "Dışa Aktar",
      "exportAsXLS": "XLS olarak dışa aktarın.",
      "finishedWindow": "İşlem bitti",
      "forDateRange": {
        "M": "ay",
        "X": "özel aralık",
        "Y": "yıl"
      },
      "formShouldNotBeEmpty": "Form verileri boş bırakılmamalıdır!",
      "from": "başlangıç",
      "fromCompany": "şirketten",
      "further": "Sonraki",
      "grayWindow": "Engellenmiş dilim",
      "grossWeightKg": "Brüt ağırlık [kg]",
      "hint": "İpucu:",
      "hour": "Saat",
      "id": "Kimlik",
      "idx": "Dizin",
      "instructionalVideo": "Talimat videosu",
      "interval": {
        "M": "{count} {count, plural, one {ay} other {ay}}",
        "d": "{count} {count, plural, one {gün} other {gün}}"
      },
      "language": {
        "ENG": "ENG",
        "PL": "TR"
      },
      "lastUpdated": "Son değişiklik tarihi",
      "lateWindow": "Geç kalmış dilim",
      "legend": "Açıklama",
      "loadingData": "Veriler yükleniyor...",
      "max": "Maks.",
      "maxDate": "En geç tarih",
      "message": "Mesaj",
      "min": "Min.",
      "minDate": "En erken tarih",
      "more": "Daha fazla",
      "name": "Ad",
      "nameEn": "İngilizce ad",
      "namePl": "Türkçe ad",
      "netWeightKg": "Net ağırlık [kg]",
      "newMessagesTitle": "Yeni mesajlarınız var.",
      "next": "Sonraki",
      "no": "Hayır",
      "noAdviceAddedYet": "Henüz ihbar eklenmedi",
      "noAuthority": "Bu işlemi gerçekleştirme izniniz yok",
      "noData": "Veri yok",
      "noResults": "Sorgunuz sonuç vermedi",
      "notSpecified": "Belirtilmedi",
      "ok": "Tamam",
      "oldBrowser": "Eski bir tarayıcı kullanıyorsunuz. Uygulamamız Google Chrome, Firefox, Opera, Vivaldi, Safari veya IE Edge gibi modern bir tarayıcıda daha iyi çalışır.",
      "onDay": "gün:",
      "operationInProgress": "İşlem devam ediyor...",
      "options": "Seçenekler",
      "or": "veya",
      "order": "Sipariş",
      "orderNumber": "S. No.",
      "other": "Diğerleri",
      "otherRequirements": "Diğer şartlar",
      "otherRequirementsVisibleToCarrier": "Diğer şartlar (bu alan taşıyıcıya görünür)",
      "outOf": "/",
      "perPage": "sayfa başına",
      "phone": "Telefon",
      "plan": "Plan",
      "plannedDate": "Planlanan tarih",
      "pleaseWait": "Lütfen bekleyin...",
      "previous": "Önceki",
      "recipient": "Alıcı",
      "refresh": "Yenile",
      "register": "Katıl",
      "registryOfPersons": "Sicil",
      "reject": "Reddet",
      "rejected": "Reddedildi",
      "rememberFilters": "Filtreleri hatırla",
      "resend": "Tekrar gönder",
      "reset": "Sıfırla",
      "restore": "Geri Yükle",
      "role": "Rol",
      "save": "Kaydet",
      "saveAndClose": "Kaydet ve kapat",
      "saveSuccessAdditionalMessage": "Değişiklikleri görmek için sayfayı yenileyin",
      "saveSuccessMessage": "Değişiklikler başarıyla kaydedildi",
      "saved": "Kaydedildi",
      "saving": "Kaydediliyor",
      "scrollToClosestTransport": "En yakın tarihteki nakliyeye git",
      "search": "Ara",
      "selectDate": "Tarih seç",
      "selectDeselectAll": "Tümünü seç/Tüm seçimleri kaldır",
      "selectValue": "Select value",
      "send": "Gönder",
      "settings": "Ayarlar",
      "shortName": "Kısa ad",
      "shortNameEn": "İngilizce kısa ad",
      "shortNamePl": "Türkçe kısa ad",
      "show": "Göster",
      "sure": "Bunu yapmak istediğinizden emin misiniz?",
      "timeSign": {
        "sonra": "sonra",
        "önce": "önce"
      },
      "timeUnit": {
        "d": "gün",
        "h": "saat",
        "m": "dakika",
        "s": "saniye"
      },
      "to": "bitiş",
      "today": "Bugün",
      "transportCombiningIndicator": "Nakliye türü ilişkisi aynı",
      "transportDeletionDisabled": "Bu nakliyeyi silemezsiniz. Lütfen yöneticiye ulaşın.",
      "transportEditionDisabled": "Bu nakliyeyi düzenleme izniniz yok.",
      "type": "Tür",
      "update": "Güncelle",
      "uploadFile": "Dosya yükle",
      "value": "Değer",
      "waiting": "Bekliyor",
      "week": "Hafta",
      "weightAndSave": "Tart ve kaydet",
      "windowMovedAutomatically": "Gecikme nedeniyle zaman dilimi otomatik olarak taşındı",
      "yes": "Evet"
    },
    "company": {
      "address": "Adres",
      "addresses": "Adresler",
      "alias": "Rumuz",
      "allowedDomains": {
        "emailNotMatch": "Bu şirkete yalnızca e-postaları girilen etki alanlarında olan kişileri davet edebilirsiniz: {allowedDomains}",
        "header": "{companyName} şirketi için izin verilen etki alanları",
        "info": "Yalnızca aşağıdaki listedeki izin verilen etki alanlarından birine sahip kişiler şirkete davet edilebilir",
        "label": "İzin verilen etki alanları",
        "list": "İzin verilen etki alanları listesi:",
        "successDeleteMsg": "Etki alanı başarıyla silindi",
        "successSaveMsg": "Etki alanları başarıyla kaydedildi"
      },
      "chooseCompanyTypeHint": "Lütfen şirket türünü girin",
      "chooseCountryHint": "Önce ülke seçin!",
      "contractorNumber": "Yüklenici numarası",
      "country": "Ülke",
      "createCompany": "Şirket oluştur",
      "deleteConfirm": "{companyName} şirketi ile {principalName} şirketi arasındaki ortaklığı silmek istediğinizden emin misiniz? Bu işlemden sonra {companyName} şirketi {principalName} platformuna erişemeyecek.",
      "deletedMessage": "\"{companyName}\" başarıyla silindi.",
      "descriptionOfTheFleet": "Filo açıklaması",
      "editCompany": "Şirketi düzenle",
      "employees": "Çalışanlar",
      "errors": {},
      "existingCompanyName": "Şirket platformda zaten var. Adını düzenleyemezsiniz.",
      "gusHasProblem": "Merkezi İstatistik Bürosu veri tabanıyla bağlantı yok. Bağlantı tekrar kurulduktan sonra şirket, Merkezi İstatistik Bürosundan alınan verilere göre doğrulanacak",
      "krs": "KRS/CEiDG",
      "label": "Şirket",
      "legalStatus": {
        "businessActivity": "Ticari faaliyet",
        "label": "Hukuki statü",
        "limitedCompany": "Şirket"
      },
      "limitedDataHint": "{company} şirketinin bazı ayrıntılarının görüntülenmesi ve düzenlenmesi sınırlanmış; bunlar yalnızca {company} şirketindeki Yöneticiler tarafından yönetilebilir.",
      "list": "Şirket listesi",
      "maxNumberOfAccounts": "Maks. hesap sayısı",
      "maxNumberOfAccountsHint": "Maks. hesap sayısına ulaştıktan sonra bu şirkete yeni kullanıcı davet etmek mümkün olmaz. Maks. hesap sayısı ayarlanmazsa hesap sayısı sınırı olmaz.",
      "name": "Ad",
      "noEmployees": "Bu şirketin çalışanı yok",
      "noPartnershipWarning": "Şirketiniz herhangi bir ortaklık kurmadı. Bu durum, yüklenici seçiminde sorunlara yol açabilir.",
      "partnershipStatus": {
        "ACCEPTED": "Kabul edildi",
        "AWAITING_VERIFICATION": "Doğrulama bekleniyor",
        "BLOCKED": "Engellendi",
        "NO_INVITATION": "Davet yok",
        "REGISTRATION_IS_IN_PROGRESS": "Kayıt yapılıyor",
        "REJECTED": "Reddedildi",
        "UPDATED": "Güncellendi",
        "WAITING": "Bekliyor",
        "label": "Durum"
      },
      "partnershipTable": {
        "awaitingLabel": "Bekleyen davetler listesi",
        "companyName": "Şirket adı",
        "label": "İlişkili şirketler listesi"
      },
      "patron": "Müşteri",
      "patronWillChange": "{transportIds} siparişlerinde müşteri değiştirilecek. Emin misiniz? \"İptal\" düğmesini seçerseniz'değişiklikler kaydedilmez.",
      "possessedCertificates": "Sahip olunan sertifikalar",
      "pricingPlan": "Şirket için abonelik seçeneği",
      "rampEdition": {
        "header": "{companyName} şirketinin kullanıcıları için rampa düzenleme",
        "label": "Rampayı düzenle",
        "updateForAllUsers": "Bu şirketin tüm kullanıcıları için rampaları güncelle"
      },
      "registrationDate": "Kayıt tarihi",
      "regon": "REGON",
      "saveSuccessMessage": "Şirket başarıyla kaydedildi.",
      "sections": "Kısımlar",
      "selectCountry": "Ülke seç",
      "selectType": "Tür seç",
      "selectVoivodeship": "Voyvodalık seç",
      "shortName": "Kısa ad",
      "taxIdNumber": "Vergi Kimlik Numarası (NIP)",
      "type": {
        "CARRIER": "Taşıyıcı",
        "CLIENT": "Müşteri",
        "OPERATOR": "Operatör",
        "SERVICE": "Hizmet",
        "SERVICEMAN": "Hizmet görevlisi",
        "SUPPLIER": "Tedarikçi \\\\ Alıcı",
        "SUPPLIER.CLIENT": "Müşteri",
        "SUPPLIER.COLLECTION": "Alıcı",
        "SUPPLIER.COMPANY": "Şirket",
        "SUPPLIER.COURIER": "Kurye",
        "SUPPLIER.DELIVERY": "Tedarikçi",
        "label": "Tür",
        "producer": "Üretici"
      },
      "typeNipHint": "Önce NIP numarasını yazın!",
      "update": "Güncelle",
      "verifiedByGus": "Şirket, Merkezi İstatistik Bürosu tarafından başarıyla doğrulandı. Adını düzenleyemezsiniz."
    },
    "companyDisabledIcon": {
      "bothInsurancesMissing": "Engellendi. Etkin OCP ve OCS sigortası yok",
      "insuranceAmountTooLow": "Engellendi. Sigorta tutarı "
    },
    "companyRamp": {
      "notExists": "{companyName} şirketinde henüz atanmış bir varsayılan rampa erişimi yok",
      "updateForAllUsersConfirmMsg": "Bu şirketin tüm kullanıcıları için rampa erişiminin üzerine yazmak istediğinizden emin misiniz?",
      "usersWithIndividualRampAccess": "Farklı bir rampa erişimi ataması olan kullanıcıların listesi"
    },
    "courier": {
      "COURIER_PACKAGE": {
        "add": "Ekle",
        "description": "İçerik",
        "disableConfirm": "Bu ögeyi silmek istediğinizden emin misiniz?",
        "edit": "Düzenle",
        "height": "Yükseklik [cm]",
        "internalId": "Kimlik",
        "length": "Uzunluk [cm]",
        "numberOfPackagesTotal": "Toplam tutar:",
        "packageIdPlaceholder": "Kimlik",
        "packageType": "Tür",
        "packageTypePlaceholder": "Tür",
        "packageValue": "Mal değeri (parça başına)",
        "packageValueLine": "Mal değeri (satır başına)",
        "packageWeightTotal": "Toplam ağırlık:",
        "pallet": "PALET",
        "parcel": "PAKET",
        "quantity": "Adet",
        "sectionLabel": "Paketler",
        "sum": "Toplam:",
        "weight": "Ağırlık (parça başına) [kg]",
        "width": "Genişlik [cm]"
      },
      "documents": {
        "connote": "Konşimento",
        "label": "Etiket",
        "manifest": "Beyanname"
      },
      "priceDetails": {
        "Vat": "KDV",
        "basePrice": "Taban fiyat",
        "basePriceVat": "Taban fiyat KDV",
        "dhlPremium": "12:00 Premium",
        "emergencySituation": "Acil Durum",
        "finalPriceComponents": "Son fiyat bileşenleri",
        "fuelCharge": "Yakıt için ilave bedel",
        "grossPrice": "Brüt Fiyat",
        "grossServicePrice": "Brüt hizmet fiyatı",
        "netPriceComponents": "Net fiyat bileşenleri",
        "netWorth": "Net değer",
        "peakPrice": "Tavan ilave bedel",
        "priceMultiplePackaging": "Birden çok paket için ilave bedel",
        "servicePrice": "Hizmet Fiyatı"
      },
      "validation.float.range": "Değer 0,001 - 999999999999 arasında bir sayı olmalıdır",
      "validation.integer.range": "Değer 1 - 9999999 arasında bir tam sayı olmalıdır",
      "validation.minimumOnePackage": "En az bir geçerli paket eklemeniz gerekiyor",
      "validation.range": "Değer 1 - 9999999 arasında bir sayı olmalıdır"
    },
    "courierSettings": {
      "add": "Kurye ayarları ekle",
      "description": {
        "isGetCourierQuotesFieldVisibility": "Şu alanın görünürlüğü: \"Kurye fiyat teklifi al\"",
        "shouldGetCourierQuotesByDefault": " Varsayılan olarak kurye fiyat teklifi al",
        "transportType": "Nakliye türü"
      },
      "label": "Kurye ayarları",
      "noSettings": "Henüz kurye ayarı yok"
    },
    "dateFilterProperty": {
      "DATE_CREATED": "Siparişin oluşturulduğu tarih",
      "DEPARTURE_DATE": "Seçilen teslimat tarihi (bu aşamada zaman dilimi yok)",
      "LOAD_DATE": "Yükleme tarihi",
      "TIME_RANGE_FOR_ADVICE_START": "Zaman aralığının başlangıcı (bu aşamada zaman dilimi yok)",
      "TIME_RANGE_FOR_ADVICE_STOP": "Zaman aralığının bitişi (bu aşamada zaman dilimi yok)",
      "TIME_WINDOW_DATES": "Bir zaman diliminin başlangıcı veya bitişi",
      "TIME_WINDOW_START": "Bir zaman diliminin başlangıcı",
      "TIME_WINDOW_STOP": "Bir zaman diliminin bitişi",
      "UNLOAD_DATE": "Boşaltma tarihi",
      "hint": "Nakliye türü, zaman dilimi olmadan bir sipariş oluşturulmasına izin veriyorsa bu sipariş, zaman dilimi özellikleriyle ilgili filtrelere (ör. Zaman diliminin başlangıcı) eklenmez."
    },
    "delivery": {
      "additionalData": "Ek veriler",
      "contactPersonDetails": "İletişim kurulacak kişi",
      "create": "Yeni teslimat oluştur",
      "delete": "Teslimatı silmek istediğinizden emin misiniz?",
      "deliveryNumber": "Teslimat numarası",
      "description": "Açıklama",
      "edit": "Teslimatı düzenle",
      "freightCost": "Taşıma bedeli",
      "freightCostCurrency": "Para birimi",
      "incoterms": "Incoterms",
      "label": "Teslimat {id}",
      "modeOfTransport": "Nakliye biçimi",
      "placeholder": {
        "city": "Şehir",
        "postal": "12-345"
      },
      "planningTabTitle": "Teslimat planlama",
      "step": {
        "country": "Ülke",
        "load": "Yükle",
        "loadArrivalDate": "Yükleme varış tarihi",
        "loadRealDate": "Gerçek yükleme varış tarihi",
        "maxAdviceDate": "Zaman aralığı bitişi",
        "minAdviceDate": "Zaman aralığı başlangıcı",
        "postalAndCity": "Posta kodu ve şehir",
        "recipient": "Alıcı",
        "street": "Sokak",
        "unload": "Boşalt",
        "unloadArrivalDate": "Boşaltma varış tarihi",
        "unloadRealDate": "Gerçek boşaltma varış tarihi"
      },
      "toPlan": "Planlanmamış teslimatlar"
    },
    "depo-bölgesi": {
      "errors": {}
    },
    "dev": {
      "confirm": "Onayla",
      "performSaveAt": "Kayıt yeri:"
    },
    "discrepancyReport": {
      "generate": "Sapma kaydı oluştur",
      "label": "Sapma kaydı",
      "noAdviceInfo": "İhbar eklendikten sonra sapma kaydı oluşturulabilir",
      "noDiscrepanciesFound": "Sapma bulunamadı",
      "noDiscrepanciesInfo": "Uyuşmazlıklar bulunduktan sonra sapma kaydı oluşturulabilir"
    },
    "dizin-adet": {
      "errors": {
        "quantity": {
          "conditionallyObligatory": "Lütfen değerlerden birini girin: {names}.",
          "invalidNumber": "Lütfen doğru pozitif sayıyı girin.",
          "obligatory": "Lütfen adedi girin."
        }
      },
      "incompleteLoadingRulesNotMet": "Bu değer ancak tamamlanmamış yükleme için girilebilir."
    },
    "document": {
      "CERTIFICATE": {
        "expirationDate": "Sertifikanın son geçerlilik tarihinin bitişi:",
        "number": "Sertifika numarası"
      },
      "INSURANCE": {
        "create": "Sigorta ekle",
        "expirationDate": "Sigortanın son geçerlilik tarihinin bitişi:",
        "expirationDateFrom": "Sigortanın son geçerlilik tarihinin başlangıcı:",
        "new": "Yeni {typeLabel}",
        "number": "Sigorta numarası",
        "save": "Sigortayı kaydet",
        "savingInProgress": "Sigortayı kaydediyoruz...",
        "showArchived": "Arşivlenen sigortaları göster"
      },
      "LICENCE": {
        "create": "Yetki belgesi ekle",
        "expirationDate": "Son geçerlilik tarihi",
        "new": "Yeni {typeLabel}",
        "number": "Yetki belgesi numarası",
        "save": "Yetki belgesini kaydet",
        "savingInProgress": "Yetki belgesini kaydediyoruz...",
        "showArchived": "Arşivlenen yetki belgelerini göster"
      },
      "MAPPING": {
        "expirationDate": "Sertifikanın son geçerlilik tarihinin bitişi:",
        "expirationDateFrom": "Sertifikanın son geçerlilik tarihinin başlangıcı:",
        "number": "Harita numarası"
      },
      "amount": "Sigorta tutarı",
      "currency": "Para birimi",
      "disableConfirm": "Bu belgeyi arşive atamak istediğinizden emin misiniz? Geçerlilik kontrol edilirken arşivlenen belgeler hesaba katılmaz.",
      "documentType": "Kapsam",
      "documentType.placeholder": "Kapsam seçin",
      "errors": {
        "expirationDate": {
          "isInPast": "Son geçerlilik tarihi geçmişte olamaz."
        },
        "expirationDateFrom": {
          "afterExpirationDate": "Son geçerlilik \"başlangıç tarihi\" \"bitiş tarihi\"nden önce olmalıdır."
        }
      },
      "expirationShiftInfo": "Belge, sigortanın bitiş tarihi olan {date} tarihinden {days} gün önce geçersiz sayılır",
      "expired": "Belgenin süresi doldu!",
      "files": "Eklenen dosyalar",
      "maxCountExceeded": "Bir talebe en fazla {maxCount} dosya ekleyebilirsiniz",
      "noFilesInfo": "Bu belgeye dosya eklenmedi",
      "noFilesWarning": "Lütfen taranmış bir belge dosyası ekleyin!",
      "scopeOfInsurance": "Bölgesel kapsam"
    },
    "documentType": {
      "CERTIFICATE": {
        "add": "Kalibrasyon sertifikası ekle",
        "expirationDate": "Son geçerlilik tarihinin bitişi:",
        "expirationDateFrom": "Son geçerlilik tarihinin başlangıcı:",
        "expirationDateLabel": "Kalibrasyonun geçerliliği",
        "name": "Sensör kalibrasyon sertifikası",
        "number": "Sertifika numarası",
        "showArchived": "Arşivlenen kalibrasyon sertifikalarını göster"
      },
      "SUMMER_MAPPING": {
        "add": "Yaz haritası ekle",
        "expirationDate": "Son geçerlilik tarihinin bitişi:",
        "expirationDateFrom": "Son harita tarihi",
        "name": "Yaz haritası",
        "showArchived": "Arşivlenen yaz haritalarını göster"
      },
      "WINTER_MAPPING": {
        "add": "Kış haritası ekle",
        "expirationDate": "Son geçerlilik tarihinin bitişi:",
        "expirationDateFrom": "Son harita tarihi",
        "name": "Kış haritası",
        "showArchived": "Arşivlenen kış haritalarını göster"
      },
      "isRequiredForModeOfTransportationInfo": "Eksik belgeler:"
    },
    "driver": {
      "addDriver": "Bir sürücü ekle",
      "disableConfirm": "Bu sürücüyü kaldırmak istediğinizden emin misiniz?",
      "documentNumber": "Belge numarası",
      "documentType": {
        "ID_CARD": "Nüfus cüzdanı",
        "PASSPORT": "Pasaport",
        "label": "Belge türü"
      },
      "errors": {
        "phoneNumber": {
          "regex": "Numara 9 ila 12 basamaklı olmalıdır"
        }
      },
      "label": "Sürücüler",
      "lastUpdated": "Son güncelleme",
      "name": "Ad ve soyadı",
      "nationality": "Uyruk",
      "phoneNumber": "Telefon numarası",
      "timeWindow": "Zaman dilimi"
    },
    "emailAdvice": {
      "adviceDetails": "İhbar ayrıntıları",
      "adviceDetailsCf": "Verileri tamamla",
      "alreadySent": "E-posta İhbarı talebi zaten gönderildi",
      "alreadySentCf": "Verileri tamamlama talebi zaten gönderildi",
      "confirm": "Veri talebini yeniden göndermek istediğinizden emin misiniz? Önceki bağlantı devre dışı bırakılacak.",
      "logLabel": {
        "ADVICE_DATA": "İhbar ayrıntısı talebi",
        "CF_DATA": "Verileri tamamlama talebi"
      },
      "noRole": "Bu işlem'için yeterli izniniz yok",
      "title": "İhbar verisi talebini e-posta ile gönder",
      "titleCf": "Verileri tamamlama talebini e-posta ile gönder"
    },
    "errors": {
      "blank": "Lütfen bir değer girin",
      "blankCheckbox": "Bu alan işaretlenmelidir",
      "blankSelect": "Lütfen bir değer seçin",
      "emptyFile": "Yüklenen dosya boş, lütfen başka bir dosya seçin",
      "fileAlreadyExists": "Dosya yüklenirken bir hata oluştu. Girilen adda bir dosya zaten var.",
      "fileTooLarge": "Dosya yüklenirken bir hata oluştu. Dosya çok büyük!",
      "fileUploadError": "Dosya yüklenirken bir hata oluştu",
      "filterRequestTimeout": "Aramanız sırasında bir hata oluştu. Lütfen aramanızın tarih aralığını daraltıp tekrar deneyin.",
      "invalidDatesSequence": "Tarih 'Başlangıç' şu tarihten önce olmalıdır: 'Bitiş'",
      "invalidEmail": "Lütfen geçerli bir e-posta adresi girin",
      "invalidQuantity": "Palet sayısı pozitif bir tam sayı olmalıdır",
      "noNegativeNumber": "Bu giriş negatif bir sayı olamaz",
      "number": "Bu giriş sadece sayı içerebilir",
      "positiveInteger": "Bu giriş pozitif bir tam sayı olmalıdır",
      "positiveNumber": "Bu giriş pozitif bir sayı olmalıdır",
      "tooLong": "Bu giriş en fazla %@ karakter olabilir",
      "tooShort": "Bu giriş en az %@ karakter olmalıdır"
    },
    "file": {
      "confirmDeletion": "Bu dosyayı silmek istediğinizden emin misiniz?"
    },
    "formChanges": {
      "dirtyValueChanged": "Dikkat! Bu alandaki değeri \"{yourValue}\" olarak değiştirdiniz ancak başka bir kullanıcı bu alandaki değişiklikleri \"{newValue}\" değeriyle kaydetti.",
      "newValue": "Dikkat! Başka bir kullanıcı bu alanda \"{value}\" değerini ayarladı.",
      "valueChanged": "Dikkat! Başka bir kullanıcı bu alandaki değeri \"{oldValue}\" yerine \"{newValue}\" olarak değiştirdi.",
      "valueRemoved": "Dikkat! Başka bir kullanıcı bu alandan \"{value}\" değerini kaldırdı."
    },
    "freeSubsequentSpots": {
      "infoAboutCurrentSpot": "Seçilen rampa: <b>{currentRamp}</b>, zaman: <b>{currentStart}</b>",
      "infoAboutWindowSize": "Zaman diliminiz seçilen yere sığmıyor, boşaltma için gereken süre: <b>{windowSizeInMinutes}</b> dakika",
      "showSearchSpotsButton": "Planda kullanılabilecek dilimleri göster",
      "suggestionsHeader": "Mümkün olan en erken ihbar zamanı:"
    },
    "gates": {
      "artrIdentifier": "ARTR kimliği",
      "create": "Kapı oluştur",
      "gateTypes": {
        "ENTRY_GATE": "Giriş kapısı",
        "EXIT_GATE": "Çıkış kapısı",
        "UNKNOWN": "Bilinmiyor",
        "label": "Gate's type"
      },
      "label": "Kapılar"
    },
    "groupTypeSettings": {
      "description": {
        "canSelectOnlyOneContractorsGroup": "Belirli bir türde yalnızca bir grup seçilmesine izin verir",
        "partnershipTypesWithAccess": "Grup türünün görünür olacağı ortaklık türlerini belirler",
        "requiresContractorsGroup": "Grup türünde en az bir grubun seçilmesi gerekip gerekmediğini belirler",
        "visible": "Grup türünün bildirimlerde görünür olup olmayacağını belirler"
      },
      "label": "Grup türü ayarları",
      "labels": {
        "canSelectOnlyOneContractorsGroup": "Yalnızca bir grup seçmek zorunlu",
        "partnershipTypesWithAccess": "Grup türünün görünür olacağı ortaklık türleri",
        "requiresContractorsGroup": "En az bir grup seçmek zorunlu",
        "visible": "Gönderimlerde grup türünün görünürlüğü"
      }
    },
    "help": {
      "changePrincipalSpaceInfo": "Not: Bulunduğunuz sayfa yenilenecek, lütfen bu işlemi tamamlamadan önce değişikliklerinizi kaydedin.",
      "contactUs": "Logintegra Yardım Masası",
      "contactWithWarehouse": "Depoyla görüşün",
      "label": "Yardım",
      "newMessagesTitle": "%@1 yeni mesajınız var!",
      "selectRamp": "Yükleme grubu seçin.",
      "statute": "Yönetmelik"
    },
    "hizmet-şartları": {
      "errors": {
        "unchecked": "Bir doğrulama talebi göndermek için HİZMET ŞARTLARINI onaylamalısınız"
      },
      "label": {
        "acquiant": "Şu belgeleri okudum: ",
        "confirmed": "{user} kullanıcısı tarafından {confirmationDate} tarihinde kabul edildi",
        "documents": "Hizmet Şartları belgeleri:"
      },
      "name": "HİZMET ŞARTLARI",
      "shortName": "Hizmet Şartları"
    },
    "ikincil-kısım": {
      "bankAccountNumber": "Banka hesap numarası",
      "bankFilePattern": {
        "label": "Form şablonu"
      },
      "errors": {},
      "internalNotes": "Dahili notlar",
      "label": "Ek veriler",
      "otherAttachments": "Diğer ekler"
    },
    "index": {
      "INDEX": {
        "add": "Bir dizin ekle",
        "disableConfirm": "Bu dizini silmek istediğinizden emin misiniz?",
        "enabled": "Dizin etkinleştirildi",
        "globalNumber": "Dizin numarası",
        "globalNumber.abbr": "Dizin no.",
        "label": "Dizin",
        "localNumber": "Yerel numara",
        "localNumber.abbr": "Yerel no.",
        "name": "Ürün adı",
        "name.abbr": "Ad",
        "notes": "Dizin notları",
        "product": "Ürün",
        "recipient": "Alıcı",
        "sectionLabel": "Dizinler",
        "step": "Bir nakliye aşamasına dizin atama",
        "version": "Sürüm numarası"
      },
      "PALLETS": {
        "add": "Palet ekle",
        "disableConfirm": "Bu ögeyi silmek istediğinizden emin misiniz?",
        "enabled": "Öge etkinleştirildi",
        "globalNumber": "Palet numarası",
        "globalNumber.abbr": "Palet no.",
        "label": "Paletler",
        "localNumber": "Yerel numara",
        "localNumber.abbr": "Yerel no.",
        "name": "Ürün adı",
        "name.abbr": "Ad",
        "notes": "Ek bilgiler",
        "product": "Ürün",
        "sectionLabel": "Paletler",
        "shortName": "Kısa ad",
        "step": "Paletleri bir aşamayla ilişkilendirme"
      },
      "company": "Yüklenici",
      "companyRecipient": "Müşterinin'alıcısı",
      "dateCreated": "Oluşturulma tarihi",
      "documentNumber": "Sipariş numarası",
      "documentNumber.abbr": "Sipariş numarası",
      "exportDisabledHint": "Değişiklikler kaydedildikten sonra dışa aktarım yapılabilir.",
      "selectCompany": "Bir müşteri seçin",
      "selectCompanyHint": "Önce sipariş formunda müşteriyi seçin",
      "selectRecipient": "Bir alıcı seçin",
      "transportNumber": "Sipariş türü",
      "transportNumber.abbr": "Sipariş türü"
    },
    "inspectionSection": {
      "fillInspection": "Doldur",
      "label": "İnceleme",
      "noInspection": "Nakliye incelemesi yapılmadı"
    },
    "invitation": {
      "acceptedByAnotherPerson": "Davet, şirketinizdeki başka bir kişi tarafından zaten kabul edildi.",
      "acceptedSummary": "Kabul edildi",
      "alreadyAccepted": "Bu davet zaten kabul edildi.",
      "alreadyHandled": "Bu davet ele alındı",
      "companyRegistration": "Şirket kaydı",
      "createdBy": "{personName},",
      "creator": "Oluşturan",
      "email": "E-posta",
      "errors": {},
      "expirationDate": "Son geçerlilik tarihi",
      "for": {
        "CARRIER": "Taşıyıcı",
        "CLIENT": "Müşteri",
        "JOIN_COMPANY": "Şirketinize",
        "OPERATOR": "Operatör",
        "SERVICEMAN": "Hizmet görevlisi",
        "SUPPLIER": "Tedarikçi \\\\ Alıcı",
        "SUPPLIER.CLIENT": "Müşteri"
      },
      "hasExpired": "Süresi doldu",
      "instruction": {
        "label": "Şu belgeyi okudum:",
        "linkName": "GENEL HİZMET ŞARTLARI"
      },
      "invalidTaxIdNumber": {
        "info": "Şirket ayrıntıları, davetteki bilgilerle eşleşmiyor. Lütfen Logintegra'ya ulaşın."
      },
      "invite": "Davet et",
      "inviteToCompany": "Şirkete davet et",
      "label": "Bir davet",
      "notFound": "Davet yok veya süresi doldu.",
      "ofCompany": "iş birliği başlatmanız için sizi sisteme davet ediyor",
      "registeredAccount": "Kayıtlı hesap",
      "registrationComplete": "Teşekkürler. Hesabınız kaydedildi. Lütfen {username} oturum açma adını kullanarak oturum açın.",
      "registrationCompleteLdap": "Thank you. Your account has been registered. You can log in using the login {username} and the password that is used to log in to your company's internal systems (Active Directory).",
      "rejectedSummary": "Reddedildi",
      "sendTo": "Bir davet gönder",
      "sent": "Davet {email} adresine gönderildi.",
      "taxIdNumberExist": {
        "info": "Vergi kimlik numarası (NIP) zaten veri tabanında var. Lütfen Logintegra ile iletişime geçin."
      },
      "toCompanyWithRole": "{companyName} şirketi için {roleName} olarak {companyName} şirketiyle",
      "toJoinExistingCompany": "/ {companyName} şirketi için",
      "toPartnership": "{personName}, sizi {roleName} olarak {companyName} şirketini başlatmaya davet ediyor.",
      "type": {
        "CARRIER": "Taşıyıcı olarak katıl",
        "CLIENT": "Müşteri olarak katıl",
        "JOIN_COMPANY": "Bir şirkete katıl",
        "SERVICEMAN": "Hizmet görevlisi olarak katıl",
        "SUPPLIER": "Tedarikçi \\\\ alıcı olarak katıl",
        "SUPPLIER.CLIENT": "Müşteri olarak katıl"
      },
      "userRegistration": "Kullanıcı kaydı"
    },
    "invitations": {
      "cannotInviteAnyRoleGroup": "Kullanıcı davet etme yetkiniz yok",
      "emptyReceived": "Henüz bir davet almadınız.",
      "emptySent": "Henüz bir davet göndermediniz.",
      "label": "Davetler",
      "noRoleGroupsFound": "Seçilen ortaklık türüne sahip bir rolü davet etme yetkiniz yok",
      "received": "Alındı",
      "sent": "Gönderildi",
      "types": {
        "ACCEPTED": "Kabul edildi",
        "EXPIRED": "Süresi doldu",
        "REJECTED": "Reddedildi",
        "WAITING": "Bekliyor"
      }
    },
    "kısım-işlemi": {
      "noCommentError": "Lütfen ret nedenlerini girin.",
      "rejectionComment": "Bu kısmı reddetme'nedeninizi belirtin"
    },
    "log": {
      "actor": "Değişikliği yapan",
      "change": "Değiştir",
      "dateCreated": "Tarih",
      "fieldChange": "Alan değişikliği",
      "integration": {
        "resend": "Yeniden gönder",
        "resendFailed": "Mesajın yeniden gönderilmesi başarısız oldu",
        "resendSuccess": "Mesajın yeniden gönderilmesi başarıyla planlandı",
        "sureToResend": "API mesajını yeniden göndermek istediğinizden emin misiniz?"
      },
      "method": "Yöntem",
      "object": "Nesne",
      "partnership": "Ortaklık #{id}",
      "request": "Talep",
      "requestType": "Talep türü",
      "response": "Yanıt",
      "transport": "Nakliye",
      "url": "URL adresi"
    },
    "logintegraEmail": "info@logintegra.com",
    "logintegraPhone": "0048 585 357 806",
    "logs": {
      "DELETE": {
        "ActionAuthority": "Nakliye türüne bağlı kullanıcı rolü izni silindi",
        "ActiveAuctionRestriction": "Kısıtlama silindi",
        "ActiveRestriction": "Kısıtlama silindi",
        "AdviceDatesRestriction": "Kısıtlama silindi",
        "AfterCheckpointBasedRestriction": "Kısıtlama silindi",
        "AuthorityRestriction": "Nesneye bağlı kullanıcı rolü izni silindi",
        "BeforeCheckpointBasedRestriction": "Kısıtlama silindi",
        "BlockedRestriction": "Engellenmiş kısıtlama silindi",
        "CompanyRamp": "Bir şirket için rampa atamasının silinmesi",
        "CustomFieldValue": "Özel alan güncellendi",
        "Delivery": "Teslimat silindi: ",
        "NoRouteOfferRestriction": "Kısıtlama silindi",
        "OwnCompanyRestriction": "Kısıtlama silindi",
        "PersonNotification": "Kişi bildirimi kaldırıldı",
        "PersonRamp": "Bir kullanıcı için rampa atamasının silinmesi",
        "RoleGroupAuthority": "Kullanıcı rolü izni silindi",
        "Step": "Aşama silindi",
        "TimeBasedRestriction": "Kısıtlama silindi",
        "TransportTransportRelation": "Nakliye ilişkisi silindi",
        "UnlimitedRestriction": "Kısıtlama silindi"
      },
      "INSERT": {
        "ARTR": "ARTR sistem entegrasyonu",
        "ActionAuthority": "Nakliye türüne bağlı kullanıcı rolü izni eklendi",
        "ActiveAuctionRestriction": "Kısıtlama eklendi",
        "ActiveRestriction": "Kısıtlama eklendi",
        "Advice": "İhbar oluşturuldu",
        "AdviceDatesRestriction": "Kısıtlama eklendi",
        "AfterCheckpointBasedRestriction": "Kısıtlama eklendi",
        "Auction": "İhale oluşturuldu",
        "AuthorityRestriction": "Nesneye bağlı kullanıcı rolü izni eklendi",
        "BeforeCheckpointBasedRestriction": "Kısıtlama eklendi",
        "BlockedRestriction": "Engellenmiş kısıtlama eklendi",
        "CompanyRamp": "Şirket rampalarının yeniden atanması",
        "CustomFieldValue": "Özel alan dolduruldu",
        "File": "Dosya eklendi",
        "Index": "Dizin eklendi",
        "NoRouteOfferRestriction": "Kısıtlama eklendi",
        "Note": "Not oluşturuldu",
        "Offer": "Teklif eklendi",
        "OwnCompanyRestriction": "Kısıtlama eklendi",
        "Person": "Hesap oluşturuldu",
        "PersonNotification": "Kişi bildirimi eklendi",
        "PersonRamp": "Kullanıcı rampalarının yeniden atanması",
        "RoleGroupAuthority": "Kullanıcı rolü izni eklendi",
        "Step": "Aşama eklendi",
        "TimeBasedRestriction": "Kısıtlama eklendi",
        "Transaction": "Sipariş aktarıldı",
        "Transport": "Nakliye oluşturuldu",
        "TransportTransportRelation": "Nakliye ilişkisi eklendi",
        "UnlimitedRestriction": "Kısıtlama eklendi"
      },
      "TYPE_EMAIL": "Bir e-posta bildirimi gönderme",
      "TYPE_SMS": "SMS bildirimi gönderme",
      "UPDATE": {
        "ActionAuthority": "Nakliye türüne bağlı kullanıcı rolü izni değiştirildi",
        "Advice": "İhbar güncellendi",
        "Auction": "İhale güncellendi",
        "AuthorityRestriction": "Nesneye bağlı kullanıcı rolü izni değiştirildi",
        "Checkpoint": "Durum güncellemesi",
        "CompanyRamp": "Şirket rampalarının yeniden atanması",
        "CustomFieldValue": "Özel alan güncellendi",
        "File": "Dosya güncellendi",
        "Index": "Dizin güncellendi",
        "IndexQuantity": "Dizin adedi güncellendi",
        "Note": "Not güncellendi",
        "Offer": "Teklif güncellendi",
        "Person": "Oturum aç",
        "PersonRamp": "Kullanıcı rampalarının yeniden atanması",
        "Step": "Aşama güncellendi",
        "StepPackageType": "Paketler",
        "TimeWindow": "Zaman dilimi güncellendi",
        "Transaction": "İşlem güncellendi",
        "Transport": "Nakliye güncellendi"
      },
      "WeightLog": "Ağırlık okuma"
    },
    "maks-palet": {
      "errors": {},
      "label": "Palet sınırı",
      "title": "Günlük palet sınırı"
    },
    "modeOfTransportation": {
      "approve": "Nakliyat biçimini onayla",
      "brand": "Marka",
      "create": "Bir nakliyat biçimi ekle",
      "disabledReason": {
        "blockedSections": "Engellenen kısımlar: {blockedSections}",
        "blockingDocument": "{blockingDocument} belgesinin geçerlilik tarihi ({minValidityDate}) nedeniyle bu nakliyat biçimini seçip hakkında bir ihbar gönderemezsiniz. Geçerlilik, nakliyenin bitişinden ({maxTransportDate}) önce sona eriyor.",
        "label": "Nakliyat biçiminin durumu: {status}",
        "rejectedSections": "Reddedilen kısımlar: {rejectedSections}"
      },
      "documents": {
        "disabled": "You can'Devre dışı bırakılmış bir araca belge ekleyemezsiniz"
      },
      "edit": {
        "disable": "Bu nakliyat biçimini devre dışı bırakmak istediğinizden emin misiniz?"
      },
      "label": "{count, plural, one {Nakliyat biçimi} other {Nakliyat biçimi}}",
      "serialNumberOfRecorder": "Bir kayıt cihazının seri numarası",
      "showEnabled": {
        "false": "Yalnızca etkinleştirilmiş nakliyat biçimlerini göster",
        "true": "Devre dışı bırakılan nakliyat biçimlerini de göster"
      },
      "status": {
        "ACCEPTED": "Kabul edildi",
        "AWAITING_VERIFICATION": "Kabul edilmedi",
        "BLOCKED": "Engellendi",
        "DISABLED": "Devre dışı",
        "REJECTED": "Reddedildi",
        "UPDATED": "Güncellendi"
      },
      "subcontractor": "Alt yüklenici",
      "verificationInfo": "Nakliyat biçimi {user} kullanıcısı tarafından {date} tarihinde onaylandı"
    },
    "noAccessibleStatisticsReports": "Herhangi bir rapor türüne erişiminiz yok",
    "noAccessibleTransportTypes": "Herhangi bir nakliye türüne erişiminiz yok",
    "note": {
      "autoGenerated": "Otomatik olarak oluşturuldu",
      "creator": "Oluşturan",
      "dateCreated": "Oluşturma:",
      "label": "Not",
      "note": "Not",
      "transport": "Nakliye"
    },
    "notification": {
      "ADVICE_CONFIRMATION": {
        "desc": "Bir ihbar kaydedildikten sonra oluşturana gönderilen bildirim.",
        "label": "Bir ihbar oluşturma işleminin onaylanması: {transportTypeName}",
        "labelShort": "Bir ihbar oluşturma işleminin onaylanması"
      },
      "ADVICE_CREATED": {
        "desc": "Bir ihbar kaydedildikten sonra gönderilen bildirim.",
        "label": "Bir ihbarın oluşturulması: {transportTypeName}",
        "labelShort": "Bir ihbarın oluşturulması"
      },
      "ADVICE_CREATED_SMS": {
        "desc": "Bir ihbar oluşturulduktan sonra sürücüye gönderilen SMS bildirimi",
        "label": "Bir ihbar oluşturulduktan sonra sürücüye gönderilen SMS bildirimi",
        "labelShort": "Bir ihbar oluşturulduktan sonra sürücüye gönderilen SMS"
      },
      "ADVICE_UPDATED": {
        "desc": "Bir ihbar değiştirildikten sonra ilgili kişilere gönderilen bir bildirim (ör. ihbarı oluşturan, bir tedarikçi \\\\ alıcı ise lojistikçiye ve taşıyıcılara e-posta gönderilir).",
        "label": "Bir ihbarın değiştirilmesi: {transportTypeName}",
        "labelShort": "Bir ihbarın değiştirilmesi"
      },
      "ADVICE_UPDATED_SMS": {
        "desc": "Bir ihbar düzenlendikten sonra sürücüye gönderilen SMS bildirimi",
        "label": "Bir ihbar düzenlendikten sonra sürücüye gönderilen SMS bildirimi",
        "labelShort": "Bir ihbar düzenlendikten sonra sürücüye gönderilen SMS"
      },
      "ADVICE_VERIFICATION": {
        "desc": "Bir ihbarın doğrulanması hakkında bildirim",
        "label": "Bir ihbarın doğrulanması hakkında bildirim",
        "labelShort": "Bir ihbarın doğrulanması"
      },
      "AUCTION_ENDED_WITHOUT_OFFERS": {
        "desc": "Bir ihale herhangi bir teklif olmadan bittiğinde gönderilen bir bildirim; bir ihalede fiyat teklifi verebilecek kullanıcılara gönderilir.",
        "label": "Bu ihalede fiyat teklifi yoktu: {transportTypeName}",
        "labelShort": "İhalede fiyat teklifi yok"
      },
      "AUCTION_LOST": {
        "desc": "Bir ihaleyi kaybettiğinizde gönderilen bir bildirim.",
        "label": "{transportTypeName} nakliyesinin ihalesi kaybedildi",
        "labelShort": "İhale kaybedildi"
      },
      "AUCTION_REMINDER": {
        "Shortabel": "İhale bitişi hatırlatması",
        "desc": "İhalenin bitişinin yaklaştığını hatırlatmak için gönderilen bir bildirim.",
        "label": "{transportTypeName} nakliyesi için ihale bitişi hatırlatması"
      },
      "AUCTION_WON": {
        "desc": "Bir ihaleyi kazandığınızda gönderilen bir bildirim.",
        "label": "{transportTypeName} nakliyesi için ihale kazanıldı",
        "labelShort": "İhale kazanıldı"
      },
      "CARRIER_CHOSEN": {
        "desc": "Bir taşıyıcı seçildiğinde veya değiştirildiğinde gönderilen bir bildirim",
        "label": "{transportTypeName} nakliyesi için taşıyıcı seçildi",
        "labelShort": "Taşıyıcı seçildi"
      },
      "CHECKPOINT_CONFIRMED_BY_SMS": {
        "desc": "Belirli bir durumun planlanan onay tarihi ile birlikte sürücüye gönderilen SMS bildirimi.",
        "label": "Durum onayı istemek için sürücüye SMS bildirimi",
        "labelShort": "Durum onayı istemek için sürücüye SMS bildirimi"
      },
      "CHECKPOINT_CONFIRMED_SMS": {
        "desc": "Kontrol noktası onaylandıktan sonra sürücüye gönderilen SMS bildirimi",
        "label": "Kontrol noktası onaylandıktan sonra sürücüye SMS bildirimi",
        "labelShort": "Kontrol noktası onaylandıktan sonra SMS"
      },
      "CHECKPOINT_CONFIRMED_SMS_ASSOCIATED_WITH_USER": {
        "desc": "Kontrol noktası onaylandıktan sonra kullanıcıya gönderilen SMS bildirimi",
        "label": "Kontrol noktası onaylandıktan sonra kullanıcıya SMS bildirimi",
        "labelShort": "Kontrol noktası onaylandıktan sonra kullanıcıya SMS bildirimi"
      },
      "CONFIRMED_CHECKPOINT": {
        "desc": "{checkpointName} durumu onaylandığında gönderilen bir bildirim.",
        "label": "{transportTypeName} nakliye türü için {checkpointName} onay durumu ",
        "labelShort": "Durum onayı"
      },
      "DISCREPANCY_PROTOCOL_CREATED": {
        "desc": "Sapma kaydı oluşturulduktan sonra, kaydın oluşturulduğu siparişe erişimi olan kullanıcılara gönderilen bir bildirim.",
        "label": "{transportTypeName} için bir sapma kaydı oluşturma",
        "labelShort": "Bir sapma kaydı oluşturma"
      },
      "INVITATION": {
        "desc": "Şununla ortaklık yapma daveti:",
        "label": "Şununla ortaklık yapma daveti:",
        "labelShort": "Ortaklık daveti"
      },
      "LATE_ADVICE": {
        "desc": "Sürücünün varışı gecikmeliyse gönderilen'bildirim.",
        "label": "İhbar gecikmesi: {transportTypeName}",
        "labelShort": "İhbar gecikmesi"
      },
      "LATE_DOCUMENT_EMAIL": {
        "desc": "Tedarikçi nakliyat belgelerini zamanında eklemezse gönderilen bildirim.",
        "label": "Belge ekleme gecikmesi: {transportTypeName}, {checkpointName}",
        "labelShort": "Belge ekleme gecikmesi"
      },
      "MODE_OF_TRANSPORTATION_UPDATED": {
        "desc": "Bir nakliyat biçimi güncellendikten sonra gönderilen bir bildirim",
        "label": "Nakliye biçiminde güncelleme",
        "labelShort": "Nakliye biçiminde güncelleme"
      },
      "NEW_ADDED_OFFER": {
        "desc": "Taşıyıcı yeni bir teklif eklediğinde gönderilen bildirim.",
        "label": "Yeni bir teklif ekleme: {transportTypeName}",
        "labelShort": "Yeni bir teklif ekleme"
      },
      "NEW_AUCTION": {
        "desc": "Bir ihalede fiyat teklifi verebilecek kullanıcılara ve ihale oluşturulurken seçilen şirketlerin kullanıcılarına gönderilen bir bildirim.",
        "label": "{transportTypeName} nakliyesi için yeni bir ihale",
        "labelShort": "Yeni bir ihale"
      },
      "NEW_MODE_OF_TRANSPORTATION": {
        "desc": "Yeni bir nakliyat biçimi eklendikten sonra gönderilen bir bildirim",
        "label": "Yeni nakliyat biçimi",
        "labelShort": "Yeni nakliyat biçimi"
      },
      "NEW_OFFER": {
        "desc": "Sorgunun oluşturulduğu sırada seçilen şirketlerin kullanıcılarına gönderilen bildirim.",
        "label": "Nakliye için yeni sorgu: {transportTypeName}",
        "labelShort": "Nakliye için yeni sorgu"
      },
      "NEW_USER_IN_COMPANY": {
        "desc": "Şirkete yeni bir kullanıcı eklendiğinde gönderilen bildirim",
        "label": "Şirkete yeni bir kullanıcı eklendiğinde gönderilen bildirim",
        "labelShort": "Şirkette yeni kullanıcı"
      },
      "OFFER_HAS_BEEN_OUTBID": {
        "desc": "Bir ihaledeki ilk sırada değişiklik olduğunda gönderilen bir bildirim.",
        "label": "Teklifin üstüne çıkıldı: {transportTypeName}",
        "labelShort": "Teklifin üstüne çıkıldı"
      },
      "OFFER_REJECTED": {
        "desc": "Fiyat teklifi seçilmemişse sorgu aşamasında seçilen şirketlerin kullanıcılarına gönderilen bir bildirim",
        "label": "{transportTypeName} nakliyesi için sorguda fiyat teklifi seçilmedi",
        "labelShort": "Sorguda fiyat teklifi seçilmedi"
      },
      "ORDER_PASSED": {
        "desc": "Bir sipariş taşıyıcıya aktarıldığında taşıyıcı'şirketin kullanıcılarına gönderilen bir bildirim",
        "label": "Sipariş aktarıldı: {transportTypeName}",
        "labelShort": "Sipariş aktarıldı"
      },
      "ORDER_WAITING_FOR_CONFIRMATION": {
        "desc": "Bir sipariş taşıyıcıya aktarıldığında ve taşıyıcının'onayını beklediğinde taşıyıcı şirketin kullanıcılarına gönderilen'bir bildirim",
        "label": "Sipariş onay bekliyor: {transportTypeName}",
        "labelShort": "Sipariş onay bekliyor"
      },
      "REJECTED_CHECKPOINT": {
        "desc": "{checkpointName} durumu reddedildiğinde gönderilen bir bildirim.",
        "label": "{transportTypeName} nakliye türü için {checkpointName} durumunun reddi",
        "labelShort": "Durum reddi"
      },
      "TIME_WINDOW_ADDED": {
        "desc": "Siparişe yeni bir zaman dilimi eklendikten sonra gönderilen bir bildirim",
        "label": "Zaman dilimi oluşturuldu",
        "labelShort": "Dilim oluşturuldu"
      },
      "TIME_WINDOW_EDITED": {
        "desc": "Kullanıcı zaman dilimini düzenlediğinde gönderilen bildirim",
        "label": "Kullanıcı zaman dilimini düzenlediğinde gönderilen bildirim",
        "labelShort": "Düzenlenen zaman dilimi"
      },
      "TRANSPORT_CREATED": {
        "desc": "Bir nakliye kaydedildikten sonra yüklenicilere gönderilen bildirim.",
        "label": "Bir nakliyenin oluşturulması: {transportTypeName}",
        "labelShort": "Bir nakliyenin oluşturulması"
      },
      "TRANSPORT_DELETED_EMAIL": {
        "desc": "Nakliye silindiğinde gönderilen bildirim",
        "label": "Nakliye silindiğinde gönderilen bildirim",
        "labelShort": "Nakliye silindi"
      },
      "TRANSPORT_DELETED_SMS": {
        "desc": "Nakliye silindikten sonra SMS bildirimi (e-posta ihbarları)",
        "label": "Nakliye silindikten sonra SMS bildirimi (e-posta ihbarları)",
        "labelShort": "Nakliye silindikten sonra SMS (e-posta ihbarları)"
      },
      "roleGroups": {
        "desc": "Belirli bir roldeki kullanıcılar, belirli bir bildirimi alabilir ve hesap ayarlarında bildirimleri etkinleştirebilir/devre dışı bırakabilir.",
        "label": "Kullanıcı rolleri",
        "labelShort": "Kullanıcı rolleri"
      }
    },
    "notificationLog": {
      "TYPE_EMAIL": "Bir e-posta gönderildi",
      "TYPE_SMS": "Bir kısa mesaj gönderildi"
    },
    "notificationToast": {
      "deliveryTransport": {
        "ADD_DELIVERY_TO_TRANSPORT": {
          "error": "Incompatible loading cities in deliveries!"
        },
        "CREATE": {
          "error": "Error during planning of transport: ",
          "loading": "In progress of planning transport: ",
          "success": "Successfully planned transport: "
        }
      },
      "titles": {
        "error": "Hata",
        "loading": "Yükleme",
        "success": "Başarılı"
      },
      "transportCombining": {
        "error": "Nakliyeler birleştirilirken hata oluştu",
        "loading": "Nakliye birleştirme sürüyor",
        "success": "Nakliyeler birleştirildi"
      }
    },
    "notifications": {
      "add": "Bildirim ekle",
      "appendTransportFiles": "Eklenmiş dosyaları gönder",
      "availableNotifications": "Kullanılabilecek bildirimler",
      "edit": "Düzenle",
      "label": "Bildirimler",
      "legendAdviceUpdated": "İHBAR GÜNCELLENDİ bildirim mesajı için kullanılan değişkenler",
      "legendCommon": "Genel değişkenler",
      "legendDescriptions": "SMS içeriğinde değişkenler kullanabilirsiniz. SMS gövdesinde \"%\" ile ayırarak değişken adını girmeniz yeterlidir. Örnek değişken kullanımı: %TRAILER_REG_NUM%",
      "mailNotification": "E-posta bildirimi",
      "manualNotification": "Elle bildirim",
      "messageEn": "İngilizce içerik",
      "messageInputType": {
        "ADDRESS_CITY": "Boşaltma şehri",
        "CAR_REG_NUM": "Aracın ruhsat numarası:",
        "COMPANY_SHORT_NAME": "Şirketin kısa adı",
        "GATE": "Gate's name in location",
        "LOCATION": "Depo konumu",
        "NEW_ADVICE_DATE": "Yeni ihbar tarihi",
        "OLD_ADVICE_DATE": "Eski ihbar tarihi",
        "OLD_REGISTRATION_NUMBER": "Aracın eski ruhsat numarası",
        "PLACE_NAME": "Queue's place name",
        "QUEUE_NAME": "Kuyruk adı",
        "RAMP_MESSAGE_EN": "Nakliyeye atanan rampa hakkında ek bilgiler EN",
        "RAMP_MESSAGE_PL": "Nakliyeye atanan rampa hakkında ek bilgiler TR",
        "RAMP_NUMBER": "Rampa numarası",
        "SQUARE_NAME": "Kare adı",
        "TIME_WINDOW_DATES": "Zaman dilimi tarihleri",
        "TRAILER_REG_NUM": "Römork ruhsat numarası",
        "TRANSPORT_ID": "Nakliye kimlik numarası"
      },
      "messagePl": "Türkçe içerik",
      "new": "Yeni bildirim",
      "noNotificationsForRole": "Seçilen rolde kullanılabilecek bildirim yok.",
      "selectRole": "Lütfen önce bir rol seçin.",
      "sureToDelete": "Bu bildirimi silmek istediğinizden emin misiniz?",
      "task": "Görev",
      "transportType": "Nakliye türü"
    },
    "offer": {
      "auction": "İhale",
      "company": "Şirket",
      "creator": "Kullanıcı",
      "dateCreated": "Oluşturulma tarihi",
      "deleted": "Silindi",
      "description": "Teklifle ilgili yorumlar",
      "edit": "Düzenle",
      "enabled": "Etkin",
      "errors": {
        "auction": {
          "hasEnded": "Üzgünüz'ama bu siparişin ihalesi zaten tamamlandı. Teklifinizi kaydedemedik."
        },
        "price": {
          "higherThanMaximum": "Girilen fiyat, bu sipariş için Ana Şirket tarafından ayarlanan maksimum fiyattan yüksek.",
          "lowerThanMinimum": "Girilen fiyat, bu sipariş için Ana Şirket tarafından ayarlanan minimum fiyattan düşük.",
          "nonPositiveNumber": "Verilen teklif, sıfırdan büyük bir sayı olmalıdır.",
          "reductionNotMet": "Üzgünüz ama {principalCompanyName} tarafından istendiği şekilde, önceki teklifinizden düşmeniz gereken minimum tutar {minReductionSize} {currencySymbol}."
        }
      },
      "hasWon": "Kazanan teklif",
      "label": "Teklif",
      "place": "yer",
      "price": "Teklifiniz",
      "revoke": {
        "alert": "Bu sipariş için güncel teklifi iptal etmek istediğinizden emin misiniz?",
        "fail": {
          "CannotRevokeOffer": "Bu teklifi iptal edemezsiniz."
        },
        "label": "Güncel teklifi iptal et",
        "success": "Teklif başarıyla iptal edildi"
      },
      "save": "Teklifi kaydet",
      "savingInProgress": "Teklif kaydediliyor...",
      "valid": "Geçerli"
    },
    "packageTypes": {
      "CARTONS": {
        "label": "Koliler",
        "lowerCase": "koli",
        "singular": "koli"
      },
      "CHEP_PALLETS": {
        "label": "CHEP palet",
        "lowerCase": "CHEP palet",
        "singular": "CHEP palet"
      },
      "CUBIC_METERS": {
        "label": "Metreküp",
        "lowerCase": "metreküp",
        "singular": "Metreküp"
      },
      "DISPOSABLE_PALLETS": {
        "label": "Kullan-at palet",
        "lowerCase": "kullan-at palet",
        "singular": "Kullan-at palet"
      },
      "EURO_PALLETS": {
        "label": "EURO palet",
        "lowerCase": "EURO palet",
        "singular": "EURO palet"
      },
      "LOADING_PLACES": {
        "label": "Yükleme yeri",
        "lowerCase": "yükleme yeri",
        "singular": "Yükleme yeri"
      },
      "PALLETS": {
        "label": "Paletler",
        "lowerCase": "palet",
        "singular": "palet"
      },
      "PALLETS_WITH_RETURNED_GOODS": {
        "label": "İade edilen mal paleti",
        "lowerCase": "iade edilen mal paleti",
        "singular": "İade edilen mal paleti"
      },
      "PALLET_PLACES": {
        "label": "Palet yeri",
        "lowerCase": "palet yeri",
        "singular": "Palet yeri"
      },
      "RETURNABLE_PALLETS": {
        "label": "İade edilebilen palet",
        "lowerCase": "iade edilebilen palet",
        "singular": "İade edilebilen palet"
      },
      "SPATIAL_METERS": {
        "label": "Metrekare",
        "lowerCase": "metrekare",
        "singular": "Metrekare"
      },
      "THE_NUMBER_OF_ITEMS": {
        "label": "Öge sayısı",
        "lowerCase": "öge sayısı",
        "singular": "Tek parça"
      },
      "noRelationsWithPackageTypes": "Nakliye türü ile paket türleri arasında ilişki yok",
      "referenceQuantity": "{referencePackage} olarak adet: {referenceQuantity}",
      "transportTypes": {
        "confirmDelete": "Nakliye türü ile paket türü arasındaki ilişkiyi silmek istediğinizden emin misiniz?",
        "defaultValue": "Varsayılan değer",
        "idx": "Siparişi göster",
        "minValue": "Minimum değer",
        "multiplier": "{referencePackage} başına {package}",
        "packageType": "Paket türü",
        "referencePackage": "Dikkat! Referans paket türünün ayarlarını değiştiriyorsunuz!",
        "referencePackageChkbox": "Referans paket",
        "required": "Zorunlu",
        "transportType": "Nakliye türü",
        "usedForMaxPalletLimitChkbox": "Maks. palet sınırı sayısı için ekleyin",
        "visibleInContractorStep": "Yüklenici aşamasında görünür",
        "visibleInLoadPackageQuantityInTransportTable": "Yükleme adedi nakliye tablosunda görünür",
        "visibleInUnloadPackageQuantityInTransportTable": "Boşaltma adedi nakliye tablosunda görünür",
        "visibleInWarehouseStep": "Depo aşamasında görünür"
      }
    },
    "page": {
      "pageTitle": "Lojistik platformu"
    },
    "paidFeaturesInfo": {
      "authorities": {
        "UNLIMITED_ACCESS_TO_ARCHIVED_TRANSPORTS": "Şirketinizin geçerli fiyatlandırma planı, son <b>3 ayın</b> siparişlerine erişmenize izin veriyor."
      },
      "label": "Kullanılabilecek şirket fiyatlandırma planları hakkında bilgi almanızı öneririz"
    },
    "partnership": {
      "ANTI_CORRUPTION_TYPE": "Yolsuzlukla Mücadele Beyanı",
      "CONTRACTOR_GROUP_ADDED": "Grup eklendi",
      "CONTRACTOR_GROUP_REMOVED": "Grup kaldırıldı",
      "GROUPS": "Gruplar",
      "INSURANCE_DOMESTIC_OCP": "Yurt İçi Nakliyede Taşıyıcı Sorumluluk Sigortası",
      "INSURANCE_INTERNATIONAL_OCP": "Uluslararası Nakliyede Taşıyıcı Sorumluluk Sigortası",
      "INSURANCE_OC": "Üçüncü Şahıs Sigortası",
      "INSURANCE_OCS": "Nakliye Komisyoncusunun'Sorumluluk Sigortası",
      "LICENCE_DOMESTIC": "Yurt İçi Yetki Belgesi",
      "LICENCE_FORWARDING": "Nakliye Komisyonculuğu Yetki Belgesi",
      "LICENCE_INTERNATIONAL": "Uluslararası Yetki Belgesi",
      "MAIN_DATA_TYPE": "Ana veriler",
      "SECONDATY_DATA_TYPE": "İkincil veriler",
      "STATUS_CHANGED": "Durum değişikliği",
      "TYPE_EMAIL": "E-posta gönderildi",
      "add": {
        "hint": "Tedarikçi ile taşıyıcı arasında ortaklık ekleyin.",
        "label": "Ortaklık ekle",
        "title": "Yükleniciler arasında ortaklık ekle"
      },
      "additionalDetails": "Ek ayrıntılar",
      "adviceVerification": {
        "firstPartLabel": "Her ihbardan önce verileri doğrulamak için",
        "secondPartLabel": "bir e-posta gönder"
      },
      "allowsPeriodicTransport": "Düzenli ihbarlar",
      "auxiliary": {
        "editionDisabled": "Bunu düzenleyemezsiniz.",
        "title": "{contractorName} şirketi, {principalCompanyName} şirketinin yüklenicisi olarak görev yapıyor.",
        "usersEditionDisabled": "Kullanıcılarını düzenleyemezsiniz."
      },
      "connectAll": {
        "CARRIER": "taşıyıcılar",
        "SUPPLIER": "tedarikçiler",
        "SUPPLIER.CLIENT": "müşteriler",
        "cofirmMsg": "{companyName} şirketini, {principalCompanyName} alanındaki tüm {contractorsType} türlerine bağlamak istediğinizden emin misiniz?",
        "label": "Tüm yüklenicileri birbirine bağla",
        "success": "Ortaklıklar oluşturuldu"
      },
      "contractor": "Yüklenici",
      "contractorOfCompany": "/ {companyName} şirketi için",
      "dateCreated": "Oluşturulma tarihi",
      "deleteMsg": "Yükleniciler arasındaki bu ortaklığı silmek istediğinizden emin misiniz?",
      "errors": {
        "additionalDetails": {
          "length": "Maksimum karakter sayısı 1000'dir"
        },
        "adviceVerificationInterval": {
          "intervalFormatError": "Yanlış aralık formatı; bir sayı ve ardından bir zaman birimi girin: s (saniye), m (dakika), h (saat) veya d (gün), ör. 48h"
        },
        "contractorShortName": {
          "contractorShortNameTooLong": "Kısa ad çok uzun!"
        },
        "representativeEmail": {
          "email": "Lütfen geçerli bir e-posta adresi girin"
        },
        "representativePhoneNumber": {
          "regex": "Polonya'da kayıtlı telefon numarası, dokuz rakamdan oluşmalıdır"
        },
        "transportLimit": {
          "invalidNumber": "Nakliye sınırı bir doğal sayı olmalıdır."
        }
      },
      "logs": {
        "CONTRACTOR_GROUP_ADDED": "Yüklenici grubu eklendi",
        "CONTRACTOR_GROUP_REMOVED": "Yüklenici grubu silindi",
        "DOCUMENT_DISABLED": "Belge devre dışı bırakıldı",
        "DOCUMENT_SAVED": "Belge kaydedildi",
        "DOCUMENT_UPDATED": "Belge güncellendi",
        "FILE_ADDED": "Dosya eklendi",
        "FILE_DELETED": "Dosya silindi",
        "SECTION_CONFIRMED": "Kısım onaylandı",
        "SECTION_REJECTED": "Kısım reddedildi",
        "SECTION_SAVED": "Kısım kaydedildi",
        "SECTION_UPDATED": "Kısım güncellendi",
        "STATUS_CHANGED": "Durum değiştirildi",
        "TYPE_EMAIL": "E-posta bildirimi"
      },
      "representativeEmail": "Temsilci - e-posta",
      "representativeName": "Temsilci",
      "representativePhoneNumber": "Temsilci - telefon numarası",
      "transportLimit": "Nakliye sınırı",
      "transportLimitHint": "Sınır yoksa alanı boş bırakın.",
      "transportsCompletedCount": "Bunlar arasında tamamlananlar",
      "transportsCreatedCount": "Oluşturulan nakliyeler",
      "transportsInProgressCount": "Devam eden nakliyeler"
    },
    "partnerships": {
      "label": "Ortaklıklar"
    },
    "periodicTransport": {
      "cannotChangeCompany": "Bu şirketi değiştiremezsiniz; siparişe düzenli ihbar seçeneği etkinleştirilmiş en az bir şirket eklenmelidir",
      "cannotDeleteStep": "Bu aşamayı silemezsiniz; siparişe düzenli ihbar seçeneği etkinleştirilmiş en az bir şirket eklenmelidir",
      "deleteAllConfirmMsg": "İlerideki düzenli ihbarların tümünü silmek istiyor musunuz? \"İptal\", yalnızca bu siparişi siler",
      "errors": {
        "chooseAtLeastOneDay": "En az bir gün seçin",
        "chooseFirstDayOfPeriodicTransport": "İlk düzenli nakliyenin gerçekleşeceği, hafta içinde bir gün seçin",
        "endDateMustBeAfterStartDate": "Bitiş tarihi başlangıç tarihinden sonra olmalıdır",
        "incorrectRangeOption": "Yanlış aralık; olası değerler \"Günlük\", \"Haftalık\" veya \"Aylık\"tır",
        "windowMustBeInFirstDayOfPeriodicTransport": "Düzenli ihbarın ilk günü, planlanan ihbar tarihi ile aynı olmalıdır",
        "wrongDateRange": "Belirlenen bir seçenek \"{range}\" için, düzenli ihbarın maksimum tarih aralığı {interval} olabilir. Düzenli ihbar için daha kısa bir tarih aralığı ayarlayın."
      },
      "frequencies": {
        "DAILY": "Günlük",
        "MONTHLY": "Aylık",
        "WEEKLY": "Haftalık"
      },
      "frequency": "İhbarın sıklığı",
      "label": "Düzenli ihbar",
      "updateAllConfirmMsg": "İlerideki düzenli ihbarların tümünü güncellemek istiyor musunuz? \"İptal\", değişiklikleri yalnızca bu sipariş için kaydeder"
    },
    "pricingPlans": {
      "BASIC": "BASIC",
      "CLIENT": "CLIENT",
      "OPERATOR": "OPERATOR",
      "PRO": "PRO"
    },
    "product": {
      "INDEX": {
        "name": "Ürün adı",
        "name.autoCompletePlaceholder": "Ürünü adıyla aramak için yazmaya başlayın"
      },
      "ORDER": {
        "name": "Sipariş türü",
        "name.autoCompletePlaceholder": "Sipariş türüyle aramak için yazmaya başlayın"
      },
      "add": "Ürün ekle",
      "disableConfirm": "Bu ürünü silmek istediğinizden emin misiniz?",
      "disabled": "{index} ürününü başarıyla kaldırdık.",
      "errors": {
        "index": {
          "tooLong": "Girilen metin çok uzun. Maksimum uzunluk 255 karakterdir."
        },
        "name": {
          "tooLong": "Girilen metin çok uzun. Maksimum uzunluk 255 karakterdir."
        },
        "shortName": {
          "tooLong": "Girilen metin çok uzun. Maksimum uzunluk 255 karakterdir."
        }
      },
      "index": "Dizin",
      "index.autoCompletePlaceholder": "Bir ürünü diziniyle aramak için yazmaya başlayın",
      "saved": "{index} ürününü doğru şekilde kaydettik.",
      "shortName": "Kısa ad",
      "shortName.autoCompletePlaceholder": "Bir ürünü kısa adıyla aramak için yazmaya başlayın",
      "unit": "Birim",
      "updated": "{index} ürününü doğru şekilde güncelledik."
    },
    "products": {
      "INDEX": {
        "label": "Ürünler",
        "table": "Ürün tablosu"
      },
      "ORDER": {
        "label": "Ürünler",
        "table": "Ürün tablosu"
      }
    },
    "queues": {
      "disableConfirm": "Bu kuyruğu devre dışı bırakmak istediğinizden emin misiniz?",
      "englishName": "İngilizce ad",
      "idx": "Dizi",
      "updateSuccessMessage": "{queueName} kuyruğunun ayarları başarıyla güncellendi."
    },
    "queuesStatus": {
      "sendSmsModal": {
        "messagePlaceholder": "Kısa mesajı girin",
        "title": "Şu kuyruktaki sürücülere kısa mesaj:"
      },
      "tableHeader": "Güncel kuyruk durumunun özeti"
    },
    "ramp": {
      "additionalSmsNotificationInfoEn": "Additional information EN",
      "additionalSmsNotificationInfoPl": "Additional information PL",
      "backToWarehouse": "Depoya geri dön",
      "deleteDedicatedWindows": "Kullanılmayan/kullanılan özel zaman dilimlerini sil",
      "disableConfirm": "Bu rampayı devre dışı bırakmak istediğinizden emin misiniz?",
      "disabledMessage": "\"{rampName}\" rampasını başarıyla sildik.",
      "enableConfirm": "Bu rampayı etkinleştirmek istediğinizden emin misiniz?",
      "enabledMessage": "\"{rampName}\" rampasını başarıyla etkinleştirdik.",
      "errors": {
        "cannotMoveWindowException": "Dilimi, belirtilen yere taşıyamazsınız.",
        "idx": {
          "idxTooBig": "Rampa sırası, bu depodaki rampa sayısından daha büyük olmamalıdır.",
          "nonPositiveInteger": "Rampa sırası, pozitif bir tam sayı olmalıdır."
        },
        "shortName": {
          "sizeTooBig": "Maksimum kısa ad uzunluğu 10 karakterdir."
        },
        "wrongSwappedWindowSize": "Yerleri değiştirilen zaman dilimlerinin süreleri farklı"
      },
      "idx": "Sıra",
      "label": "Rampa",
      "maxPallets": "Palet sınırı",
      "roleGroupsLabel": "Rampayı kullanabilecek kullanıcıların rolleri",
      "updateSuccessMessage": "Rampa'için ayarlar güncellendi.",
      "wantToSwapWindow": "Zaman dilimlerinin yerini değiştirmek istiyor musunuz?"
    },
    "ramps": {
      "label": "Rampalar"
    },
    "relationsWizard": {
      "description": "Onaydan sonra, seçilen ögeler birbirine bağlanacak ve tek bir aracı temsil edecek. Onaydan sonra, seçilmeyen ögeler bağlanan nakliyelerin grubunda olmayacak.",
      "errors": {
        "atLeastOneOfAKind": "You must select at least one transport each from the list of existing links and the list of transports without links."
      },
      "label": "Nakliyeleri birleştirme",
      "table": {
        "button": {
          "cancel": "Return",
          "createRelations": "Create relations"
        },
        "column": {
          "address": "Adres",
          "relationIdentifier": "İlişki kimliği",
          "weight": "Ağırlık [kg]",
          "wz": "Erp kimliği"
        },
        "header": {
          "existingRelations": "Geçerli ilişkiler",
          "withoutRelations": "İlişkilendirilecek ögeler"
        }
      }
    },
    "reports": {
      "contractors": {
        "header": "Şunlar arasında yüklenen/boşaltılan nakliyeler için bir rapor oluştur:",
        "label": "Zaman dilimlerinden alınan rapor"
      },
      "label": "Raporlar"
    },
    "resetPasswordWindow": {
      "enteredPasswordsIsNotIdentical": "İki parola da aynı olmalıdır.",
      "header": "Sistem parolanızı değiştirin",
      "info": "Sistem güvenlik politikamız gereğince parolanızı sıfırlayın",
      "info2": "Yeni ve eski parolalar farklı olmalı ve 8 karakterden (rakam, küçük ve büyük harfler) oluşmalıdır."
    },
    "roleGroup": {
      "label": "Rol",
      "placeholder": "Bir rol seç"
    },
    "route": {
      "addNewRate": "Yeni ücret ekle",
      "addNewRoute": "Yeni güzergah ekle",
      "address": {
        "city": "Şehir",
        "country": "Ülke",
        "load": "Yükle",
        "postal": "Posta kodu",
        "unload": "Boşalt"
      },
      "addresses": "Adresler",
      "bestOffer": "En iyi teklif",
      "details": "Güzergah ayrıntıları",
      "edition": "Düzenle",
      "errors": {
        "description": {
          "length": "Girilen metin çok uzun. Maksimum uzunluk 255 karakterdir."
        }
      },
      "label": "Güzergah",
      "newRoute": "Yeni güzergah",
      "noTransportTypeAvailable": "Fiyat listesi olan herhangi bir nakliye türüne şu anda erişiminiz yok",
      "offer": {
        "carrier": "Taşıyıcı",
        "confirmation": {
          "failure": "Ne yazık ki teklifi onaylayamadık. Sistem yöneticinize başvurun.",
          "message": "Bu teklifi onaylamak istediğinizden emin misiniz?"
        },
        "confirmed": "Onaylandı",
        "date": "Ücret tarihi",
        "deleteMessage": "Bu teklifi kaldırmak istediğinizden emin misiniz?",
        "edition": "Düzenle",
        "number": "Teklif numarası",
        "price": "Ücret",
        "showLess": "Daha az teklif göster ({n})",
        "showMore": "Daha fazla teklif göster ({n})",
        "toggleTable": "Teklif listesini' aç/kapat",
        "validity": "Geçerlilik tarihleri"
      },
      "rate": "Ücret",
      "rates": {
        "label": "Rates"
      },
      "termOfValidity": "Geçerlilik süresi"
    },
    "scheduleActionSelectModal": {
      "addWindow": "Dilim ekle",
      "blockedWindow": "Blokaj dilimi",
      "createTransport": "Nakliye oluştur",
      "dedicatedWindow": "Özel dilim",
      "title": "İşlem seç"
    },
    "section": {
      "accept": "Kabul et",
      "dateOfArchiving": "Arşivlenme tarihi",
      "dateOfDeletion": "Silinme tarihi",
      "dateOfExpiration": "Son geçerlilik tarihi",
      "deleted": "Silindi",
      "disable": "Devre dışı bırak",
      "disabled": "Devre dışı",
      "expired": "Süresi doldu",
      "label": "kısım",
      "reject": "Reddet",
      "requestVerification": "Doğrulama talep et",
      "state": {
        "ACCEPTED": "Kabul edildi",
        "ARCHIVED": "Arşivlendi",
        "DELETED": "Silindi",
        "DOCUMENT_ADDED": "Güncellendi",
        "EXPIRED": "Süresi doldu",
        "PENDING": "Bekliyor",
        "REJECTED": "Reddedildi",
        "UPDATED": "Güncellendi",
        "label": "Kısım durumu"
      },
      "successMessage": "Kısmı başarıyla kaydettik",
      "update": "Güncelle"
    },
    "sectionType": {
      "ANTI_CORRUPTION_TYPE": "Yolsuzlukla Mücadele Beyanı",
      "BLOCKADES": "Etkin blokajlar",
      "GROUPS": "Gruplar",
      "HISTORY": "Geçmiş",
      "INSURANCE": "Sigorta",
      "INSURANCE_DOMESTIC_OCP": "Yurt İçi Nakliyede Taşıyıcı Sorumluluk Sigortası",
      "INSURANCE_INTERNATIONAL_OCP": "Uluslararası Nakliyede Taşıyıcı Sorumluluk Sigortası",
      "INSURANCE_OC": "Şirketin Üçüncü Şahıs Sorumluluğu",
      "INSURANCE_OCS": "Nakliye Komisyoncusunun'Sorumluluk Sigortası",
      "LICENCE": "Yetki Belgesi",
      "LICENCE_DOMESTIC": "Yurt İçi Yetki Belgesi",
      "LICENCE_FORWARDING": "Nakliye Komisyonculuğu Yetki Belgesi",
      "LICENCE_INTERNATIONAL": "Uluslararası Yetki Belgesi",
      "MAIN_DATA_TYPE": "Ana veriler",
      "SECONDARY_DATA_TYPE": "İkincil veriler",
      "SENSOR_CALIBRATION_CERTIFICATE": "Sensör kalibrasyon sertifikası",
      "SUMMER_MAPPING": "Yaz haritası",
      "USERS": "Kullanıcılar",
      "VEHICLE_MAIN_DATA_TYPE": "Nakliyat biçimi verileri",
      "WINTER_MAPPING": "Kış haritası"
    },
    "service": {
      "arrival": "Varış",
      "company": "Şirket",
      "contractorCompanyPlaceholder": "Şirket adı",
      "createTitle": "Hizmet planla",
      "departure": "Çıkış",
      "description": "Hizmetin açıklaması",
      "registrationNumber": "Ruhsat numarası",
      "selectCompany": "Şirket seçin",
      "serviceman": "Hizmet görevlisinin adı",
      "title": "Hizmet #%@1"
    },
    "serviceNotWorking": {
      "content": "Platform geçici olarak kullanılamıyor. Lütfen daha sonra tekrar deneyin. Yaşamış olabileceğiniz rahatsızlık için özür dileriz.",
      "header": "Sayın Kullanıcımız,",
      "signature": "Logintegra Ekibi"
    },
    "settings": {
      "backToSettings": "Ayarlara geri dön",
      "confirmDelete": "Bu ayarı silmek istediğinizden emin misiniz?",
      "create": "Ayar ekle",
      "desc": {
        "ADDITIONAL_ADD_FILE_BUTTON": "Sipariş formuna belge eklemek için ek düğme",
        "ADDITIONAL_CONFIRMATION_DURING_REMOVING_GRAY_WINDOW": "Gri blokaj dilimi kaldırılırken fazladan onay",
        "ADDITIONAL_INFO_ON_PLAIN_TEMPLATE_VIEW": "Sipariş formunun yazdırılabilen görünümünde gösterilen ek bilgiler",
        "ADDITIONAL_INFO_ON_PLAIN_TEMPLATE_VIEW_EN": "Sipariş formunun çıktı görünümünde gösterilen ek bilgiler - İngilizce sürüm",
        "ADD_FILE_TO_TRANSPORT_AFTER_OFFER_ACCEPTATION": "İhale kazanıldıktan sonra veya sipariş taşıyıcıya aktarıldıktan sonra nakliyeye dosya eklenip eklenmeyeceğini belirler",
        "ADD_FILE_TO_TRANSPORT_AFTER_OFFER_ACCEPTATION_NAME": "İhale kazanıldıktan sonra veya sipariş taşıyıcıya aktarıldıktan sonra nakliyeye eklenen dosyanın adı",
        "ADVICE_CREATED_EMAIL_FILE_NAME": "İhbar ekleme hakkındaki e-posta sürümü",
        "ADVICE_CREATED_EMAIL_SUBJECT": "İhbar ekleme hakkındaki e-postanın konusu",
        "ADVICE_UPDATED_EMAIL_FILE_NAME": "İhbar düzenleme hakkındaki e-posta sürümü",
        "ADVICE_VERIFICATION_EMAIL_ENABLED": "Her ihbardan önce verileri doğrulamak için bir e-posta gönderilmesiyle ilgili alanların şirket ayrıntılarında görünüp görünmeyeceğini belirler",
        "ALT_IDENTIFIER_LABEL": "Sipariş numarasını belirten sütunun etiketi",
        "ALT_IDENTIFIER_LABEL_EN": "Sipariş numarasını belirten sütunun etiketi - İngilizce",
        "ALT_IDENTIFIER_RESET_INTERVAL": "Otomatik olarak oluşturulan \"altIdentifier\" numarası dizisinin sıfırlanma sıklığı",
        "ANNOUNCEMENTS_COUNTER_POLL_INTERVAL": "ms cinsinden veri yenileme sıklığı - duyuru sayacı",
        "API_MESSAGE_URL": "API mesajı gönderme adresi",
        "ARCHIVING_JOB_INTERVAL": "Son durumun onaylanmasından sonra ne kadar süre içinde nakliyeyi arşive taşımalıyız?",
        "ASSORTMENT_GROUP_LABEL": "Tasnif grubunu belirten form alanının etiketi",
        "ASSORTMENT_GROUP_LABEL_EN": "Tasnif grubunu belirten form alanının etiketi - İngilizce",
        "AUCTION_ENDED_WITHOUT_OFFERS_EMAIL_FILE_NAME": "Fiyat teklifi verilmemiş bir ihalenin bitişi hakkındaki e-posta sürümü",
        "AUTOMATIC_DELETING_DEDICATED_WINDOWS_INTERVAL": "Plandaki zamanlarına göre, kullanılmayan özel dilimlerin silineceği zaman ('Boş/dolu özel dilimleri otomatik olarak sil' seçeneği işaretlenmişse). Örneğin, değer '-2h' ise 16:00 (4:00 ÖS) saatindeki kullanılmayan özel dilim 14:00'da (2:00 ÖS) kaldırılır.",
        "AUTOMATIC_DELETING_UNUSED_DEDICATED_WINDOWS_ENABLED": "Kullanılmayan özel zaman dilimlerini otomatik olarak sil",
        "AUTOMATIC_DELETING_USED_DEDICATED_WINDOWS_ENABLED": "Kullanılan özel zaman dilimlerini otomatik olarak sil",
        "AUTOMATIC_GENERATE_DOCUMENT": "shouldGenerateDocument işaretini gösterip göstermeyeceğimizi belirler",
        "AUTOMATIC_WEIGHING": "Otomatik tartım işlevini etkinleştirir",
        "AUTO_GENERATED_DELIVERY_NOTE_FIXED_TEXT": "Otomatik olarak oluşturulan irsaliyede gösterilen sabit metin",
        "AVAILABLE_LOGIN_PAGE_BACKGROUND_FILE_NAMES": "Kullanılabilen oturum açma sayfası arka planlarının dosya adları",
        "CAN_ARRIVAL_DATE_BE_IN_THE_PAST": "Varış tarihinin geçmişte olup olamayacağını belirler",
        "CAN_EXPORT_INDEXES_TO_XLS": "Dizinleri bir Excel dosyasına aktarma seçeneğini etkinleştirir",
        "CAN_REFRESH_MANUALLY": "Elle veri yenileme özelliği",
        "CHANGE_TRANSPORT_INTERVAL": "Tahmini yükleme/boşaltma tarihine göre nereye kadar tedarikçinin/taşıyıcının nakliyeyi değiştirmesi mümkün olmalı?",
        "CHECKPOINT_CONFIRMED_EMAIL_FILE_NAME": "Kontrol noktası onayı hakkındaki e-posta sürümü",
        "CHECKPOINT_REJECTED_EMAIL_FILE_NAME": "Kontrol noktası reddi hakkındaki e-posta sürümü",
        "CLASSIFICATIONS_TAB_POLL_INTERVAL": "ms cinsinden veri yenileme sıklığı - \"Sınıflandırma\" sekmesi",
        "CLASSIFICATION_RESOURCE_COMPLETED_DELAY": "Çıkış durumu henüz onaylanmamışsa nesnenin \"Kuyruklar\" sekmesindeki kuyruktan kaybolması için geçmesi gereken süreyi belirler",
        "CLASSIFIED_RESOURCE_DISAPPEARANCE_DELAY": "Sınıflandırılan nakliyelerin \"Sınıflandırma\" sekmesinden ne kadar sürelik bir gecikmeden sonra kaybolacağını belirtin.",
        "COMPANY_FORM_SHOW_REPRESENTATIVE": "Şirket formunda temsilciyle ilgili alanların görünürlüğü.",
        "CONTRACTOR_NUMBER_LABEL": "Yüklenicinin numarasını belirten alanın'adı",
        "CONTRACTOR_NUMBER_LABEL_EN": "Yüklenicinin numarasını belirten alanın'adı - İngilizce",
        "CUSTOM_FIELD_SPLIT_VALUE": "Aşıldığı takdirde, nakliye formunda alanların iki sütuna bölüneceği tanımlanmış özel alan sayısı",
        "DAYS_TO_RESET_PASSWORD": "Kullanıcıdan, gün olarak ne sıklıkta parola değiştirmesi istenmeli?",
        "DAYS_TO_SHOW_ADVICE_HISTORY": "İhbarın oluşturulması/düzenlenmesi sırasında sürücünün verilerini ipucu listesinde ne zamandan itibaren göstermeliyiz (gün olarak bir zaman aralığı ayarlayın)?",
        "DEFAULT_ADVICE_FUTURE_DAYS_LIMIT": "Yeni yüklenicilere atanan ihbarların gönderilebileceği varsayılan gün sınırı.",
        "DEFAULT_EMAIL_DELAY_INTERVAL": "E-posta göndermek için varsayılan gecikme",
        "DEFAULT_NUMBER_OF_DISPLAYED_OFFERS": "Fiyat listesindeki güzergah için varsayılan sipariş sayısı",
        "DEFAULT_TAB_AFTER_LOGIN": "Oturum açıldıktan sonra gösterilen varsayılan sekme",
        "DELAY_TIME_TO_MOVE_ORDER_TO_ARCHIVE_AFTER_TASK_TIMEOUT": "Siparişin otomatik olarak arşive taşınması için beklenecek gecikme süresi (yalnızca belirtilen görevler için)",
        "DELAY_TIME_TO_MOVE_TIME_WINDOW_TO_NEXT_FREE_SPOT": "Platformun dilimi mümkün olan en yakın dilime taşıması için geçecek gecikme süresi",
        "DISPLAY_INDEXES_IN_SCHEDULE": "Plan görünümünde bir dizin listesi göster",
        "DISPLAY_TRAILER_REG_NUMBER_IN_ADVICE_COLUMN": "\"İhbar\" sütununda bir ruhsat numarası yerine bir Römork ruhsat numarası gösterir",
        "DOCUMENTS_MONITORING_INTERVAL": "Müşteriye e-posta gönderilmeden önce geçmesi beklenecek olan, belge ekleme gecikmesinin uzunluğu.",
        "DOCUMENT_SECTION_VISIBLE_IN_TRANSPORT_FORM": "Nakliye formunda'belgenin kısım görünürlüğü",
        "DRIVER_PUNCTUALITY_INTERVAL": "Aşama onayının gecikmesini belirleyen zaman diliminin başlangıcına göre geçen süre",
        "EMAIL_NOTIFICATION_CONTAINS_PRODUCTS_SECTION": "Ürünler kısmının e-postalarda görünür olup olmayacağını belirler",
        "EMAIL_NOTIFICATION_CONTAINS_UNLOAD_SECTION": "Boşaltma kısmının e-postalarda görünür olup olmayacağını belirler",
        "ENABLE_TRANSPORT_ROW_HIGHLIGHTING": "Bir nakliye satırının tıkladıktan sonra vurgulanıp vurgulanmayacağını belirler",
        "ENABLE_TRANSPORT_TABLE_DAYS_LIMIT": "Etkin siparişler tablosunda, belirtilen bir tarihin ilerisinde zaman dilimleri içeren siparişleri gizleyen bir filtreyi etkinleştirir",
        "END_OF_DAY_IN_WAREHOUSE_INTERVAL": "İhbar zaman dilimlerinin hesaplanmasında kullanılan, depoda iş gününün bitişi. Uyarı! Depoda iş gününün bitişi farklı olabilir. Örnek: Sonraki günün bitişini 6:00 olarak ayarlamak için parametre değeri şöyle olmalıdır: 30h [30.01. 00:00 + 30h = 31.01. 06:00]",
        "ERP_IDENTIFIER_LABEL": "ERP sipariş numarasını belirten sütunun etiketi",
        "ERP_IDENTIFIER_LABEL_EN": "ERP sipariş numarasını belirten sütunun etiketi - İngilizce",
        "FILE_TERMS_OF_CONDITIONS_ACCEPTED": "Hizmet şartlarının bağlantısı.",
        "FIND_ADDRESSES_MAX": "Otomatik doldurma özelliği tarafından döndürülen adres sayısı",
        "FOOTER": "Platformda gösterilen bir alt bilginin bağlantısı",
        "FOREIGN_DELIVERY_MULTIPLIER": "Yurt dışı teslimatlarla ilgili olarak, boşaltma uzunluğunun çarpılacağı değer.",
        "HAS_ACCESS_TO_APPLIES": "Taşıyıcı belgelerinin doğrulanmasının gerekip gerekmediğini'belirler",
        "HAS_ACCESS_TO_MAX_PALLET_PER_DAY": "Her gün için palet sınırının etkinleştirilip etkinleştirilmeyeceğini belirler",
        "HAS_ACCESS_TO_MAX_PALLET_PER_RAMP": "Her rampa için palet sınırının etkinleştirilip etkinleştirilmeyeceğini belirler",
        "HAS_ACCESS_TO_NUMBER_OF_WINDOWS": "Bir depoda belirli bir günün dilim sayısının gösterilip gösterilmeyeceğini belirler. Depoda günün başlangıcına ve bitişine bağlıdır.",
        "HD_MODE_NEXT_DAY_SHIFT_TIME": "HD görünümünde bir planın sonraki güne taşınma zamanını belirler (HH:mm formatında)",
        "HOW_MANY_DAYS_BEFORE_CERTIFICATE_EXPIRATION_SHOULD_MAIL_BE_SENT": "Sensör kalibrasyon sertifikasının geçerlilik süresinin bitişinden kaç gün önce, hatırlatma içeren bir e-posta bildirimi gönderileceği",
        "HOW_MANY_DAYS_BEFORE_DOCUMENT_EXPIRATION_DATE_CARRIER_SHOULD_BE_BLOCKED": "Belgenin son geçerlilik tarihinden kaç gün önce taşıyıcı engellenmeli?",
        "HOW_MANY_DAYS_BEFORE_INSURANCE_EXPIRATION_SHOULD_MAIL_BE_SENT": "Sigortanın geçerlilik süresinin bitişinden kaç gün önce, taşıyıcıya hatırlatma içeren bir e-posta bildirimi gönderileceği",
        "HOW_MANY_DAYS_BEFORE_MAPPING_EXPIRATION_SHOULD_MAIL_BE_SENT": "Haritanın geçerlilik süresinin bitişinden kaç gün önce, hatırlatma içeren bir e-posta bildirimi gönderileceği",
        "HOW_MUCH_TIME_BEFORE_START_WINDOW_EDITING_ORDER_SENDS_SMS": "İhbar düzenlemenin, zaman diliminin başlangıcından ne kadar süre önce mesaj göndermeyi tetikleyeceği.",
        "HYPERLINK_TO_LOGO_ON_NAVBAR": "Oturum açıldıktan sonra, gezinme çubuğunda gösterilen logonun köprü bağlantısı",
        "INVITATION_EXPIRATION_INTERVAL": "Davetin geçerlilik süresi.",
        "LATE_ADVICE_EMAIL_FILE_NAME": "Gecikme hakkındaki e-posta sürümü",
        "LIMITED_INVITATION_MODE": "Yönetici dışındaki, her şeyi yapabilen kullanıcılar, yalnızca kendi rollerine ve yüklenicilerine kullanıcı davet edebilir",
        "LINK_TO_APPLIES_MANUAL": "Uygulama Kılavuzunun bağlantısı",
        "LOADING_URL": "Platform URL'si",
        "LOCAL_PRINCIPAL_LANGUAGE": "Yerel şirket dili",
        "LOGO_ON_LOGIN_PAGE": "Oturum açma sayfasında gösterilen logonun bağlantısı.",
        "LOGO_ON_NAVBAR": "Oturum açıldıktan sonra, gezinme çubuğunda gösterilen logonun bağlantısı",
        "MANAGE_TRANSPORT_INTERVAL": "Tahmini yükleme/boşaltma tarihine göre nereye kadar nakliye ihbarı oluşturmak veya değiştirmek mümkün olmalı?",
        "MAXIMUM_NUMBER_OF_ORDERS_TO_COPY": "\"Benzerini oluştur\" işlevi kullanılarak oluşturulabilecek maksimum sipariş sayısı",
        "MAXIMUM_PERMISSIBLE_VEHICLE_WEIGHT_SETTING": "İzin verilebilecek maksimum araç ağırlığı [t]",
        "MAX_CHARACTERS_IN_INDEX_PRODUCTS_COLUMN": "Ürünler sütununda gösterilmesine izin verilen maksimum karakter sayısı",
        "MAX_CHARACTERS_IN_ORDER_NUMBER_COLUMN": "Sipariş numaralarını içeren sütunlarda gösterilen karakter sayısı",
        "MAX_CHARACTERS_IN_TRANSPORT_RELATION_COLUMN": "İlişkiler sütununda gösterilmesine izin verilen maksimum karakter sayısı",
        "MAX_NUMBER_OF_TIME_WINDOWS_FOR_SUGGEST": "Önerilen maksimum zaman dilimi sayısı",
        "MAX_SIMULTANEOUSLY_ADDED_FILES_COUNT": "Siparişe aynı anda eklenebilecek, izin verilen maksimum dosya sayısı",
        "MAX_WINDOW_LENGTH": "Zaman dilimi için izin verilen maksimum uzunluk",
        "MIN_WEIGHT_TO_CREATE_TRANSPORT": "Otomatik olarak nakliye oluşturmak için minimum ağırlık",
        "NAME_TERMS_OF_CONDITIONS_ACCEPTED": "Hizmet şartlarının adı.",
        "NAME_TERMS_OF_CONDITIONS_ACCEPTED_EN": "Hizmet şartlarının adı - İngilizce",
        "NEW_ADVICE_EMAIL_RECIPIENTS": "Yeni ihbar hakkında bildirim gönderilecek ek e-posta adresleri (virgülle ayrılmış olarak, ör. xyz@xyz.com,abc@abc.com)",
        "NEW_AUCTION_EMAIL_FILE_NAME": "Yeni bir ihale hakkındaki e-posta sürümü",
        "NEW_TRANSPORT_EMAIL_FILE_NAME": "Yeni bir sipariş hakkındaki e-posta sürümü",
        "ORDER_FORM_PRINTING_ENABLED": "Sipariş formunda yazdırılabilir sürüm olup olmayacağını belirler",
        "ORDER_WAITING_FOR_CONFIRMATION_EMAIL_FILE_NAME": "Siparişin taşıyıcıya aktarılması hakkındaki e-posta sürümü",
        "OWN_ADDRESSES_ONLY": "Yalnızca belirli bir ana şirket alanına girilen adreslere erişim (şirketlerin ana adresleri için geçerli değildir)",
        "PACKAGE_TYPES_FEATURE_ENABLED": "Paket ayarları yönetim sekmesinin görünürlüğü",
        "PATRON_IN_CONTRACTOR_DETAILS_ENABLED": "\"Müşteri\" alanının şirket ayrıntılarında görünüp görünmeyeceğini belirler",
        "PERCENTAGE_OF_TRANSPORTS_TAKEN_FOR_RANDOM_INSPECTIONS": "Rastgele kontroller için nakliyelerin yüzde kaçı yazılmalı?",
        "POLL_INTERVAL": "ms cinsinden veri yenileme sıklığı - tablo ve plan.",
        "QUEUES_TAB_POLL_INTERVAL": "ms cinsinden veri yenileme sıklığı - \"Kuyruklar\" ve \"Kuyruk Durumu\" sekmesi",
        "RESET_PASSWORD_IS_DISABLED": "Zorunlu parola sıfırlamayı devre dışı bırakın.",
        "ROLES_WITH_ENABLED_USER_AND_GROUP_FILTER": "Ek filtreleme etkinken (siparişlerim, grup siparişleri, tüm siparişler) rol listesi. Değerler ondalık işaretinden sonra belirtilir, ör. \"ADMIN, GUARD\".",
        "ROLE_ADD_CONTRACTOR_GROUP": "Yüklenici ayrıntılarındaki gruplandırma kısmına yeni bir grup'ekleme seçeneği",
        "ROLE_EDIT_CONTRACTOR_GROUP": "Mevcut yüklenici gruplarını düzenleme seçeneği",
        "SCHEDULE_LEGEND_ENABLED": "Planda açıklamayı etkinleştirir",
        "SEND_API_MESSAGE_EVERY_TRANSPORT_UPDATE": "Her nakliye güncellemesinden sonra API mesajı gönderilmesini etkinleştirir",
        "SEND_CONTRACTOR_LATE_ADVICE_MAIL": "Siparişi oluşturan ana şirketse harici yüklenicilere veya siparişi oluşturan yükleniciyse oluşturan yükleniciye gecikme e-postası gönderilip gönderilmeyeceğini belirler.",
        "SEND_FUTURE_PERIODIC_TRANSPORTS_EDIT_MAIL_INTERVAL_IN_DAYS": "Düzenli ihbarlar düzenlendiği takdirde e-posta bildirimlerinin gönderileceği zamana kadar geçecek olan gün sayısı",
        "SEND_SMS_AFTER_CREATING_ORDER": "Bir ihbar oluşturulduktan sonra sürücüye kısa mesaj gönder",
        "SEND_SMS_AFTER_EDITING_ORDER": "Bir ihbar düzenlendikten sonra sürücüye kısa mesaj gönder",
        "SET_SCHEDULE_LEGEND_TO_FIXED_POSITION": "Planda açıklamanın her zaman görünüp görünmeyeceğini belirler",
        "SHOULD_RENDER_ALL_TRANSPORT_TYPES": "Varsayılan olarak, etkin siparişler tablosunda tüm nakliye türlerini göster",
        "SHOW_COMPANY_VOIVODESHIP_FIELD": "Şirket verilerinde \"Voyvodalık\" alanının görünürlüğü",
        "SHOW_PRINCIPAL_ADDRESS_IN_PRINT_VIEW": "Yazdırma görünümünde ana şirket adresinin görünürlüğü",
        "SHOW_QUANTITY_SUM_CHECK_MESSAGE": "Bir siparişte X'ten fazla palet varsa bir mesaj göster",
        "SHOW_TERMS_OF_CONDITIONS_ACCEPTED": "Hizmet şartlarının kabul edilebileceği alan, ihbar formunda görünür olmalıdır.",
        "SHOW_TRANSPORT_COMBINING_MODAL_ON_SINGLE_OPTION": "Mümkün olan tek bir birleştirme seçeneği olsa da nakliyelerin birleştirilmesi için bir pencere gösterir",
        "SHOW_USERS_FOR_SUPPLIER": "Tedarikçi/alıcı ayrıntılarında bir kullanıcı listesini etkinleştirir",
        "SORT_TABLE_BY_TRANSPORT_COMPLETED": "Nakliye tablosundaki nakliyelerin varsayılan olarak \"Tamamlandı\" durumuna göre sıralanıp sıralanmayacağını belirler",
        "START_OF_DAY_IN_WAREHOUSE_INTERVAL": "İhbar zaman dilimlerinin hesaplanmasında kullanılan, depoda iş gününün başlangıcı. Dikkat! İş gününün başlangıcı farklı olabilir.",
        "SUPPLIER_FIELD_IN_COMPANY_DETAILS_TRANSLATION_KEY": "\"Tedarikçi/alıcı\" alanı için çeviri anahtarı. Olası değerler: 'CLIENT','COMPANY','COURIER'",
        "TERMS_OF_SERVICE_FILE_EXT": "Hizmet Şartları dosyasının uzantısı, ör. docx, pdf",
        "TIME_TO_ACCEPT_AUTO_ROUTE_OFFER_PASS": "Otomatik sipariş geçişi halinde siparişin kabul edileceği zaman",
        "TIME_TO_ACCEPT_TRANSPORT_INTERVAL": "Bir siparişin kabul edileceği zaman",
        "TIME_TO_RESTORE_ORDER_FROM_ARCHIVE": "Siparişin arşivden geri yüklenebilmesini için beklenecek süre",
        "TIME_WINDOW_OFFSET_INTERVAL": "Zaman dilimlerinin ayarlanabileceği dakika sayısı. Olası değerler 15, 30 veya 60'tır",
        "TRANSPORTS_LIST_LABEL": "Nakliyeler listesi etiketi",
        "TRANSPORTS_LIST_LABEL_EN": "Nakliyeler listesi etiketi - İngilizce",
        "TRANSPORT_DESCRIPTION_COLUMN_MAX_CHARS": "Notlar sütununda gösterilen maksimum karakter sayısı",
        "TRANSPORT_FREE_SLOT_SEARCH_INTERVAL_IN_DAYS": "Düzenli bir ihbar oluşturulurken programın boş bir zaman dilimi arayacağı aralık (gün olarak)",
        "TRANSPORT_IDENTIFIER_TYPE": "REST API erişimi olan kullanıcının nakliyelere erişeceği değeri belirtir. Kullanılabilecek seçenekler: ID, ERP_IDENTIFIER, ALT_IDENTIFIER.",
        "TRANSPORT_PAGINATION_DEFAULT_MAX_ROWS": "Nakliye tablosu sayfalarındaki sayfa başına maksimum varsayılan satır sayısını belirler",
        "TRANSPORT_TABLE_DAYS_LIMIT": "Aşıldığı takdirde, etkin siparişler tablosunda siparişlerin gizleneceği, geçerli tarihten itibaren geçecek süre (gün olarak)",
        "USE_ADFS_AUTH_FOR_API_MESSAGE": "Enables authentication for the ADFS service (when sending API messages)",
        "USE_WEBSOCKETS": "Websocket desteğini etkinleştirir",
        "WAREHOUSE_LABEL": "Depoyu belirten form alanının etiketi",
        "WAREHOUSE_LABEL_EN": "Depoyu belirten form alanının etiketi - İngilizce",
        "WAREHOUSE_ZONE_LABEL": "Depo bölgesini belirten form alanının etiketi",
        "WAREHOUSE_ZONE_LABEL_EN": "Depo bölgesini belirten form alanının etiketi - İngilizce",
        "WEEK_SCHEDULE_START_DATE_SHIFT": "Hafta görünümünde bir planın başına eklenen gün sayısı",
        "WEIGHT_OF_PALLET": "Kilogram cinsinden bir paletin ağırlığını gösterir",
        "WEIGHT_SELECTOR": "Sayfada ağırlığı olan bir ögenin belirtilmesi",
        "WEIGHT_URL": "Tartı uygulamasının bağlantısı",
        "WINDOW_CHANGE_MAIL_DELAY_INTERVAL": "Zaman dilimini ayarladıktan sonra, e-posta gönderilmeden önce geçmesi beklenecek olan gecikme uzunluğu.",
        "WINDOW_CONSTANT_TIME": "Dilimlerdeki boşaltma uzunluğuna eklenen, dakika cinsinden sabit değer.",
        "XLS_ADDRESSES_ENABLED": "XLS raporuna \"Adresler\" sütununu ekle",
        "XLS_DESCRIPTION_ENABLED": "XLS raporuna \"Notlar\" sütununu ekle",
        "XLS_EXTERNAL_ADDRESSES_DATA_ENABLED": "XLS raporuna \"Harici adres verileri\" sütununu ekle",
        "XLS_TOP_ROW_ENABLED": "XLS raporları için başlığı etkinleştirin."
      },
      "description": "Açıklama",
      "edit": "Ayarı düzenle",
      "editable": "Düzenlenebilir",
      "label": "Ana ayarlar",
      "name": "Ad",
      "other": "Diğer ayarlar",
      "restSettingLabel": "Diğer ayarlar",
      "roleGroups": {
        "actionIsPossible": "işlem yapılabilir",
        "actionType": "Yetki",
        "actionTypeDescription": {
          "ACCEPT_AND_REJECT_TRANSPORT": "Bu izin, yükleniciye aktarılan siparişlerin kabul edilmesini ve reddedilmesini sağlar.",
          "ACCESS_CARRIER_COMPANY_FIELD": "Nakliye formunda \"Taşıyıcı\" alanının görünürlüğünü ayarlar. \"Nakliye türüne erişim\" izni de gerekir",
          "ACCESS_CUSTOM_FIELD": "Nakliye formunda bir özel alanın görünürlüğünü ayarlar",
          "ACCESS_DESCRIPTION_FIELD": "Nakliye formunda \"Not\" alanının görünürlüğünü ayarlar. \"Nakliye türüne erişim\" izni de gerekir",
          "ACCESS_INDEX": "Bu roldeki bir kullanıcı dizin kısmına erişebilir.",
          "ACCESS_PACKAGES": "Nakliye formunda \"Paketler\" kısmının görünürlüğünü ayarlar. Şu izin de gerekir: \"Nakliye türüne erişim\"",
          "ACCESS_SERVICE_COMPANY_FIELD": "Nakliye formunda \"Hizmet\" alanının görünürlüğünü ayarlar. \"Nakliye türüne erişim\" izni de gerekir",
          "ACCESS_SUPPLIER_COMPANY_FIELD": "Nakliye formunda \"Tedarikçi/Alıcı\" alanının görünürlüğünü ayarlar. \"Nakliye türüne erişim\" izni de gerekir",
          "ACCESS_TRANSPORT": "Belirli bir nakliye türüne erişimi etkinleştirir (sipariş tablosunda nakliye türünün görünürlüğü)",
          "ADD_ADVICE": "İhbar verisi eklemenizi sağlar.",
          "ADD_DOCUMENTS": "Siparişe dosya ekleme seçeneğini etkinleştirir. Tabloda belgeleri içeren sütuna erişim gerekir.",
          "ADD_LOAD_STEP_FROM_LOAD": "Bir yükleme aşamasından sonra bir yükleme aşaması ekleme.  Şu nakliye türü ayarının da etkinleştirilmesi gerekir: \"Nakliye formunda bir aşama kısmının görünürlüğü\".",
          "ADD_LOAD_STEP_FROM_UNLOAD": "Bir boşaltma aşamasından sonra bir yükleme aşaması ekleme. Şu nakliye türü ayarının da etkinleştirilmesi gerekir: \"Nakliye formunda bir aşama kısmının görünürlüğü\".",
          "ADD_NOTES": "Sipariş tablosunda bir nakliyeye not ekleme seçeneğini etkinleştirir. Tabloda notları içeren sütuna erişim gerekir.",
          "ADD_PACKAGES": "Nakliye formunda \"Paketler\" kısmında bir paket ekleme seçeneğini etkinleştirir. Şu izin de gerekir: \"Paket kısmının görünürlüğü\"",
          "ADD_TIME_WINDOW": "Zaman dilimi ekleme seçeneğini etkinleştirir.",
          "ADD_UNLOAD_STEP_FROM_LOAD": "Bir yükleme aşamasından sonra bir boşaltma aşaması ekleme.  Şu nakliye türü ayarının da etkinleştirilmesi gerekir: \"Nakliye formunda bir aşama kısmının görünürlüğü\".",
          "ADD_UNLOAD_STEP_FROM_UNLOAD": "Bir boşaltma aşamasından sonra bir boşaltma aşaması ekleme. Şu nakliye türü ayarının da etkinleştirilmesi gerekir: \"Nakliye formunda bir aşama kısmının görünürlüğü\".",
          "ADVICE_ONLY_ON_DEDICATED_WINDOWS": "Zaman dilimlerini yalnızca özel dilimlere ekleme",
          "BID_AUCTION": "İhaleye katılma ve fiyat teklifi gönderme seçeneğini etkinleştirir.",
          "CREATE_AUCTION": "Bu izne sahip kullanıcı, ihale oluşturma seçeneğini kullanabilir.",
          "CREATE_TRANSPORT": "Yeni bir nakliye oluşturmanıza izin verir. Şu izin de gerekir: \"Nakliye türüne erişim\".",
          "DELETE_DOCUMENTS": "Kullanıcının nakliye için eklenen belgeyi silmesine izin verir. Tabloda belgeleri içeren sütuna erişim gerekir.",
          "DELETE_INDEX": "Nakliye için eklenen dizini silmenize izin verir.",
          "DELETE_LOAD_STEPS": "Yükleme aşamalarını silme seçeneğini etkinleştirir.  Şu nakliye türü ayarının da etkinleştirilmesi gerekir: \"Nakliye formunda bir aşama kısmının görünürlüğü\".",
          "DELETE_PACKAGES": "Nakliye formunda \"Paketler\" kısmında bir paket silme seçeneğini etkinleştirir. Şu izin de gerekir: \"Paket kısmının görünürlüğü\"",
          "DELETE_TRANSPORT": "Bu izin seviyesindeki kullanıcı, daha önce oluşturulmuş nakliyeleri silebilir. Şu izin de gerekir: \"Nakliye türüne erişim\".",
          "DELETE_UNLOAD_STEPS": "Boşaltma aşamalarını silme seçeneğini etkinleştirir.  Şu nakliye türü ayarının da etkinleştirilmesi gerekir: \"Nakliye formunda bir aşama kısmının görünürlüğü\".",
          "DISABLE_DATA_FIELDS": "Nakliye formunda verilerin devre dışı bırakılıp bırakılmayacağını belirler",
          "EDIT_COURIER_INVOICE_SECTION": "\"Mal faturası\" kısmını düzenleme seçeneğini etkinleştirir",
          "EDIT_DESCRIPTION_FIELD": "Sipariş ayrıntılarında \"Notlar\" alanını düzenleme seçeneğini etkinleştirir. Şu izin de gerekir: \"Nakliye türüne erişim\"",
          "EDIT_INDEX": "Dizinleri düzenleme seçeneğini etkinleştirir.",
          "EDIT_PACKAGES": "Nakliye formunda \"Paketler\" kısmındaki paketi düzenleme seçeneğini etkinleştirir. Şu izin de gerekir: \"Paket kısmının görünürlüğü\"",
          "EDIT_TRANSACTION_FREIGHT": "Taşıyıcıya aktarılan siparişte yükü düzenleme seçeneğini etkinleştirir",
          "MOVE_ADVICE_WINDOW": "İhbar zaman dilimlerini taşıma seçeneğini etkinleştirir",
          "MOVE_LOAD_STEPS": "Formdaki yükleme aşamalarını taşıma seçeneğini etkinleştirir.  Şu nakliye türü ayarının da etkinleştirilmesi gerekir: \"Nakliye formunda bir aşama kısmının görünürlüğü\".",
          "MOVE_UNLOAD_STEPS": "Formdaki boşaltma aşamalarını taşıma seçeneğini etkinleştirir. Şu nakliye türü ayarının da etkinleştirilmesi gerekir: \"Nakliye formunda bir aşama kısmının görünürlüğü\".",
          "PASS_COURIER": "Nakliyeyi kuryeye aktarma seçeneğini etkinleştirir.",
          "PASS_TRANSPORT": "Nakliyeyi yükleniciye aktarma seçeneğini etkinleştirir.",
          "RESIZE_ADVICE_WINDOW": "İhbar zaman dilimlerinin boyutunun değiştirilmesine izin verir. Şu iznin de: \"İhbar zaman dilimini taşı\" doğru çalışması gerekir.",
          "ROLE_ACCESS_AUCTION_DATA": "İhale verilerinin görünürlüğünü etkinleştirir",
          "ROLE_ACCESS_COURIER_INVOICE_SECTION": "\"Mal faturası\" kısmının görünürlüğünü ayarlar",
          "ROLE_ACCESS_FREIGHT_DATA": "Taşıma ücretinin görünürlüğünü etkinleştirir",
          "ROLE_ACCESS_TO_INSPECTION_BUTTON": "\"İnceleme\" düğmesinin görünürlüğü",
          "ROLE_ACCESS_TO_INSPECTION_PANEL": "\"İnceleme\" panelinin görünürlüğü",
          "ROLE_ACCESS_TO_LOCATION": "Visibility of the 'Konum' field.",
          "ROLE_ACCESS_TRANSPORT_ADDITIONAL_DESCRIPTION": "\"Ek Notlar\" alanının görünürlüğü",
          "ROLE_ACCESS_WEIGHT_SECTION": "Nakliye formunda \"Tartım\" kısmının görünürlüğünü ayarlar. Şu izin de gerekir: \"Nakliye türüne erişim\"",
          "ROLE_ACCESS_WORKER_FIELD": "İzin, nakliye oluşturma formunda \"Çalışanlar\" alanının görünürlüğünü sağlar",
          "ROLE_AUTOMATIC_PASS_TRANSPORT_TO_ROUTE_OFFERS": "Nakliyeyi sonraki yüklenicilere otomatik olarak aktarmayı etkinleştirir.",
          "ROLE_CAN_ACCESS_ARCHIVED_TRUCK_DOCUMENTS": "Nakliyat biçiminin kısımlarındaki arşiv belgelerine erişim",
          "ROLE_CAN_ACCESS_COURIERS_SECTION": "Nakliye formunda \"Kuryeler\" kısmına erişimi ayarlar",
          "ROLE_CAN_ACCESS_TASK": "Görevin görünürlüğünü etkinleştirir",
          "ROLE_CAN_ACCESS_TRANSPORT_SUPERVISOR": "Nakliye formunda \"İletişim Kurulacak Kişi\" alanının görünürlüğü",
          "ROLE_CAN_ADD_HAS_TO_WAIT_FOR_RELATION": "Nakliyeler arasında bir bağlantı ekleme seçeneğini etkinleştirir",
          "ROLE_CAN_ADD_ROUTE": "Fiyat listesine yeni bir güzergah ekleme seçeneği",
          "ROLE_CAN_ADD_TRANSPORT_COMBINING_RELATION": "Nakliyeleri birbirine bağlama seçeneğini etkinleştirir. Birleştirilen nakliyelerde ortak bildirim, dilim veya durumlar olur.",
          "ROLE_CAN_AUTOMATICALLY_CONFIRM_PREVIOUS_CHECKPOINTS": "Kullanıcı, gösterilen durumu onaylayarak önceki tüm onaylanmamış durumları da otomatik olarak onaylar",
          "ROLE_CAN_BLOCK_TASK": "Görev onayını engelleme seçeneğini etkinleştirir",
          "ROLE_CAN_CHOOSE_MORE_THAN_ONE_LOCATION": "Sipariş ayrıntılarında 'Konum' alanı için 1'den fazla değerin seçilmesini etkinleştirir. Şu izin de gerekir: 'Nakliye türüne erişim'",
          "ROLE_CAN_CONFIRM_TASK": "Görevi onaylama seçeneğini etkinleştirir",
          "ROLE_CAN_CREATE_DELIVERY": "\"Planlama\" sekmesinde teslimat oluşturma seçeneğini etkinleştirir",
          "ROLE_CAN_CREATE_TRANSPORT_FROM_SCHEDULE": "Doğrudan plandan bir nakliye oluşturma",
          "ROLE_CAN_DELETE_DELIVERY": "\"Planlama\" sekmesinde teslimat silme seçeneğini etkinleştirir",
          "ROLE_CAN_DELETE_RELATION": "Nakliyeleri birbirinden ayırma seçeneğini etkinleştirir. İzin, nakliyelerden birini ayırmanızı sağlar.",
          "ROLE_CAN_DELETE_ROUTE": "Fiyat listelerindeki güzergahları silme seçeneği",
          "ROLE_CAN_DOWNLOAD_REGISTRY_OF_PERSONS_XLS": "İzin, personel kayıtları raporunu bir Excel dosyasına aktarmanızı sağlar",
          "ROLE_CAN_EDIT_COURIERS_SECTION": "Nakliye formunda \"Kuryeler\" kısmındaki alanları düzenlemenize izin verir. \"Kuryeler kısmına erişim\" izni gerekir",
          "ROLE_CAN_EDIT_DELIVERY": "\"Planlama\" sekmesinde teslimat düzenleme seçeneğini etkinleştirir",
          "ROLE_CAN_EDIT_READY_TO_PLAN": "Nakliye formunda \"Planlamaya hazır\" işaretini düzenleme seçeneğini etkinleştirir",
          "ROLE_CAN_EDIT_ROUTE": "Fiyat listelerindeki güzergahları düzenleme seçeneği",
          "ROLE_CAN_EDIT_TRANSPORT_PALLETS_WITHOUT_CHANGING_TIME_WINDOW_SIZE": "Algoritmaya göre zaman dilimini genişletmek için yer olmamasına rağmen, nakliyedeki paket sayısını artırma seçeneği.",
          "ROLE_CAN_EDIT_TRANSPORT_SUPERVISOR": "Nakliye formunda \"İletişim Kurulacak Kişi\" alanını düzenleme seçeneği",
          "ROLE_CAN_MULTI_ARCHIVE_TRANSPORTS": "Nakliyelerin grup halinde arşivlenmesi",
          "ROLE_CAN_SCAN_BARCODES": "Nakliye aramak için barkod taraması seçeneğini etkinleştirir",
          "ROLE_CAN_SEND_EMAIL_ADVICE": "E-posta ihbarı ile ihbar verisi sağlanması için talep gönderme seçeneğini etkinleştirir",
          "ROLE_CAN_SEND_EMAIL_ADVICE_CF": "E-posta ihbarı ile sipariş verisi sağlanması için talep gönderme seçeneğini etkinleştirir",
          "ROLE_CAN_SKIP_CHECKPOINT_CONFIRMATION": "Her aşamanın zorunlu onay sırasına rağmen bir aşamanın atlanmasına izin verir",
          "ROLE_CREATE_SIMILAR": "Benzer bir sipariş (sipariş kopyası) oluşturmanıza izin verir.",
          "ROLE_CREATE_SIMILAR_MULTIPLE": "Benzerini oluştur; sipariş kopyası sayısını belirleme seçeneği",
          "ROLE_CREATE_WORKER": "\"Çalışanlar Listesi\" alt sekmesinde çalışan oluşturma seçeneği",
          "ROLE_DELETE_WORKER": "\"Çalışanlar Listesi\" alt sekmesinde çalışan silme seçeneği",
          "ROLE_EDIT_TRANSPORT_ADDITIONAL_DESCRIPTION": "\"Ek Notlar\" alanını düzenleme seçeneğini etkinleştirir",
          "ROLE_EDIT_WAREHOUSE_LOCATION": "Sipariş ayrıntılarında \"Konum\" alanını düzenleme seçeneğini etkinleştirir. Şu izin de gerekir: \"Nakliye türüne erişim\"",
          "ROLE_EDIT_WEIGHT": "\"Tartım\" kısmında ağırlığı düzenleme",
          "ROLE_EDIT_WORKER": "\"Çalışanlar Listesi\" alt sekmesinde çalışan düzenleme seçeneği",
          "ROLE_EDIT_WORKER_FIELD": "İzin, nakliye oluşturma formunda \"Çalışanlar\" alanını düzenlemenizi sağlar",
          "ROLE_HIDE_TRANSPORTS_WITHOUT_ACTIVE_WAREHOUSE_STATUSES": "The permission hides transports that do not have active statuses with the user's warehouse",
          "SWAP_ADVICE_WINDOWS": "İhbar zaman dilimlerinin yerlerinin değiştirilmesine izin verir.",
          "UPDATE_ADVICE": "Bildirim verilerini düzenlemenize izin verir.",
          "UPDATE_CARRIER_COMPANY_FIELD": "Sipariş ayrıntılarında \"Taşıyıcı\" alanını düzenleme seçeneğini etkinleştirir. Şu izin de gerekir: \"Nakliye türüne erişim\"",
          "UPDATE_CUSTOM_FIELD": "Nakliye formunda bir özel alanı düzenleme seçeneğini etkinleştirir",
          "UPDATE_MAIN_DATA_SECTION": "Nakliye düzenleme seçeneğini etkinleştirir. İhbar verileri, özel alanlar ve \"Notlar\" alanı dışında tüm alanlara uygulanır. Şu izin de gerekir: \"Nakliye türüne erişim\".",
          "UPDATE_SERVICE_COMPANY_FIELD": "Sipariş ayrıntılarında \"Hizmet\" alanını düzenleme seçeneğini etkinleştirir. Şu izin de gerekir: \"Nakliye türüne erişim\"",
          "chooseActionType": "Bir yetki seviyesi seçin"
        },
        "addPermission": "İzin ekle",
        "authorityLabels": {
          "ACCEPT_AND_REJECT_TRANSPORT": "Aktarılan nakliyeyi kabul et/reddet",
          "ACCESS_CARRIER_COMPANY_FIELD": "\"Taşıyıcı\" alanının görünürlüğü",
          "ACCESS_CUSTOM_FIELD": "Özel alanın görünürlüğü",
          "ACCESS_DESCRIPTION_FIELD": "\"Not\" alanının görünürlüğü",
          "ACCESS_INDEX": "Dizinler kısmına erişim",
          "ACCESS_PACKAGES": "Paket kısmının görünürlüğü",
          "ACCESS_SERVICE_COMPANY_FIELD": "\"Hizmet\" alanının görünürlüğü",
          "ACCESS_SUPPLIER_COMPANY_FIELD": "\"Tedarikçi/Alıcı\" alanının görünürlüğü",
          "ACCESS_TO_TRANSPORT_DETAILS": "Nakliye ayrıntılarına erişim",
          "ACCESS_TRANSPORT": "Bir nakliye türüne erişim",
          "ADD_ADVICE": "Bir ihbar verisi ekle",
          "ADD_DOCUMENTS": "Belge ekle",
          "ADD_LOAD_STEP_FROM_LOAD": "Bir yükleme aşamasından sonra bir yükleme aşaması ekleme.",
          "ADD_LOAD_STEP_FROM_UNLOAD": "Bir boşaltma aşamasından sonra bir yükleme aşaması ekleme.",
          "ADD_NOTES": "Tabloya not ekle",
          "ADD_PACKAGES": "Bir paket ekleme",
          "ADD_TIME_WINDOW": "Zaman dilimi ekle",
          "ADD_UNLOAD_STEP_FROM_LOAD": "Bir yükleme aşamasından sonra bir boşaltma aşaması ekleme.",
          "ADD_UNLOAD_STEP_FROM_UNLOAD": "Bir boşaltma aşamasından sonra bir boşaltma aşaması ekleme.",
          "BID_AUCTION": "Bir ihalede fiyat teklifi verme",
          "CAN_DELETE_APPLIES_FILES": "Taşıyıcının uygulamasından belge'sil",
          "CAN_DELETE_MOT_FILES": "Nakliye biçimindeki belgeleri sil",
          "CREATE_AUCTION": "Bir ihale oluştur",
          "CREATE_TRANSPORT": "Bir nakliye oluştur",
          "DELETE_DOCUMENTS": "Belge sil",
          "DELETE_INDEX": "Bir dizini sil",
          "DELETE_LOAD_STEPS": "Bir yükleme aşamasını sil",
          "DELETE_PACKAGES": "Paket kaldır",
          "DELETE_TRANSPORT": "Bir nakliyeyi sil",
          "DELETE_UNLOAD_STEPS": "Bir boşaltma aşamasını sil",
          "DISABLE_DATA_FIELDS": "Bir nakliye formundaki verileri gizleme",
          "EDIT_COURIER_INVOICE_SECTION": "\"Mal faturası\" kısmını düzenleme seçeneğini etkinleştirir",
          "EDIT_DESCRIPTION_FIELD": "\"Not\" alanını düzenleme seçeneğini etkinleştirir",
          "EDIT_INDEX": "Bir dizini güncelle",
          "EDIT_PACKAGES": "Paket düzenle",
          "EDIT_TRANSACTION_FREIGHT": "Yükü düzenle",
          "MOVE_ADVICE_WINDOW": "Bir ihbar zaman dilimini taşı",
          "MOVE_LOAD_STEPS": "Yükleme aşamalarını taşı",
          "MOVE_UNLOAD_STEPS": "Boşaltma aşamalarını taşı",
          "PASS_COURIER": "Nakliyeyi kuryeye aktarma",
          "PASS_TRANSPORT": "Bir nakliyeyi aktar",
          "RESIZE_ADVICE_WINDOW": "Bir ihbar zaman dilimini yeniden boyutlandır",
          "ROLE_ACCESS_ALL_DEDICATED_WINDOWS": "Herhangi bir şirketin zaman dilimlerinde bir ihbar oluşturma seçeneği",
          "ROLE_ACCESS_ALL_QUEUES": "Tüm kuyruklara erişim",
          "ROLE_ACCESS_ALL_RAMPS": "Plandaki tüm rampalara/dilimlere erişim",
          "ROLE_ACCESS_ALL_USER_GROUP_DEDICATED_WINDOWS": "Herhangi bir bölümün zaman dilimlerinde bir ihbar oluşturma seçeneği",
          "ROLE_ACCESS_API": "REST API'ye erişim",
          "ROLE_ACCESS_COURIER_INVOICE_SECTION": "\"Mal faturası\" kısmının görünürlüğünü ayarlar",
          "ROLE_ACCESS_PALLETS_SUMMARY": "Palet sınırı verilerine erişim",
          "ROLE_ACCESS_PRODUCT": "Ürün ayrıntılarına erişim",
          "ROLE_ACCESS_QUEUES_ACCESSIBILITY": "Dış sahalara ve kuyruklara erişimin görünürlüğü",
          "ROLE_ACCESS_TASK_DATA": "Görev verilerine erişim",
          "ROLE_ACCESS_TO_APPLY_HISTORY": "Taşıyıcı uygulama'geçmişine erişim",
          "ROLE_ACCESS_TO_NUMBER_OF_WINDOWS": "Plan sekmesinde zaman dilimi sayacını gösterir",
          "ROLE_ACCESS_TO_WAREHOUSE_TEMPLATES": "Plan şablonlarına erişim ",
          "ROLE_ACCESS_TRANSPORT_TYPE_DATA": "Nakliye türü verilerine erişim",
          "ROLE_ACCESS_TRUCKS": "Kamyon listesine erişim",
          "ROLE_ACCESS_USERS_ACTIVITY": "Kullanıcı etkinlik geçmişine erişim",
          "ROLE_ACCESS_WAREHOUSE_DATA": "Depo verilerine erişim",
          "ROLE_ACCESS_WEIGHT_SECTION": "Tartım kısmının görünürlüğü",
          "ROLE_ACCESS_WORKER_FIELD": "\"Çalışanlar\" alanının görünürlüğü",
          "ROLE_ACCESS_WORKFLOW_DATA": "İş akışı verilerine erişim",
          "ROLE_ADD_CONTRACTOR_GROUP": "Bir yükleniciye bir grup ekleme",
          "ROLE_ADD_NEW_ADDRESS_IN_COMPANY_DETAILS": "Şirket ayrıntıları kısmına yeni adres ekleme",
          "ROLE_ADD_WINDOW_NOTES": "Gri (engellenmiş) bir zaman dilimine not ekleme",
          "ROLE_ADVICE_EXCEL": "Siparişleri bir Excel dosyasında indir",
          "ROLE_ALLOW_CONCURRENT_SESSIONS": "Tek bir kullanıcı için eş zamanlı oturuma izin verir",
          "ROLE_ANNOUNCEMENTS_TAB": "Duyurular sekmesine erişim",
          "ROLE_API_MESSAGE_RESENDING": "API mesajını yeniden gönderme",
          "ROLE_APPROVE_MOT": "Nakliye biçiminin onaylanması",
          "ROLE_ARCHIVE_TAB": "\"Arşiv\" sekmesine erişim",
          "ROLE_AUTOMATIC_PASS_TRANSPORT_TO_ROUTE_OFFERS": "Otomatik nakliye aktarımı",
          "ROLE_BLOCK_RAMPS": "Planda bir blokaj oluşturma",
          "ROLE_CAN_ACCESS_COMPANY_TRANSPORT_LIMIT": "Şirket için nakliye sınırı alanlarının görünürlüğü",
          "ROLE_CAN_ACCESS_COURIERS_SECTION": "Kuryeler kısmına erişim",
          "ROLE_CAN_ACCESS_TASK": "Görev görünürlüğü",
          "ROLE_CAN_ACCESS_TRANSPORT_SUPERVISOR": "\"İletişim Kurulacak Kişi\" alanının görünürlüğü",
          "ROLE_CAN_ADD_CONFIRMATION_DATE_TASK": "Görev tarihi onayı",
          "ROLE_CAN_ADD_HAS_TO_WAIT_FOR_RELATION": "Nakliyeler arasında bir bağlantı ekleme",
          "ROLE_CAN_ADD_INVOICE_IN_TRANSACTION": "Fatura verisi ekle",
          "ROLE_CAN_ADD_MOT": "Bir nakliye biçimi ekle",
          "ROLE_CAN_ADD_ROUTE": "Fiyat listesine bir güzergah ekleme",
          "ROLE_CAN_ADD_TRANSPORT_COMBINING_RELATION": "Nakliyeleri birleştirmek için bir bağlantı ekleme",
          "ROLE_CAN_AUTOMATICALLY_CONFIRM_PREVIOUS_CHECKPOINTS": "Önceki tüm aşamaların otomatik onayı",
          "ROLE_CAN_BLOCK_TASK": "Görev onayı blokajı",
          "ROLE_CAN_CHOOSE_MORE_THAN_ONE_LOCATION": "'Konum' alanında 1'den fazla değer seçme",
          "ROLE_CAN_CONFIRM_ROUTE_OFFER": "Fiyat listesindeki teklifi onaylama",
          "ROLE_CAN_CONFIRM_TASK": "Görev onayı",
          "ROLE_CAN_CREATE_DELIVERY": "\"Planlama\" sekmesinde nakliyat oluşturma seçeneğini etkinleştirir",
          "ROLE_CAN_CREATE_MAX_PALLET": "Bir palet sınırı ayarlama seçeneği",
          "ROLE_CAN_CREATE_ROUTE_OFFER": "Fiyat listesine teklif ekleme",
          "ROLE_CAN_CREATE_TRANSPORT_FROM_SCHEDULE": "Doğrudan plandan bir nakliye oluşturma",
          "ROLE_CAN_DELETE_DELIVERY": "\"Planlama\" sekmesinde teslimat silme seçeneğini etkinleştirir",
          "ROLE_CAN_DELETE_RELATION": "Nakliye bağlantısını silme",
          "ROLE_CAN_DELETE_ROUTE": "Fiyat listelerinden güzergah silme",
          "ROLE_CAN_DELETE_ROUTE_OFFER": "Fiyat listesindeki bir teklifi kaldırma",
          "ROLE_CAN_DOWNLOAD_ENTRY_PASS": "Giriş kartını indirmeyi etkinleştirir",
          "ROLE_CAN_EDIT_ADDRESSES": "Adresleri düzenle",
          "ROLE_CAN_EDIT_COMPANY_TRANSPORT_LIMIT": "Şirket için nakliye sınırını içeren alanı düzenle",
          "ROLE_CAN_EDIT_COURIERS_SECTION": "Kuryeler kısmındaki alanları düzenleme",
          "ROLE_CAN_EDIT_DELIVERY": "\"Planlama\" sekmesinde nakliyat düzenleme seçeneğini etkinleştirir",
          "ROLE_CAN_EDIT_INVOICE_IN_TRANSACTION": "API fatura verisini düzenle",
          "ROLE_CAN_EDIT_READY_TO_PLAN": "Nakliye formunda \"Planlamaya hazır\" işaretini düzenleme",
          "ROLE_CAN_EDIT_ROUTE": "Fiyat listelerindeki güzergahları düzenleme",
          "ROLE_CAN_EDIT_ROUTE_OFFER": "Fiyat listesindeki bir teklifi düzenleme",
          "ROLE_CAN_EDIT_TRANSPORT_SUPERVISOR": "\"İletişim Kurulacak Kişi\" alanının düzenlenmesini etkinleştirir",
          "ROLE_CAN_EXCEED_MAX_PALLET": "Palet sınırı aşıldığında ihbar gönderme",
          "ROLE_CAN_EXCEED_MAX_PALLET_ON_DEDICATED_WINDOWS": "Özel dilimlerdeki palet sınırı hakkında ihbar",
          "ROLE_CAN_GENERATE_FULL_SETTLEMENT_REPORT": "Faturalandırma raporunu bir Excel dosyasında indir",
          "ROLE_CAN_GENERATE_LIST_OF_POINTS_REPORT": "Nokta listesini bir Excel dosyasında indirme.",
          "ROLE_CAN_GENERATE_SHORT_SETTLEMENT_REPORT": "Bir ihbar raporu oluşturma",
          "ROLE_CAN_MOVE_WINDOW_OUT_OF_ASSORTMENT_GROUP": "Bir zaman dilimini bir tasnif grubunun dışına taşımanıza izin verir",
          "ROLE_CAN_PLAN_ADVICE_IN_THE_PAST": "Geçmişte bir ihbar planlama",
          "ROLE_CAN_REJECT_TASK": "Görev reddi",
          "ROLE_CAN_REVOKE_TASK": "Görev iptali",
          "ROLE_CAN_SEE_CURRENT_PLACE_IN_AUCTION": "Devam eden bir ihalede halihazırda dolu olan yerin görünürlüğü",
          "ROLE_CAN_SEND_EMAIL_ADVICE": "E-posta ihbarı - ihbar verileri için talep gönderme",
          "ROLE_CAN_SEND_EMAIL_ADVICE_CF": "E-posta ihbarı - verilerin tamamlanması için talep gönderme",
          "ROLE_CAN_SKIP_CHECKPOINT_CONFIRMATION": "Aşama onayını atla",
          "ROLE_CAN_UPDATE_VEHICLE_MAIN_DATA_SECTION": "Nakliye aracının ana verilerini düzenle",
          "ROLE_CARRIERS_TAB": "\"Taşıyıcılar\" sekmesine erişim",
          "ROLE_CHANGE_WAREHOUSE": "Planda bir depoyu seçme",
          "ROLE_CLASSIFICATION_TAB": "\"Sınıflandırma\" sekmesine erişim",
          "ROLE_COMPANIES_TAB": "\"Şirketler\" sekmesine erişim",
          "ROLE_CREATE_ASSORTMENT_GROUP": "Bir tasnif grubu oluştur",
          "ROLE_CREATE_BLOCKED_WINDOWS": "Engellenmiş dilim oluştur",
          "ROLE_CREATE_COMPANY": "Yeni bir şirket oluştur",
          "ROLE_CREATE_CUSTOM_FIELD_DEFINITIONS": "Özel alanların ayarlarına erişim",
          "ROLE_CREATE_DISCREPANCY_PROTOCOL": "Sapma kaydı oluşturma",
          "ROLE_CREATE_DRIVER": "Yeni bir sürücü oluştur",
          "ROLE_CREATE_GUARD_ROLE_GROUP": "Bir nöbetçi rolü oluşturma",
          "ROLE_CREATE_PLACE": "Yer oluşturma",
          "ROLE_CREATE_PRODUCT": "Bir ürün oluştur",
          "ROLE_CREATE_QUEUE": "Kuyruk oluşturma",
          "ROLE_CREATE_RAMP": "Yeni bir rampa oluştur",
          "ROLE_CREATE_ROLE_GROUP": "Yeni bir rol grubu oluşturma seçeneği",
          "ROLE_CREATE_SIMILAR": "Benzer bir nakliye oluştur",
          "ROLE_CREATE_SIMILAR_MULTIPLE": "Benzerini oluştur; sipariş kopyası sayısını belirleme seçeneği",
          "ROLE_CREATE_SQUARE": "Dış saha oluşturma",
          "ROLE_CREATE_TRUCK": "Yeni bir kamyon oluştur",
          "ROLE_CREATE_USED_FOR_DELIVERIRES_ROLE_GROUP": "Bir teslimat planlama rolü oluşturma",
          "ROLE_CREATE_USER": "Yeni bir kullanıcı oluştur",
          "ROLE_CREATE_WAREHOUSE": "Yeni bir depo oluştur",
          "ROLE_CREATE_WAREHOUSE_ZONE": "Bir depo bölgesi oluştur",
          "ROLE_DELETE_COMPANY": "Bir şirketi devre dışı bırak",
          "ROLE_DELETE_DRIVER": "Bir sürücüyü sil",
          "ROLE_DELETE_INVITATION": "Etkin daveti silme",
          "ROLE_DELETE_MULTIPLE_PERIODIC_TRANSPORTS": "Birden çok düzenli ihbarı kaldırma",
          "ROLE_DELETE_PLACE": "Yeri kaldırma",
          "ROLE_DELETE_PRODUCT": "Bir ürünü sil",
          "ROLE_DELETE_QUEUE": "Kuyruğu kaldırma",
          "ROLE_DELETE_RAMP": "Bir rampayı sil",
          "ROLE_DELETE_SQUARE": "Kareyi kaldırma",
          "ROLE_DELETE_TRUCK": "Bir aracı sil",
          "ROLE_DELETE_USER": "Bir kullanıcıyı sil",
          "ROLE_DELETE_WAREHOUSE": "Bir depoyu sil",
          "ROLE_DEV_TOOLS": "Ek geliştirici araçları",
          "ROLE_DISABLE_USER": "Bir kullanıcıyı devre dışı bırak",
          "ROLE_EDIT_ALL_USERS": "Tüm kullanıcıları düzenleme",
          "ROLE_EDIT_ASSORTMENT_GROUP": "Bir tasnif grubunu düzenle",
          "ROLE_EDIT_COMPANY": "Bir şirketin seçilen alanlarını düzenle",
          "ROLE_EDIT_CONTRACTOR_GROUP": "Yüklenici Grubunu düzenle",
          "ROLE_EDIT_GUS_DATA": "Merkezi İstatistik Bürosundan indirilen verileri düzenleme",
          "ROLE_EDIT_INCOMPLETE_LOADING": "\"Tamamlanmamış yükleme\" onay kutusunun yönetimi.",
          "ROLE_EDIT_MULTIPLE_PERIODIC_TRANSPORTS": "Birden çok düzenli ihbarı düzenleme",
          "ROLE_EDIT_MY_NOTIFICATION": "Kendi bildirim ayarlarını düzenleme",
          "ROLE_EDIT_MY_QUEUES": "Kendi kuyruklarını ve karelerini düzenleme erişimi",
          "ROLE_EDIT_MY_RAMP": "Kendi rampa erişimini düzenleme",
          "ROLE_EDIT_PLACE": "Yer düzenle",
          "ROLE_EDIT_PRODUCT": "Bir ürünü güncelle",
          "ROLE_EDIT_QUEUE": "Kuyrukları düzenleme",
          "ROLE_EDIT_RAMP": "Bir rampayı düzenle",
          "ROLE_EDIT_ROLE_GROUP": "Kullanıcı rolü değişikliği",
          "ROLE_EDIT_SQUARE": "Dış sahaları düzenleme",
          "ROLE_EDIT_USERS_MAIN_DATA": "Ana kullanıcı verilerini düzenleme",
          "ROLE_EDIT_USERS_NOTIFICATION": "Bildirimleri düzenleme",
          "ROLE_EDIT_USERS_RAMP": "Rampalara kullanıcı erişimini düzenleme",
          "ROLE_EDIT_WAREHOUSE": "Bir depoyu düzenle",
          "ROLE_EDIT_WAREHOUSE_LOCATION": "Depo konumu alanını düzenle",
          "ROLE_EDIT_WAREHOUSE_ZONE": "Bir depo bölgesini düzenle",
          "ROLE_EDIT_WEIGHT": "\"Tartım\" kısmında ağırlığı düzenle",
          "ROLE_EDIT_WORKER_FIELD": "\"Çalışanlar\" alanını düzenle",
          "ROLE_EDIT_WORKFLOW": "Durum ayarlarını düzenle",
          "ROLE_FILTER_ACTIVE_TRANSPORTS_TABLE": "Etkin siparişler tablosunda sütunlara göre filtrelerin görünürlüğü",
          "ROLE_FULL_EDIT_COMPANY": "Bir şirketin tüm alanlarını düzenle",
          "ROLE_HIDE_TRANSPORTS_WITHOUT_ACTIVE_WAREHOUSE_STATUSES": "Kullanılabilecek depolarda son durum onayından sonra nakliyeleri gizleme",
          "ROLE_INVITATIONS_TAB": "\"Davetler\" sekmesine erişim",
          "ROLE_INVITE_AS_TEMPORARY_USER_WHEN_ACCOUNT_LIMIT_EXCEEDED": "Şirket sınırını aşan geçici kullanıcılar davet etme",
          "ROLE_INVOICE_COLUMN": "Fatura sütununu göster",
          "ROLE_MANAGE_ADMIN_ANNOUNCEMENTS": "İdari duyuruların yönetimi",
          "ROLE_MANAGE_ADVICE_VERIFICATION_MAIL": "Verilerin doğrulanmasını talep eden bir e-posta bildirimini yönet",
          "ROLE_MANAGE_ALLOWED_DOMAINS": "Şirket için izin verilen e-posta etki alanlarının yönetimine erişim",
          "ROLE_MANAGE_ANNOUNCEMENTS": "Duyuruları yönetme seçeneği",
          "ROLE_MANAGE_APPLIES_SETTINGS": "Uygulama ayarları yönetimine erişim",
          "ROLE_MANAGE_APPLY_SECTIONS": "Taşıyıcı uygulamalarının' yönetimi",
          "ROLE_MANAGE_ARTR_SETTINGS": "ARTR ayarlarını yönetme",
          "ROLE_MANAGE_AUCTION_SETTINGS": "İhale ayarları yönetimine erişim",
          "ROLE_MANAGE_AUTHORITIES": "Bir rolün izinlerini yönetme seçeneği",
          "ROLE_MANAGE_CMR_SETTINGS": "CMR dosya ayarlarını yönetme",
          "ROLE_MANAGE_COLUMN_VISIBILITY": "Sütun görünürlüğünü yönet",
          "ROLE_MANAGE_COMPANY_PRICING_PLAN": "Şirket için abonelik seçeneğini düzenle",
          "ROLE_MANAGE_COMPANY_RAMP": "Şirket için bir rampa değişikliğine erişim",
          "ROLE_MANAGE_DEDICATED_WINDOWS": "Özel dilimleri yönet",
          "ROLE_MANAGE_LOCAL_ADMINS": "Yüklenici şirketlerdeki yerel yöneticilerin yönetimi",
          "ROLE_MANAGE_MAX_COMPANY_ACCOUNT_NUMBER": "Şirket için maksimum hesap sayısını düzenle",
          "ROLE_MANAGE_MOT_SECTIONS": "Nakliyat biçimlerinin yönetimi",
          "ROLE_MANAGE_NOTIFICATIONS": "Bildirimleri yönet",
          "ROLE_MANAGE_PACKAGE_TYPE_TRANSPORT_TYPE": "Paket ayarlarını yönet",
          "ROLE_MANAGE_PARTNERSHIP": "Ortaklık bağlantılarını yönet",
          "ROLE_MANAGE_PRINCIPAL_DEDICATED_WINDOWS": "Müşterinin şirketine özel dilimler oluşturma",
          "ROLE_MANAGE_QUEUES_ACCESSIBILITY": "Dış sahalara ve kuyruklara erişimin yönetimi",
          "ROLE_MANAGE_SETTINGS": "Ayar yönetimi",
          "ROLE_MANAGE_TRANSPORT_TYPE": "Nakliye türü ayarlarını yönet",
          "ROLE_MANAGE_USER_GROUPS": "Yüklenicinin şirketi içindeki grupların'yönetimi",
          "ROLE_MANAGE_USER_ROLE_INVITATIONS": "Kullanıcı rolü davetlerini yönet",
          "ROLE_MODE_OF_TRANSPORTATION_TAB": "\"Nakliyat biçimi\" sekmesine erişim",
          "ROLE_MOVE_BLOCKED_WINDOWS": "Engellenmiş dilimleri taşı",
          "ROLE_MOVE_DEDICATED_WINDOWS": "Engellenmiş dilimleri taşı",
          "ROLE_MOVE_WINDOWS_BETWEEN_WAREHOUSE_ZONES": "Zaman dilimlerini depo bölgeleri arasında taşıma",
          "ROLE_OWN_COLUMNS_EXCEL": "Bir tablonun sütunlarına dayalı bir rapor oluştur",
          "ROLE_OWN_COMPANY_TAB": "Yüklenici hesabında \"Şirket\" sekmesine erişim",
          "ROLE_PERFORM_ACTION_IN_WAREHOUSE": "Bir depoda işlem yapma",
          "ROLE_PLANNING_TAB": "Planlama sekmesine erişim",
          "ROLE_PRICE_LIST_TAB": "\"Fiyat listeleri\" sekmesine erişim",
          "ROLE_PRODUCTS_TAB": "Ürün listesine erişim",
          "ROLE_QUEUES_TAB": "\"Kuyruklar\" sekmesine erişim",
          "ROLE_QUEUE_STATUS_TAB": "\"Kuyruk durumu\" sekmesine erişim",
          "ROLE_READ_WINDOW_NOTES": "Gri (engellenmiş) bir zaman dilimindeki notların görünürlüğü",
          "ROLE_REJECT_CHECKPOINT": "Kontrol noktası reddi",
          "ROLE_RESIZE_BLOCKED_WINDOWS": "Engellenmiş dilimleri yeniden boyutlandır",
          "ROLE_RESIZE_DEDICATED_WINDOWS": "Özel dilimleri yeniden boyutlandır",
          "ROLE_RESTORE_ORDER_FROM_ARCHIVE": "Siparişleri arşivden geri yükleme seçeneği",
          "ROLE_REVOKE_CHECKPOINT": "Bir kontrol noktasını iptal et",
          "ROLE_SEND_SMS_TO_ALL_DRIVERS_IN_QUEUE": "Belirli bir kuyruktaki tüm sürücülere mesaj bildirimi gönderme seçeneği",
          "ROLE_SETTINGS_TAB": "\"Ayarlar\" sekmesine erişim",
          "ROLE_SHOW_API_INTEGRATION_LOGS": "Entegrasyon kısmına erişim",
          "ROLE_SHOW_AWAITING_INVITATIONS_TABLE": "Bekleyen davetler listesini göster",
          "ROLE_SHOW_CUSTOM_FIELD_DEFINITIONS": "Özel alanların yönetimi",
          "ROLE_SHOW_PARTNERSHIP_TABLE": "Ortaklık listesini göster",
          "ROLE_SHOW_TRANSPORT_LOGS": "Sipariş geçmişine erişim",
          "ROLE_SORT_TRANSPORT_TABLE": "Nakliye tablosunu sıralama",
          "ROLE_STATISTICS_REPORT_TAB": "\"İstatistikler\" sekmesine erişim",
          "ROLE_SUPER_USER": "Özel kullanıcı izinleri",
          "ROLE_TRANSPORT_TABLE": "Nakliye tablosuna erişim",
          "ROLE_USERS_TAB": "\"Kullanıcılar\" sekmesine erişim",
          "ROLE_WAREHOUSE_TAB": "\"Plan\" sekmesine erişim",
          "SWAP_ADVICE_WINDOWS": "İhbar zaman dilimlerinin yerlerini değiştir",
          "UNLIMITED_ACCESS_TO_ARCHIVED_TRANSPORTS": "Arşivdeki siparişlere sınırsız erişim",
          "UPDATE_ADVICE": "Bir ihbarı güncelle",
          "UPDATE_CARRIER_COMPANY_FIELD": "\"Taşıyıcı\" alanını güncelle",
          "UPDATE_CUSTOM_FIELD": "Bir özel alanı güncelle",
          "UPDATE_MAIN_DATA_SECTION": "Ana nakliye alanlarını güncelle",
          "UPDATE_SERVICE_COMPANY_FIELD": "\"Hizmet\" alanını güncelle"
        },
        "authorityRangeLabel": "Yetki aralığı",
        "authorityRangeLabels": {
          "ACTIVE": "Sipariş henüz arşivlenmemişse.",
          "ACTIVE_AUCTION_BASED": "Siparişe atanmış etkin ihaleye bağlıdır.",
          "ADVICE_RESTRICTION_BASED": "Bir ihbarın belirtilen zaman aralığı içinde.",
          "AFTER_CHECKPOINT_BASED": "Kontrol noktası onaylandıktan sonra.",
          "BEFORE_CHECKPOINT_BASED": "Kontrol noktası onaylanana kadar.",
          "BLOCKED": "İşlem engellendi.",
          "NO_ROUTE_OFFER": "Nakliyenin, taşıyıcı tarafından fiyat listesindeki ücretten kabul edilmesine bağlıdır.",
          "OWN_COMPANY_BASED": "Bu siparişi oluşturan şirkete bağlıdır.",
          "TIME_BASED": "Zamana bağlı",
          "UNLIMITED": "Ek koşullar olmadan"
        },
        "authorityRangePhrases": {
          "ACTIVE": "sipariş arşivlenene kadar.",
          "ACTIVE_AUCTION_BASED": "nakliyede etkin bir ihale yoksa.",
          "ADVICE_RESTRICTION_BASED": "bir ihbarın belirtilen zaman aralığı içinde.",
          "AFTER_CHECKPOINT_BASED": "{checkpointName} kontrol noktası onaylandıktan sonra.",
          "BEFORE_CHECKPOINT_BASED": "{checkpointName} kontrol noktası onaylanana kadar.",
          "BLOCKED": "işlem engellendi.",
          "CHECKPOINT_BASED": "{checkpointName} kontrol noktasına göre.",
          "NO_ROUTE_OFFER": "tercih edilen teklif olmadığında veya fiyat listesinde geçerli teklifleri olan taşıyıcılar nakliyeyi gerçekleştirmeyi kabul etmediğinde.",
          "OWN_COMPANY_BASED": "oluşturan şirket içinde.",
          "TIME_BASED": "en geç {timeReference} tarihinden {timeInterval} {afterOrBefore}.",
          "TIME_BASED_CURRENT_DATE_AFTER": "geçerli tarihten {timeInterval} sonra.",
          "TIME_BASED_CURRENT_DATE_BEFORE": "geçerli tarihten itibaren en fazla {timeInterval}.",
          "UNLIMITED": "ek koşullar olmadan."
        },
        "checkpoint": "Durum",
        "conditions": "Koşullar",
        "create": {
          "copyFrom": "Başka bir rolden izinleri kopyala:",
          "info": "Rol kaydedildikten sonra izinler değiştirilebilir",
          "isGuard": "Nöbetçi rolü",
          "isGuardInfo": "Basitleştirilmiş kontrol noktası onayı olan rol",
          "label": "Bir rol ekle",
          "nameEn": "Ad - EN",
          "namePl": "Ad",
          "noPartnershipInfo": "Hiçbir ortaklık ana şirket rolünün oluşturulmasıyla sonuçlanmaz",
          "partnershipType": "Ortaklık türü:",
          "usedForDeliveries": "Teslimat planlama rolü",
          "usedForDeliveriesInfo": "Planlama sekmesinde teslimat planlama rolü"
        },
        "customFieldDefinition": "Özel alan",
        "dateAfterPhrase": "tarih belirttikten sonra",
        "dateBeforePhrase": "tarih belirtmeden önce",
        "deleteAllAuthoritiesTitle": "{title} için tüm izinleri kaldırma düğmesi",
        "generalAuthorityDescriptions": {
          "ACCESS_TO_TRANSPORT_DETAILS": "Sipariş ayrıntılarına erişimi etkinleştirir",
          "ADVICE_ONLY_ON_DEDICATED_WINDOWS": "Bu olanak, yalnızca dilimi ekleyen kullanıcı tarafından kullanılabilecek bir özel dilimin yerine bir zaman dilimi ekleme seçeneğini kısıtlar",
          "CAN_DELETE_APPLIES_FILES": "Taşıyıcı uygulamasına eklenmiş belgeleri silme'seçeneği.",
          "CAN_DELETE_MOT_FILES": "Bir nakliye biçimine eklenmiş belgeleri silmenize izin verir.",
          "ROLE_ACCESS_ALL_DEDICATED_WINDOWS": "Herhangi bir şirketin özel zaman dilimlerinde ihbar oluşturmanıza izin verir",
          "ROLE_ACCESS_ALL_QUEUES": "Tüm kuyruklara erişim.",
          "ROLE_ACCESS_ALL_RAMPS": "Plandaki tüm rampalara/dilimlere erişim.",
          "ROLE_ACCESS_ALL_USER_GROUP_DEDICATED_WINDOWS": "Herhangi bir bölümün özel zaman dilimlerinde ihbar oluşturmanıza izin verir",
          "ROLE_ACCESS_API": "Kullanıcı için REST API yöntemlerine erişimi etkinleştirir",
          "ROLE_ACCESS_AUCTION_DATA": "İhale verilerinin görünürlüğü",
          "ROLE_ACCESS_FREIGHT_DATA": "Taşıma ücretinin görünürlüğü",
          "ROLE_ACCESS_PALLETS_SUMMARY": "Palet sınırı verilerine erişim",
          "ROLE_ACCESS_PRODUCT": "Ürünün ayrıntılarına erişim ekler.",
          "ROLE_ACCESS_QUEUES_ACCESSIBILITY": "İzin, dış sahalara ve kuyruklara erişimin görünürlüğünün yönetilmesini sağlar. Bu izin etkinleştirildikten sonra, öge kullanıcı ayrıntılarında görünür.",
          "ROLE_ACCESS_TASK_DATA": "Görev verilerine erişim",
          "ROLE_ACCESS_TO_APPLY_HISTORY": "Taşıyıcı uygulamasının geçmişine'erişim.",
          "ROLE_ACCESS_TO_INSPECTION_BUTTON": "Nakliye tablosunda \"İnceleme\" sütununda kontrol düğmesinin görünürlüğü",
          "ROLE_ACCESS_TO_INSPECTION_PANEL": "\"İnceleme\" panelinin görünürlüğü",
          "ROLE_ACCESS_TO_LOCATION": "Visibility of the 'Konum' field.",
          "ROLE_ACCESS_TO_NUMBER_OF_WINDOWS": "Planda bir zaman dilimi sayacının gösterilmesini etkinleştirir",
          "ROLE_ACCESS_TO_WAREHOUSE_TEMPLATES": "Planda zaman dilimi şablonlarını yönetmenize izin verir.",
          "ROLE_ACCESS_TRANSPORT_ADDITIONAL_DESCRIPTION": "\"Ek Notlar\" alanının görünürlüğü",
          "ROLE_ACCESS_TRANSPORT_TYPE_DATA": "Nakliye türü verilerine erişim",
          "ROLE_ACCESS_TRUCKS": "Kamyon listesine erişim",
          "ROLE_ACCESS_USERS_ACTIVITY": "Kullanıcı etkinlik geçmişine erişimi etkinleştirir",
          "ROLE_ACCESS_WAREHOUSE_DATA": "Depo verilerine erişim",
          "ROLE_ACCESS_WORKFLOW_DATA": "İş akışı verilerine erişim",
          "ROLE_ADD_CONTRACTOR_GROUP": "Bir yüklenici için yeni bir grup ekleme seçeneği",
          "ROLE_ADD_NEW_ADDRESS_IN_COMPANY_DETAILS": "Kullanıcının şirket ayrıntıları kısmına yeni bir adres eklemesine izin verir",
          "ROLE_ADD_WINDOW_NOTES": "Gri zaman dilimlerine not ekleme seçeneğini etkinleştirir.",
          "ROLE_ADVICE_EXCEL": "Sipariş verilerini bir Excel dosyasına aktarmanıza izin verir.",
          "ROLE_ALLOW_CONCURRENT_SESSIONS": "Tek bir kullanıcı için birden çok tarayıcıdan eş zamanlı oturuma izin verir",
          "ROLE_ANNOUNCEMENTS_TAB": "Duyurular sekmesine erişimi etkinleştirir",
          "ROLE_API_MESSAGE_RESENDING": "API mesajını yeniden gönderme seçeneği",
          "ROLE_APPROVE_MOT": "Bir nakliye biçimini onaylamanıza izin verir.",
          "ROLE_ARCHIVE_TAB": "\"Arşiv\" sekmesine erişimi etkinleştirir.",
          "ROLE_BLOCK_RAMPS": "Planda bir dilimi engelleme seçeneği ve rampa başlığına tıkladıktan sonra rampanın tamamını engelleme seçeneği.",
          "ROLE_CAN_ACCESS_ARCHIVED_TRUCK_DOCUMENTS": "\"Nakliyat biçimi\" sekmesinde nakliyat biçimi ayrıntılarındaki arşiv belgelerine erişimi etkinleştirir.",
          "ROLE_CAN_ACCESS_COMPANY_TRANSPORT_LIMIT": "Şirket için nakliye sınırıyla ilgili alanların görünürlüğünü etkinleştirir",
          "ROLE_CAN_ACCESS_COURIERS_SECTION": "Nakliye formunun \"Kuryeler\" kısmına erişimi ayarlar",
          "ROLE_CAN_ADD_HAS_TO_WAIT_FOR_RELATION": "Nakliyeler arasında bir bağlantı ekleme seçeneğini etkinleştirir",
          "ROLE_CAN_ADD_INVOICE_IN_TRANSACTION": "Nakliye tablosundaki bir işleme fatura verisi eklemenize izin verir",
          "ROLE_CAN_ADD_MOT": "Bir nakliye biçimi eklemenize izin verir.",
          "ROLE_CAN_ADD_ROUTE": "Fiyat listesine yeni bir güzergah ekleme seçeneği",
          "ROLE_CAN_ADD_TRANSPORT_COMBINING_RELATION": "Nakliyeleri birbirine bağlama seçeneğini etkinleştirir. Birleştirilen nakliyelerde ortak bildirim, dilim veya durumlar olur.",
          "ROLE_CAN_AUTOMATICALLY_CONFIRM_PREVIOUS_CHECKPOINTS": "Kullanıcı, gösterilen durumu onaylayarak önceki tüm onaylanmamış durumları da otomatik olarak onaylar",
          "ROLE_CAN_CHOOSE_MORE_THAN_ONE_LOCATION": "Sipariş ayrıntılarında 'Konum' alanı için 1'den fazla değerin seçilmesini etkinleştirir. Şu izin de gerekir: 'Nakliye türüne erişim'",
          "ROLE_CAN_CREATE_DELIVERY": "\"Planlama\" sekmesinde nakliyat oluşturma seçeneğini etkinleştirir",
          "ROLE_CAN_CREATE_MAX_PALLET": "Palet sınırını ayarlama seçeneği.",
          "ROLE_CAN_CREATE_ROUTE_OFFER": "Fiyat listesine yeni bir teklif ekleme seçeneği",
          "ROLE_CAN_DELETE_DELIVERY": "\"Planlama\" sekmesinde teslimat silme seçeneğini etkinleştirir",
          "ROLE_CAN_DELETE_RELATION": "Nakliyeleri birbirinden ayırma seçeneğini etkinleştirir. İzin, nakliyelerden birini ayırmanızı sağlar.",
          "ROLE_CAN_DELETE_ROUTE": "Fiyat listelerindeki güzergahları silme seçeneği",
          "ROLE_CAN_DELETE_ROUTE_OFFER": "Fiyat listesindeki bir teklifi kaldırma seçeneği",
          "ROLE_CAN_DOWNLOAD_ENTRY_PASS": "Giriş kartını indir",
          "ROLE_CAN_DOWNLOAD_REGISTRY_OF_PERSONS_XLS": "Tesisteki kişilerin kayıtlarını indirme",
          "ROLE_CAN_EDIT_ADDRESSES": "\"Şirket\" sekmesinde adresleri düzenleme ve kaldırma seçeneği.",
          "ROLE_CAN_EDIT_COMPANY_TRANSPORT_LIMIT": "Şirket için nakliye sınırını içeren alanın düzenlenmesini etkinleştirir",
          "ROLE_CAN_EDIT_COURIERS_SECTION": "Nakliye formunun \"Kuryeler\" kısmında bulunan alanları düzenlemenize izin verir. \"Kuryeler kısmına erişim\" izni gerekir.",
          "ROLE_CAN_EDIT_DELIVERY": "\"Planlama\" sekmesinde nakliyat düzenleme seçeneğini etkinleştirir",
          "ROLE_CAN_EDIT_INVOICE_IN_TRANSACTION": "Genel API üzerinden bir nakliye işlemindeki fatura verilerini düzenlemenize izin verir",
          "ROLE_CAN_EDIT_READY_TO_PLAN": "Nakliye formunda \"Planlamaya hazır\" işaretini düzenleme seçeneğini etkinleştirir",
          "ROLE_CAN_EDIT_ROUTE": "Fiyat listelerindeki güzergahları düzenleme seçeneği",
          "ROLE_CAN_EDIT_ROUTE_OFFER": "Fiyat listesindeki mevcut teklifi düzenleme seçeneği",
          "ROLE_CAN_EDIT_TRANSPORT_PALLETS_WITHOUT_CHANGING_TIME_WINDOW_SIZE": "Algoritmaya göre zaman dilimini genişletmek için yer olmasa dahi nakliyedeki paket sayısını artırmanıza izin verir.",
          "ROLE_CAN_EXCEED_MAX_PALLET": "Palet sınırının aşıldığını ihbar etme seçeneği.",
          "ROLE_CAN_EXCEED_MAX_PALLET_ON_DEDICATED_WINDOWS": "Zaman dilimlerinin tamamen özel dilimlerde olması halinde, palet sınırı hakkında ihbar gönderme seçeneği",
          "ROLE_CAN_GENERATE_FULL_SETTLEMENT_REPORT": "Faturalandırma raporu verilerini bir Excel dosyasına aktarmanıza izin verir.",
          "ROLE_CAN_GENERATE_LIST_OF_POINTS_REPORT": "İzin, bir nokta listesini bir Excel dosyasına aktarmanızı sağlar.",
          "ROLE_CAN_GENERATE_SHORT_SETTLEMENT_REPORT": "Excel dosyası biçiminde bir ihbar raporu oluşturulmasına izin verir",
          "ROLE_CAN_MOVE_WINDOW_OUT_OF_ASSORTMENT_GROUP": "Zaman dilimini bir tasnif grubunun dışına taşımanıza izin verir",
          "ROLE_CAN_MULTI_ARCHIVE_TRANSPORTS": "Nakliyelerin grup halinde arşivlenmesi",
          "ROLE_CAN_PLAN_ADVICE_IN_THE_PAST": "Geçmişte bir ihbar planlamanıza izin verir",
          "ROLE_CAN_SCAN_BARCODES": "Barkodları tara",
          "ROLE_CAN_SEE_CURRENT_PLACE_IN_AUCTION": "Devam eden bir ihalede halihazırda dolu olan yerin görünürlüğü",
          "ROLE_CAN_SKIP_CHECKPOINT_CONFIRMATION": "Her aşamanın zorunlu onay sırasına rağmen bir aşamanın atlanmasına izin verir",
          "ROLE_CAN_UPDATE_VEHICLE_MAIN_DATA_SECTION": "Nakliye aracının ana verilerini düzenleme",
          "ROLE_CARRIERS_TAB": "\"Taşıyıcılar\" sekmesine erişimi etkinleştirir.",
          "ROLE_CHANGE_WAREHOUSE": "Planda depoyu değiştirme seçeneği.",
          "ROLE_CLASSIFICATION_TAB": "\"Sınıflandırma\" sekmesine erişimi etkinleştirir",
          "ROLE_COMPANIES_TAB": "\"Şirketler\" sekmesine erişimi etkinleştirir.",
          "ROLE_CREATE_ASSORTMENT_GROUP": "Yeni bir tasnif grubu oluşturma seçeneği.",
          "ROLE_CREATE_BLOCKED_WINDOWS": "Plan görünümünde gri zaman dilimleri oluşturma seçeneğini etkinleştirir.",
          "ROLE_CREATE_COMPANY": "Bu izin, davet göndermeden yeni bir şirket oluşturmanızı sağlar.",
          "ROLE_CREATE_CUSTOM_FIELD_DEFINITIONS": "Bu izin, özel alan ayarlarına erişmenizi sağlar.",
          "ROLE_CREATE_DISCREPANCY_PROTOCOL": "Sapma kaydı oluşturmanıza izin verir",
          "ROLE_CREATE_DRIVER": "Yeni bir sürücü ekleme seçeneği",
          "ROLE_CREATE_GUARD_ROLE_GROUP": "Bir nöbetçi rolü oluşturma seçeneği",
          "ROLE_CREATE_PLACE": "Rol, yeni bir yer oluşturmanıza izin verir",
          "ROLE_CREATE_PRODUCT": "Yeni bir ürün ekleme seçeneğini etkinleştirir. Şu izin seviyesi de gerekir: \"Ürünlere erişim\".",
          "ROLE_CREATE_QUEUE": "Rol, yeni bir kuyruk oluşturmanıza izin verir",
          "ROLE_CREATE_RAMP": "Mevcut bir depoya yeni rampa ekleme seçeneği. Gereken rol: \"Bir depoyu düzenle\".",
          "ROLE_CREATE_ROLE_GROUP": "Yeni bir rol grubu oluşturmanıza izin verir",
          "ROLE_CREATE_SIMILAR": "Benzer bir sipariş (sipariş kopyası) oluşturma seçeneği.",
          "ROLE_CREATE_SIMILAR_MULTIPLE": "Oluşturulacak sipariş sayısını belirleme seçeneğini etkinleştirir",
          "ROLE_CREATE_SQUARE": "Rol, yeni bir dış saha oluşturmanıza izin verir",
          "ROLE_CREATE_TRUCK": "Yeni bir araç ekleme seçeneği",
          "ROLE_CREATE_USED_FOR_DELIVERIRES_ROLE_GROUP": "Teslimat oluşturma rolü oluşturma seçeneği",
          "ROLE_CREATE_USER": "Bu izin, davet göndermeden yeni bir kullanıcı oluşturmanızı sağlar.",
          "ROLE_CREATE_WAREHOUSE": "Yeni bir depo ekleme seçeneği. Gereken rol: \"Bir depoyu düzenle\". ",
          "ROLE_CREATE_WAREHOUSE_ZONE": "Yeni bir depo bölgesi oluşturma seçeneği.",
          "ROLE_CREATE_WORKER": "Yeni çalışan oluştur",
          "ROLE_DELETE_COMPANY": "Bir şirketi devre dışı bırakma seçeneği.",
          "ROLE_DELETE_DRIVER": "Bir sürücüyü kaldırma seçeneği. ",
          "ROLE_DELETE_INVITATION": "Etkin bir daveti silmenize izin verir",
          "ROLE_DELETE_MULTIPLE_PERIODIC_TRANSPORTS": "Birden çok düzenli ihbarı kaldırma seçeneği",
          "ROLE_DELETE_PLACE": "Yerin kaldırılmasına izin verir",
          "ROLE_DELETE_PRODUCT": "Bu izin seviyesindeki bir kullanıcı, mevcut bir ürünü silebilir. Şu izin seviyesi de gerekir: \"Ürünlere erişim\".",
          "ROLE_DELETE_QUEUE": "Kuyruğun kaldırılmasına izin verir",
          "ROLE_DELETE_RAMP": "Bir rampayı silme seçeneği. Gereken rol: \"Bir depoyu düzenle\".",
          "ROLE_DELETE_SQUARE": "Karenin kaldırılmasına izin verir",
          "ROLE_DELETE_TRUCK": "Bir aracı silme seçeneği.",
          "ROLE_DELETE_USER": "Bir kullanıcıyı silme seçeneği. Silindikten sonra, kullanıcı artık platforma erişemez.",
          "ROLE_DELETE_WAREHOUSE": "Bir depoyu silme seçeneği. Gereken rol: \"Bir depoyu düzenle\".",
          "ROLE_DELETE_WORKER": "Çalışanı sil",
          "ROLE_DEV_TOOLS": "Ek geliştirici araçları",
          "ROLE_DISABLE_USER": "Kullanıcıyı devre dışı bırakma seçeneği. \"Ana kullanıcı verilerini düzenle\" izni gerekir. Üçüncü şahıs kullanıcılar söz konusu olduğunda \"Tüm kullanıcıları düzenle\" izni de gerekir.",
          "ROLE_EDIT_ALL_USERS": "Üçüncü şahıs kullanıcılar düzenlenebilir. \"Ana kullanıcı verilerini düzenle\", \"Rampalara kullanıcı erişimini düzenle\" ve \"Kullanıcı bildirimlerini düzenle\" izinlerinden biri gerekir.",
          "ROLE_EDIT_ASSORTMENT_GROUP": "Bir tasnif grubunu düzenleme seçeneği.",
          "ROLE_EDIT_COMPANY": "Yüklenici şirketinin kısa adını,'yüklenici numarasını ve ek ayrıntıları düzenleme seçeneği.",
          "ROLE_EDIT_CONTRACTOR_GROUP": "Mevcut yüklenici gruplarını düzenleme seçeneği",
          "ROLE_EDIT_GUS_DATA": "Merkezi İstatistik Bürosu tarafından doğrulanmış şirket verilerini düzenleme seçeneği",
          "ROLE_EDIT_INCOMPLETE_LOADING": "\"Tamamlanmamış yükleme\" onay kutusunu işaretleme/onay kutusunun işaretini kaldırma seçeneği.",
          "ROLE_EDIT_MULTIPLE_PERIODIC_TRANSPORTS": "Birden çok düzenli ihbarı düzenleme seçeneği",
          "ROLE_EDIT_MY_NOTIFICATION": "Kendi bildirim ayarlarını düzenleme",
          "ROLE_EDIT_MY_QUEUES": "Kendi kuyruklarını ve karelerini düzenleme erişimi",
          "ROLE_EDIT_MY_RAMP": "Kendi rampa erişimini düzenleme",
          "ROLE_EDIT_PLACE": "Rol, bir yeri düzenlemenize izin verir",
          "ROLE_EDIT_PRODUCT": "Mevcut bir ürünü düzenlemenize izin verir. Şu izin seviyesi de gerekir: \"Ürünlere erişim\".",
          "ROLE_EDIT_QUEUE": "Rol, bir kuyruğu düzenlemenize izin verir",
          "ROLE_EDIT_RAMP": "Rampa düzenlenebilir.",
          "ROLE_EDIT_ROLE_GROUP": "Kullanıcı ayrıntılarında rolü değiştirme seçeneği.",
          "ROLE_EDIT_SQUARE": "Rol, bir dış sahayı düzenlemenize izin verir",
          "ROLE_EDIT_TRANSPORT_ADDITIONAL_DESCRIPTION": "\"Ek Notlar\" alanını düzenleme seçeneğini etkinleştirir",
          "ROLE_EDIT_USERS_MAIN_DATA": "Adı, e-posta adresini, telefon numarasını ve parolayı düzenleme seçeneği",
          "ROLE_EDIT_USERS_NOTIFICATION": "Kullanıcının alınan bildirimleri düzenlemesine izin verir",
          "ROLE_EDIT_USERS_RAMP": "Rampalara ve depolara kullanıcı erişimini düzenleme seçeneği",
          "ROLE_EDIT_WAREHOUSE": "Depo verilerini düzenleme seçeneği",
          "ROLE_EDIT_WAREHOUSE_ZONE": "Bir depo bölgesini düzenleme seçeneği.",
          "ROLE_EDIT_WORKER": "Çalışanı düzenle",
          "ROLE_EDIT_WORKFLOW": "Her nakliye türü için ayrıntılı durum ayarlarını düzenleme seçeneği.",
          "ROLE_FILTER_ACTIVE_TRANSPORTS_TABLE": "Etkin nakliyeler tablosunda sütunların üzerinde filtreleri gösterir",
          "ROLE_FULL_EDIT_COMPANY": "Yüklenici şirketinin verilerini'düzenleme seçeneği.",
          "ROLE_INVITATIONS_TAB": "\"Davetler\" sekmesine erişimi etkinleştirir.",
          "ROLE_INVITE_AS_TEMPORARY_USER_WHEN_ACCOUNT_LIMIT_EXCEEDED": "Şirket sınırını aşan geçici kullanıcılar davet etme",
          "ROLE_INVOICE_COLUMN": "Fatura sütununu göster",
          "ROLE_MANAGE_ADMIN_ANNOUNCEMENTS": "İdari duyuruları yönetme seçeneği. Gereken izinler: \"Duyurular sekmesine erişim\" ve \"Duyuruları yönetme seçeneği\"",
          "ROLE_MANAGE_ADVICE_VERIFICATION_MAIL": "İzin, ihbar verilerini doğrulama talebi içeren e-posta bildirimini etkinleştirmenize/devre dışı bırakmanıza ve e-postayı gönderme zamanını değiştirmenize izin verir. İzin etkinleştirildikten sonra, öge yüklenici ayrıntılarında görünür.",
          "ROLE_MANAGE_ALLOWED_DOMAINS": "Yüklenici ayrıntılarında \"İzin verilen etki alanları\" kısmında izin verilen e-posta etki alanlarına erişim ve'bunların yönetimi.",
          "ROLE_MANAGE_ANNOUNCEMENTS": "Duyuruların yönetimine erişimi etkinleştirir. Gereken izin seviyesi: \"Duyurular sekmesine erişim\".",
          "ROLE_MANAGE_APPLIES_SETTINGS": "Uygulama ayarları yönetimine erişim",
          "ROLE_MANAGE_APPLY_SECTIONS": "Taşıyıcı uygulamalarının'yönetimi. Taşıyıcı uygulamasının kısımlarını tek tek kabul etme ve reddetme'seçeneği.",
          "ROLE_MANAGE_ARTR_SETTINGS": "ARTR ayarlarını yönetme",
          "ROLE_MANAGE_AUCTION_SETTINGS": "İhale ayarları yönetimine erişim",
          "ROLE_MANAGE_AUTHORITIES": "Roller için izin oluşturmanıza, düzenlemenize ve silmenize izin verir (genel izinler ve nakliye türleriyle ilgili izinler)",
          "ROLE_MANAGE_CMR_SETTINGS": "CMR dosya ayarlarını yönetme",
          "ROLE_MANAGE_COLUMN_VISIBILITY": "İzinler görünümünde nakliye türüne bağlı olarak sütunların görünürlüğünü yönetmenize izin verir",
          "ROLE_MANAGE_COMPANY_PRICING_PLAN": "İzin, şirket için abonelik seçeneğini değiştirmenizi sağlar",
          "ROLE_MANAGE_COMPANY_RAMP": "Yüklenici ayrıntılarında \"Rampa düzenleme\" kısmına erişimi etkinleştirir",
          "ROLE_MANAGE_DEDICATED_WINDOWS": "Özel zaman dilimleri oluşturma ve silme yetkisi verir.",
          "ROLE_MANAGE_LOCAL_ADMINS": "Yüklenici şirketlerde yerel yöneticiyi yönetme seçeneğini etkinleştirir",
          "ROLE_MANAGE_MAX_COMPANY_ACCOUNT_NUMBER": "İzin, şirketler için maksimum hesap sayısını değiştirmenizi sağlar",
          "ROLE_MANAGE_MOT_SECTIONS": "Nakliyat biçimlerinin yönetimi. Nakliyat biçimlerinin kısımlarını tek tek kabul etme ve reddetme seçeneği.",
          "ROLE_MANAGE_NOTIFICATIONS": "Allows to manage notifications",
          "ROLE_MANAGE_PACKAGE_TYPE_TRANSPORT_TYPE": "Paket ayarlarını yönet",
          "ROLE_MANAGE_PARTNERSHIP": "Yükleniciler arasında bağlantı eklenmesine ve silinmesine izin verir",
          "ROLE_MANAGE_PRINCIPAL_DEDICATED_WINDOWS": "Müşterinin şirketi için özel dilimler oluşturma seçeneği. Müşteri şirketinin kullanıcısı, bu tür bir özel dilime herhangi bir ihbar ekleyebilir.",
          "ROLE_MANAGE_QUEUES_ACCESSIBILITY": "İzin, dış sahaları ve kuyrukları etkinleştirmenizi/devre dışı bırakmanızı sağlar.",
          "ROLE_MANAGE_SETTINGS": "Ayar yönetimini etkinleştirir",
          "ROLE_MANAGE_TRANSPORT_TYPE": "Nakliye türü ayarlarını yönet.",
          "ROLE_MANAGE_USER_GROUPS": "Yüklenicinin şirketi içindeki grupların'yönetimi",
          "ROLE_MANAGE_USER_ROLE_INVITATIONS": "Belirli bir kullanıcı rolü için davet edilecek bir rol listesi atanmasına izin verir",
          "ROLE_MODE_OF_TRANSPORTATION_TAB": "\"Nakliyat biçimi\" sekmesine erişimi etkinleştirir",
          "ROLE_MOVE_BLOCKED_WINDOWS": "Gri zaman dilimlerini taşımanıza izin verir.",
          "ROLE_MOVE_DEDICATED_WINDOWS": "Özel zaman dilimlerini taşımanıza izin verir.",
          "ROLE_MOVE_WINDOWS_BETWEEN_WAREHOUSE_ZONES": "Zaman dilimlerini depo bölgeleri arasında taşımanıza izin verir",
          "ROLE_OWN_COLUMNS_EXCEL": "İzin, nakliye tablosundaki sütunların görünürlüğünü haritalayan bir rapor oluşturmanızı sağlar.",
          "ROLE_OWN_COMPANY_TAB": "Yüklenicinin hesabında \"Şirket\" sekmesine erişimi'etkinleştirir.",
          "ROLE_PERFORM_ACTION_IN_WAREHOUSE": "Rol, depoda işlem yapmanıza izin verir",
          "ROLE_PLANNING_TAB": "Planlama sekmesine erişimi etkinleştirir",
          "ROLE_PRICE_LIST_TAB": "Fiyat listesine eklenen bir teklifi onaylama seçeneğini etkinleştirir",
          "ROLE_PRODUCTS_TAB": "Ürünlerle ilgili sekmeye erişim ekler.",
          "ROLE_QUEUES_TAB": "\"Kuyruklar\" sekmesine erişimi etkinleştirir",
          "ROLE_QUEUE_STATUS_TAB": "\"Kuyruk durumu\" sekmesine erişimi etkinleştirir",
          "ROLE_READ_WINDOW_NOTES": "Bu izin seviyesindeki kullanıcılar için gri dilimlerdeki notların görünürlüğünü etkinleştirir.",
          "ROLE_REJECT_CHECKPOINT": "Bir kontrol noktasını reddetmenize izin verir.",
          "ROLE_RESIZE_BLOCKED_WINDOWS": "Gri zaman dilimlerinin (engellenmiş zaman dilimleri) boyutunu değiştirmenize izin verir. Şu iznin de: \"Engellenmiş dilimleri taşı\" doğru çalışması gerekir.",
          "ROLE_RESIZE_DEDICATED_WINDOWS": "Özel zaman dilimlerinin boyutunu değiştirmenize izin verir. Şu iznin de: \"Özel dilimleri taşı\" doğru çalışması gerekir.",
          "ROLE_RESTORE_ORDER_FROM_ARCHIVE": "Gecikme nedeniyle otomatik arşivlemeden sonra, siparişleri arşivden geri yükleme seçeneği",
          "ROLE_REVOKE_CHECKPOINT": "Bir kontrol noktasını iptal etmenize izin verir.",
          "ROLE_SEND_SMS_TO_ALL_DRIVERS_IN_QUEUE": "Belirli bir kuyruktaki tüm sürücülere mesaj bildirimleri gönderme seçeneğini etkinleştirir (Kuyruk Durumu sekmesi)",
          "ROLE_SETTINGS_TAB": "\"Ayarlar\" sekmesine erişimi etkinleştirir.",
          "ROLE_SHOW_API_INTEGRATION_LOGS": "Nakliye formunda \"Entegrasyon\" kısmını göster.",
          "ROLE_SHOW_ARTR_API_LOGS": "Nakliye formunda \"ARTR kayıtları\" kısmını göster.",
          "ROLE_SHOW_AWAITING_INVITATIONS_TABLE": "Bu izin, belirli bir şirketin ayrıntılarında bekleyen davetler listesini görüntülemenizi sağlar.",
          "ROLE_SHOW_CUSTOM_FIELD_DEFINITIONS": "Bu izin, özel alan eklemenizi, düzenlemenizi ve silmenizi sağlar. Ek olarak gereken izin: \"Özel alan ayarlarına erişim\".",
          "ROLE_SHOW_PARTNERSHIP_TABLE": "Bu izin, belirli bir şirketin ayrıntılarında ilgili yükleniciler listesini görüntülemenizi sağlar. Ortaklıklar davetler aracılığıyla oluşturulur.",
          "ROLE_SHOW_TRANSPORT_LOGS": "Nakliye formunda \"Geçmiş\" kısmını göster.",
          "ROLE_SORT_TRANSPORT_TABLE": "Rol, seçilen bir sütuna göre nakliye tablosunu filtrelemenize izin verir",
          "ROLE_STATISTICS_REPORT_TAB": "\"İstatistikler\" sekmesine erişim",
          "ROLE_SUPER_USER": "Özel kullanıcı ayrıcalıkları.",
          "ROLE_TRANSPORT_TABLE": "Bu roldeki bir kullanıcı nakliye tablosuna erişebilir.",
          "ROLE_USERS_TAB": "\"Kullanıcılar\" sekmesine erişimi etkinleştirir. ",
          "ROLE_WAREHOUSE_TAB": "\"Plan\" sekmesine erişimi etkinleştirir.",
          "UNLIMITED_ACCESS_TO_ARCHIVED_TRANSPORTS": "Siparişlere sınırsız erişimi ayarlar"
        },
        "generalPermissions": "Genel izinler",
        "header": "Rolün izinleri ",
        "invitationSection": "Kullanıcı rolleri davet edebilir",
        "invitationSettings": "Davet ayarları",
        "label": "Rol grubu ayarları",
        "noAuthorities": "Yetki yok.",
        "notificationSection": "Bildirim ayarları",
        "notificationSettings": "Bildirim ayarları",
        "permissions": "İzinler",
        "pricingPlans": "{pricingPlan} fiyat planı olan şirketler için",
        "successCreate": "Bir rol başarıyla oluşturuldu",
        "successDelete": "Yetki başarıyla devre dışı bırakıldı",
        "successUpdate": "Değişiklikler başarıyla kaydedildi",
        "sureToDelete": "Bu yetkiyi devre dışı bırakmak istediğinizden emin misiniz?",
        "sureToDeleteAll": "Bu gruptaki tüm yetkileri devre dışı bırakmak istediğinizden emin misiniz?",
        "timeIntervalLabel": "Aralık",
        "timeReferenceLabel": "Zaman referansı",
        "timeReferences": {
          "EARLIEST_TIME_WINDOW_CURRENT_DATE": "geçerli tarihe göre ilk zaman diliminin başlangıcı",
          "EARLIEST_TIME_WINDOW_START_AFTER_CHANGE": "ilk zaman diliminin başlangıcı (taşımadan sonra)",
          "EARLIEST_TIME_WINDOW_START_BEFORE_CHANGE": "ilk zaman diliminin başlangıcı (taşımadan önce)",
          "LATEST_TIME_WINDOW_STOP": "son zaman diliminin bitişi",
          "TRANSPORT_DATE_CREATED": "nakliyenin oluşturulduğu tarih"
        },
        "usersList": "Kullanıcı listesi"
      },
      "transportTypes": {
        "addAuctionSetting": "İhale ayarları ekle",
        "addTransactionSetting": "İşlem ayarları ekle",
        "auctionSetting": "İhale ayarları",
        "create": "Nakliye türü oluştur",
        "englishName": "İngilizce ad",
        "group": "Grup",
        "invalidCustomFieldId": "Girilen kimliğe sahip özel alan yok",
        "label": "Nakliye türü ayarları",
        "name": "Ad",
        "noAuctionSetting": "İhale ayarları henüz eklenmedi",
        "noTransactionSetting": "Bir siparişi taşıyıcıya aktarma ayarları henüz eklenmedi",
        "packageTypeSettings": "Paket türü ayarları",
        "permissions": "İzinler",
        "permissionsSaveSuccess": "İzin ayarlarını başarıyla kaydettik.",
        "selectRoleGroups": "Nakliye türü oluşturabilecek rolleri seçin",
        "settings": "Nakliye türü ayarları",
        "settingsDescription": {
          "adviceRequiresPhoneNumber": "Şu alanın: \"Cep telefonu numarası\", ihbar oluştururken zorunlu olup olmayacağını belirler",
          "adviceRestrictionInEveryStep": "Her aşamada \"İhbar zaman aralığı\" alanının görünür olup olmayacağını belirler",
          "adviceSavedWithTransport": "İhbar verileri nakliyeyle birlikte kaydedilir, ihbar verilerini girme işlemi nakliye formunda yapılır",
          "adviceSeparatedFromWindow": "Dilim eklemenin, ihbar verisi eklemekten ayrı bir aşama olup olmayacağını belirler",
          "allowLoadStepTypeChange": "Depo aşaması ve Yüklenici aşaması arasında nakliye formunda yükleme aşaması türünün değiştirilip değiştirilemeyeceğini belirler",
          "allowUnloadStepTypeChange": "Depo aşaması ve Yüklenici aşaması arasında nakliye formunda boşaltma aşaması türünün değiştirilip değiştirilemeyeceğini belirler",
          "allowsAltIdentifier": "Ek sipariş kimliğinin (altIdentifier) görünürlüğünü belirler. Bu alan, nakliye formunda ayarlarda belirtilen adla görünür: \"Sipariş numarası sütununun etiketi.\"",
          "allowsCarRegistrationNumber": "\"Araç ruhsat numarası\"nın görünürlüğünü belirler",
          "allowsCarrier": "\"Taşıyıcı\" şirketin nakliyeye katılıp katılmadığını belirler; Nakliye formunda \"Taşıyıcı\" alanının görünürlüğünü belirler",
          "allowsContractor": "\"Tedarikçi\" şirketin nakliyeye katılıp katılmadığını belirler; Sipariş formunda \"Tedarikçi\" alanının görünürlüğünü belirler",
          "allowsDriverDocumentNumber": "\"Sürücünün belge numarası\" alanının görünürlüğünü belirler",
          "allowsDriverName": "Şu alanın: \"Sürücünün'adı ve soyadı\", ihbar oluştururken görünür olup olmayacağını belirler",
          "allowsErpIdentifier": "Sipariş kimliğinin (erpIdentifier) görünürlüğünü belirler. Bu alan, nakliye formunda ayarlarda belirtilen adla görünür: \"ERP_IDENTIFIER_LABEL.\"",
          "allowsPeriodicTransport": "Düzenli ihbar seçeneğinin kullanılıp kullanılmayacağını belirler. Seçenek, yalnızca seçilen tedarikçiler/alıcılar için kullanılabilir.",
          "allowsPhoneNumber": "Şu alanın: \"Cep telefonu numarası\", ihbar oluştururken görünür olup olmayacağını belirler",
          "allowsPriceList": "Nakliye türünün \"Fiyat listesi\"ne erişip erişemeyeceğini belirler",
          "allowsRodoCheckbox": "İhbar formunda GDPR koşulunun görünür ve zorunlu olup olmayacağını belirler",
          "allowsService": "\"Hizmet\" şirketinin nakliyeye katılıp katılmadığını belirler; Nakliye formunda \"Hizmet\" alanının görünürlüğünü belirler",
          "allowsTrailer": "Şu alanın: \"Römork/Yarı Römork ruhsat numarası\", ihbar oluştururken görünür olup olmayacağını belirler",
          "allowsTransportSupervisor": "Nakliye formunda \"İletişim kurulacak kişi\" alanının görünürlüğünü belirler",
          "altIdentifierFormat": "Otomatik \"altIdentifier\" numara formatını belirler. Değişkenler: $ dizi, otomatik olarak oluşturulan dizi numarası; DD / MM / YYYY, tarih girme seçeneği. Örnek: \"IN/$sequence/MM/YY\".",
          "altIdentifierIsCreatedAutomatically": "Nakliye kimliği alanının (altIdentifier) otomatik olarak oluşturulup oluşturulmayacağını belirler. Bu seçenek etkinken nakliye formunda kimliği düzenleyemezsiniz.",
          "altIdentifierRegex": "\"altIdentifier\" alanında verilen değerin geçerli olup olmadığını kontrol etmek için kullanılacak normal ifadeyi belirtir.",
          "altIdentifierSequenceName": "Otomatik olarak oluşturulan \"altIdentifier\" alanına sıralı bir numara atayacak bir dizi belirler",
          "alternativeStepsLayout": "Her aşama farklı bir satırda olur",
          "calculatingTimeWindowSizeFromAlgorithm": "Zaman diliminin boyutunu hesaplarken depoda belirtilen algoritmanın kullanılıp kullanılmayacağını belirler",
          "carRegistrationNumberTranslationKey": "\"Araç Ruhsat Numarası\" alanı için çeviri anahtarı",
          "carrierMustBeSelectedBeforeAdvice": "Nakliyeye ihbar ekleme işlemi ancak nakliyeye taşıyıcı atandıktan sonra mümkün olur",
          "checkAmountOfCover": "İhale oluştururken veya taşıyıcıya aktarırken sigorta tutarını doğrula",
          "dateFilterProperty": "Arşivdeki siparişlerin sonrasında varsayılan olarak filtreleneceği alanı belirler.",
          "defaultAmountOfCover": "Alanın varsayılan değeri: \"Sigorta tutarı\"",
          "defaultLoadCompanyId": "Yükleme aşamasında varsayılan şirket",
          "defaultLoadStepEndHour": "Ortak içeren aşamalarda zaman aralığının varsayılan başlangıç zamanı",
          "defaultLoadStepStartHour": "Ortak içeren aşamalarda zaman aralığının varsayılan başlangıç zamanı",
          "defaultServicemanCompany": "Seçilen hizmet şirketi, yeni bir nakliye oluştururken otomatik olarak ayarlanır",
          "defaultTextForTransportAdditionalDescription": "\"Ek Notlar\" alanındaki varsayılan metin",
          "defaultUnloadCompanyId": "Boşaltma aşamasında varsayılan şirket",
          "dimensionsTranslationKey": "\"Boyutlar\" alanı için çeviri anahtarı (DIMENSIONS)",
          "dimensionsVisibleInContractorStep": "Nakliye formunda \"Satın alma emri numarası\" alanının görünürlüğünü belirler (depo bulunmayan aşama)",
          "dimensionsVisibleInWarehouseStep": "Nakliye formunda \"Satın alma emri numarası\" alanının görünürlüğünü belirler (depo bulunan aşama)",
          "documentIdentifierTranslationKey": "\"İrsaliye numarası\" alanı için çeviri anahtarı (DELIVERY_NOTE_NUMBER, CUSTOMERS_ORDER_NUMBER, ISSUE_ORDER_NUMBER, REFERENCE_NUMBER)",
          "duplicateStepCountryFieldToNewSteps": "İlk depolama dışı aşamadaki değere göre, yeni eklenen aşamadaki \"Ülke\" alanının otomatik olarak doldurulup doldurulmayacağını belirler",
          "generateTransportOrder": "Nakliye oluşturulduğunda atanan nakliye siparişinin oluşturulup oluşturulmayacağını belirler",
          "generatingWeightReceiptEnabled": "Nakliye için ağırlık makbuzu indirme seçeneğini etkinleştirir. Makbuz, nakliye formunda \"Tartım\" kısmından indirilebilir. Ayrıca GUARD rolündeki bir hesap, makbuzu doğrudan nakliye tablosundan indirebilir",
          "guardCheckpointConfirmationSingleColumnLayout": "Belirli bir aşama için, tek bir sütunda aşamaların düğmelerinin ve durum onay tarihlerinin güvenliğe gösterildiği bir görünüm oluşturup oluşturmayacağımızı belirler",
          "hasCmrDocumentEnabled": "Nakliye türünde CMR belgesi olup olmadığını belirler; bu belge, \"Belgeler\" kısmında ve nakliye tablosunun belge sütununda indirilebilir",
          "hasDiscrepancyReportEnabled": "Nakliye formunda \"Sapma kaydı\" kısmının görünürlüğünü belirler",
          "hasEntrancePassDocumentEnabled": "Nakliye türünde CMR belgesi olup olmadığını belirler; bu belge, \"Belgeler\" kısmında ve nakliye tablosunun belge sütununda indirilebilir",
          "hasProductSpecificationDocumentEnabled": "Nakliye türünde \"Ürün Teknik Özellikleri\" belgesi olup olmadığını belirler; bu belge, \"Belgeler\" kısmında ve nakliye tablosunun belge sütununda indirilebilir",
          "hasVisibleSteps": "Nakliye aşamalarıyla ilişkili alanların formda görünür olup olmayacağını belirler",
          "idx": "Nakliyelerin listede gösterildiği sırayı belirler",
          "isAmountOfCoverVisible": "Şu alanın görünürlüğü: \"Sigorta tutarı\"",
          "isInternalReleaseNumberVisibleInContractorStep": "Nakliye formunda \"İrsaliye numarası\" alanının görünürlüğünü belirler (depo bulunmayan aşama)",
          "isInternalReleaseNumberVisibleInWarehouseStep": "Nakliye oluşturma formunda \"İrsaliye numarası\" alanının görünürlüğünü belirler (depo bulunan aşama)",
          "isLoadStepInWarehouseByDefault": "Sipariş oluştururken yükleme aşamasının varsayılan olarak depoda olup olmayacağını belirler (\"allowLoadStepTypeChange == true\" olduğunda değiştirilebilir)",
          "isReadyToPlanObligatory": "Şu alanın zorunluluğu: Nakliye formundaki \"Planlamaya hazır\"",
          "isReadyToPlanVisible": "Şu alanın görünürlüğü: Nakliye formundaki \"Planlamaya hazır\"",
          "isUnloadStepInWarehouseByDefault": "Sipariş oluştururken boşaltma aşamasının varsayılan olarak depoda olup olmayacağını belirler (\"allowUnloadStepTypeChange == true\" olduğunda değiştirilebilir)",
          "isVolumeVisibleInContractorStep": "Nakliye formunda \"Hacim\" alanının görünürlüğünü belirler (depo bulunmayan aşama)",
          "isVolumeVisibleInWarehouseStep": "Nakliye formunda \"Hacim\" alanının görünürlüğünü belirler (depo bulunan aşama)",
          "isWarehouseLocationObligatory": "Şu alanın zorunluluğu: Nakliye formundaki \"Konum\"",
          "isWarehouseLocationVisible": "Şu alanın görünürlüğü: Nakliye formundaki \"Konum\"",
          "isWarehouseZoneVisible": "Nakliye formunda \"Depo bölgesi/Tasnif\" alanının görünürlüğünü belirler",
          "isWeightVisibleInContractorStep": "Nakliye formunda \"Ağırlık\" alanının görünürlüğünü belirler (depo bulunmayan aşama)",
          "isWeightVisibleInWarehouseStep": "Nakliye formunda \"Ağırlık\" alanının görünürlüğünü belirler (depo bulunan aşama)",
          "maximumPermissableVehicleWeight": "Tartım sırasında fazla ağırlık mesajının görünürlüğünü belirler",
          "newAddressAsDefaultInLoadStep": "Yükleme aşamasında şirket seçtikten sonra yeni adres ekleme görünümü gösterilir",
          "newAddressAsDefaultInUnloadStep": "Boşaltma aşamasında şirket seçtikten sonra yeni adres ekleme görünümü gösterilir",
          "newAddressCityAutocompleteEnabled": "Sipariş formunda yeni adres eklerken \"şehir\" alanını otomatik olarak doldur",
          "numberOfDaysAddedToFirstStep": "Nakliye formunda ilk aşama için planlanan tarihe veya zaman aralığına eklenecek gün sayısını belirler:",
          "numberOfDaysAddedToFirstStepEnd": "Nakliye formunda ilk aşama için planlanan tarihin bitişine veya zaman aralığına eklenecek gün sayısını belirler:",
          "numberOfDaysAddedToNextSteps": "Nakliye formunda ikinci aşama ve sonraki her bir aşama için planlanan tarihe veya zaman aralığına eklenecek gün sayısını belirler:",
          "numberOfDaysAddedToNextStepsEnd": "Nakliye formunda ikinci aşama ve sonraki her bir aşama için planlanan tarihin bitişine veya zaman aralığına eklenecek gün sayısını belirler:",
          "pastingFromExcelEnabled": "Excel'den ihbar verisi yapıştırma seçeneği",
          "purchaseOrderNumberTranslationKey": "\"Satın alma emri numarası\" alanı için çeviri anahtarı (SD_NUMBER)",
          "purchaseOrderNumberVisibleInContractorStep": "Nakliye formunda \"Satın alma emri numarası\" alanının görünürlüğünü belirler (depo bulunmayan aşama)",
          "purchaseOrderNumberVisibleInWarehouseStep": "Nakliye formunda \"Satın alma emri numarası\" alanının görünürlüğünü belirler (depo bulunan aşama)",
          "quantitySumCheckValue": "Nakliye formunda sonrasında onay mesajının gösterileceği palet sayısını belirler",
          "randomTransportInspection": "Nakliyenin rastgele incelemeye alınıp alınamayacağını belirler",
          "requiresAddress": "Depo bulunmayan nakliye aşamasına bir adres eklenmesi gerekip gerekmediğini belirler",
          "requiresAdvice": "İhbar verileri zorunlu; bu seçeneğin devre dışı bırakılması, ihbar formunun ve ihbar ayrıntılarının boş kalacağı anlamına gelir",
          "requiresAdviceDateRestrictions": "Şu alanın: \"İhbar zaman aralığı\", nakliye oluştururken görünür ve zorunlu olup olmayacağını belirler",
          "requiresAltIdentifier": "Nakliye oluştururken \"altIdentifier\" alanının zorunlu ve görünür olup olmayacağını belirler. Bu alan, nakliye formunda ayarlarda belirtilen adla görünür: \"Sipariş numarası sütununun etiketi\"",
          "requiresArrivalAndDepartureDate": "Nakliye formunda \"Sipariş edilen alım tarihi\"/\"Sipariş edilen teslimat tarihi\" alanlarının görünür olup olmayacağını belirler (yüklenici bulunan aşama)",
          "requiresArrivalAndDepartureDateInWarehouseStep": "Nakliye formunda \"Sipariş edilen alım tarihi\"/\"Sipariş edilen teslimat tarihi\" alanlarının görünür olup olmayacağını belirler (depo bulunan aşama)",
          "requiresCarRegistrationNumber": "Şu alanın: \"Araç ruhsat numarası\", ihbar oluştururken zorunlu olup olmayacağını belirler",
          "requiresCarrier": "Şu alanın: \"Taşıyıcı\", nakliye oluştururken zorunlu olup olmayacağını belirler",
          "requiresContractor": "Nakliyeyi kaydetmek için \"Tedarikçi/Alıcı\" ayrıntılarını vermeniz gerekir. Depoyla ilişkili olmayan aşamalar nakliyede görünecekse yüklenici bu kısımda seçilebilir. Aksi takdirde, yükleniciyi nakliye formunun ana kısmında seçebilirsiniz.",
          "requiresContractorStepCompanyGroup": "Şu alanın: \"Yüklenici Grubu\", nakliye oluştururken zorunlu olup olmayacağını belirler",
          "requiresDriverDocumentNumber": "Şu alanın: \"Sürücünün'belge numarası\", ihbar oluştururken zorunlu olup olmayacağını belirler",
          "requiresDriverLanguage": "\"Sürücünün'dili\" alanının görünür ve zorunlu olup olmadığını belirler",
          "requiresDriverName": "Şu alanın: \"Sürücünün'adı ve soyadı\", ihbar oluştururken zorunlu olup olmayacağını belirler",
          "requiresErpIdentifier": "Nakliye oluştururken \"erpIdentifier\" alanının görünür ve zorunlu olup olmayacağını belirler. Bu alan, nakliye formunda ayarlarda belirtilen adla görünür: \"ERP_IDENTIFIER_LABEL\"",
          "requiresModeOfTransportationVerification": "İhbar sırasında taşıyıcının tüm araçlarının mı yoksa yalnızca doğrulanan araçlarının mı sorgulanacağını belirler",
          "requiresService": "Şu alanın: \"Hizmet\", nakliye oluştururken zorunlu olup olmayacağını belirler",
          "requiresStepsWeight": "Şu alanın: \"Ağırlık\", nakliye oluştururken zorunlu olup olmayacağını belirler",
          "requiresTimeWindow": "Nakliyeyi kaydetmek için bir zaman dilimi seçmelisiniz. Yani nakliye formunu kaydettikten sonra kullanıcı, zaman dilimi için boşluk seçmek üzere doğrudan plana yönlendirilir.",
          "requiresTrailer": "Şu alanın: \"Römork/Yarı Römork ruhsat numarası\", ihbar oluştururken zorunlu olup olmayacağını belirler",
          "requiresTransportSupervisor": "\"İletişim Kurulacak Kişi\" alanının zorunluluğu",
          "requiresUniqueErpIdentifier": "\"erpIdentifier\" alanında özgün değerler olup olmayacağını belirler. Özgünlük kontrol edilirken silinen siparişler hesaba katılmaz.",
          "requiresWarehouse": "Nakliyeyi kaydetmek için bir depo seçmelisiniz. Şu seçeneği: \"Nakliye oluştururken zorunlu zaman dilimi seçimi\" işaretlemek için deponun da belirtilmesi gerekir.",
          "requiresWorkers": "\"Çalışanlar\" alanının zorunluluğu",
          "selectionOfWindowBeforeAdvice": "Dilim seçiminin ihbar verisi eklenmeden önce yapılıp yapılmayacağını belirler",
          "shouldCopyAdviceOnTransportCopy": "\"Benzerini oluştur\" seçeneğiyle ihbar verilerinin kopyalanıp kopyalanmayacağını belirler",
          "showAddressFieldsInContractorSteps": "Yüklenici aşamalarında adres alanlarının görünür olup olmayacağını belirler",
          "transportSupervisorTranslationKey": "Nakliye oluşturma formunda \"İletişim Kurulacak Kişi\" alanının gösterilen adını belirtir",
          "transportTypeName": "Nakliye türünün adını belirtir",
          "transportTypeNameEn": "Nakliye türünün İngilizce adını belirtir",
          "usedForDeliveries": "Teslimat oluşturma seçeneği",
          "windowColorDependsOnWarehouse": "Zaman dilimi renginin, yalnızca ilgili bir dilimle ilişkili durumlara bağlı olup olmadığını belirler",
          "workerFieldTranslationKey": "Nakliye oluşturma formunda \"Çalışan\" alanının gösterilen adını belirtir (WORKERS, GUESTS, SERVICEMAN)"
        },
        "settingsGroup": {
          "advice": "İhbarla ilgili ayarlar",
          "adviceForm": "İhbar formuyla ilgili ayarlar",
          "auctionMails": "E-posta bildirimleriyle ilgili ayarlar",
          "auctionOffers": "Fiyat teklifleriyle ilgili' ayarlar",
          "auctionPass": "Siparişin taşıyıcıya aktarılmasıyla ilgili ayarlar",
          "general": "Genel ayarlar",
          "identifier": "Bir nakliye kimliğiyle ilgili ayarlar",
          "index": "Dizinlerle ilgili ayarlar",
          "offerForm": "Sipariş formuyla ilgili ayarlar",
          "others": "Diğerleri",
          "step": "Nakliye aşamalarıyla ilgili ayarlar",
          "transaction": "Nakliyenin taşıyıcıya aktarılmasıyla ilgili ayarlar",
          "transport": "Nakliye ayarları",
          "transportForm": "Nakliye formuyla ilgili ayarlar",
          "transportType": "Nakliye türünün tamamıyla ilgili ayarlar"
        },
        "settingsLabel": {
          "adviceRequiresPhoneNumber": "Şu alanın zorunluluğu: Sürücünün'telefon numarası",
          "adviceRestrictionInEveryStep": "Her aşamada \"İhbar zaman aralığı\" alanının görünürlüğü",
          "adviceSavedWithTransport": "İhbar verileri nakliyeyle birlikte kaydedilir",
          "adviceSeparatedFromWindow": "Dilim eklemenin, ihbar verisi eklemekten ayrı bir aşama olup olmayacağını belirler",
          "allowLoadStepTypeChange": "Yükleme aşamasında \"Depo\" onay kutusunun görünürlüğü",
          "allowUnloadStepTypeChange": "Boşaltma aşamasında \"Depo\" onay kutusunun görünürlüğü",
          "allowsAltIdentifier": "Formda ek sipariş kimliğinin görünürlüğü - \"altIdentifier\"",
          "allowsCarRegNumberDuringTransportCreation": "Şu alanın görünürlüğü: Sipariş oluşturma alanında \"Araç ruhsat numarası\"",
          "allowsCarRegistrationNumber": "\"Araç ruhsat numarası\"nın görünürlüğünü belirler",
          "allowsCarrier": "Taşıyıcı şirketi nakliyeye ekle",
          "allowsContractor": "Tedarikçi şirketi nakliyeye ekle",
          "allowsDriverDocumentNumber": "\"Sürücünün belge numarası\" alanının görünürlüğü",
          "allowsDriverName": "Şu alanın zorunluluğu: Sürücünün'adı ve soyadı",
          "allowsErpIdentifier": "Formda sipariş kimliğinin görünürlüğü - \"erpIdentifier\"",
          "allowsPeriodicTransport": "Düzenli ihbarların eklenip eklenemeyeceğini belirler",
          "allowsPhoneNumber": "İhbar formunda \"Sürücünün'telefon numarası\"nın görünürlüğü",
          "allowsPriceList": "Nakliye türünün \"Fiyat listesi\"ne erişip erişemeyeceğini belirler",
          "allowsRodoCheckbox": "GDPR koşulunu etkinleştirme",
          "allowsService": "Hizmet şirketini nakliyeye ekle",
          "allowsTrailer": "Şu alanın görünürlüğü: \"Römork/Yarı Römork ruhsat numarası\"",
          "allowsTransportSupervisor": "Nakliye formunda \"İletişim kurulacak kişi\" alanının görünürlüğü",
          "altIdentifierFormat": "Otomatik olarak oluşturulan \"altIdentifier\" numarasının formatı",
          "altIdentifierIsCreatedAutomatically": "Ek sipariş numarasının otomatik olarak oluşturulması - \"altIdentifier\"",
          "altIdentifierRegex": "\"altIdentifier\" alanının doğrulanmasında kullanılan normal ifade",
          "altIdentifierSequenceName": "\"altIdentifier\" alanı için dizi adı",
          "alternativeStepsLayout": "Sipariş formunda alternatif aşama görünümü",
          "auctionSetting": {
            "addFileToTransportAfterOfferAcceptation": "Sipariş taşıyıcı tarafından kabul edildikten sonra siparişe otomatik olarak bir dosya ekle",
            "addFileToTransportAfterOfferAcceptationName": "Sipariş taşıyıcı tarafından kabul edildikten sonra siparişe eklenen dosyanın adı",
            "auctionEmailSubjectEn": "İhale hakkındaki e-posta bildiriminin konusu - İngilizce",
            "auctionEmailSubjectPl": "İhale hakkındaki e-posta bildiriminin konusu",
            "auctionEndedWithoutOffersEmailFileName": "Fiyat teklifi verilmemiş ihalenin bitişi hakkındaki e-posta sürümü",
            "auctionProlongationOffsetInterval": "Lider değişikliği halinde ihalenin uzatılacağı süre",
            "canAcceptWithMinPrice": "Rezerv fiyata ulaştıktan sonra ihalenin taşıyıcı tarafından kazanılması",
            "defaultAuctionFinishDelayInterval": "Varsayılan ihale süresi",
            "defaultCurrency": "İhalenin varsayılan para birimi",
            "exportAuctionInfoToExcel": "İhale verilerini siparişlerle birlikte bir Excel dosyasına aktar",
            "minOfferReductionSize": "Fiyat teklifinden düşülmesi gereken minimum değer",
            "newAuctionEmailFileName": "Yeni ihale hakkındaki e-posta sürümü",
            "reminderTimeAboutAuction": "Devam eden bir ihaleyle ilgili hatırlatma gönderme zamanı",
            "requireMaxPrice": "İhale oluştururken en yüksek fiyat teklifini zorunlu kıl",
            "requireMinOfferReductionSizeValidation": "Minimum fiyat düşüşü değerine doğrulama uygula",
            "requireMinPrice": "İhale oluştururken rezerv fiyatını zorunlu kıl",
            "requireReasonIfNotBestOfferIsChosen": "En iyi teklifin seçilmemesi halinde neden belirtilmesini zorunlu kıl",
            "selectAllCarriersByDefault": "Varsayılan olarak ihale için tüm taşıyıcıları seç",
            "shouldPassToCarrierAfterAuction": "Siparişin ihaleyi kazanana otomatik aktarımı",
            "showBlockedCompaniesSection": "İhale oluştururken engellenen şirketleri içeren kısmın görünürlüğü",
            "showCompanyGroupsSection": "İhale oluştururken taşıyıcı gruplandırmasını içeren kısmın görünürlüğü",
            "showDescriptionSection": "Teklif gönderme formunda notlar alanının görünürlüğü",
            "showFinishAuctionButton": "Devam eden bir ihale için \"İhaleyi sonlandır\" düğmesinin görünürlüğü",
            "showPassToCarrierButton": "\"Aktar\" düğmesinin görünürlüğü",
            "showPriceRangeFields": "İhale oluştururken fiyat aralığının görünürlüğü",
            "showQuantityFieldInOfferForm": "Teklif gönderme formunda palet sayısının görünürlüğü",
            "showRevokeOfferButton": "Teklifi iptal et düğmesinin görünürlüğü",
            "showStepDataInOfferForm": "Teklif gönderme formunda yükleme/boşaltma yerlerinin görünürlüğü",
            "translationKey": "Çeviri anahtarı"
          },
          "calculatingTimeWindowSizeFromAlgorithm": "Bir algoritmaya göre zaman dilimi boyutunu hesapla",
          "carRegistrationNumberTranslationKey": "\"Araç Ruhsat Numarası\" alanı için çeviri anahtarı (TRUCK, VEHICLE)",
          "carrierMustBeSelectedBeforeAdvice": "Bir Taşıyıcı seçilmeden ihbar eklenip eklenmeyeceğini belirler",
          "checkAmountOfCover": "İhale oluştururken veya taşıyıcıya aktarırken sigorta tutarını doğrula",
          "dateFilterProperty": " Arşivdeki varsayılan filtre: ",
          "defaultAmountOfCover": "Alanın varsayılan değeri: \"Sigorta tutarı\"",
          "defaultLoadCompanyId": "Yükleme aşamasında varsayılan şirket",
          "defaultLoadStepEndHour": "Ortak içeren aşamalarda zaman aralığının varsayılan başlangıç zamanı",
          "defaultLoadStepStartHour": "Ortak içeren aşamalarda zaman aralığının varsayılan başlangıç zamanı",
          "defaultServicemanCompany": "Varsayılan hizmet şirketi",
          "defaultTextForTransportAdditionalDescription": "\"Ek Notlar\" alanındaki varsayılan metin",
          "defaultUnloadCompanyId": "Boşaltma aşamasında varsayılan şirket",
          "dimensionsTranslationKey": "\"Boyutlar\" alanı için çeviri anahtarı",
          "dimensionsVisibleInContractorStep": "Sipariş formundaki \"Boyutlar\" alanının görünürlüğü (depo bulunmayan aşama)",
          "dimensionsVisibleInWarehouseStep": "Sipariş formundaki \"Boyutlar\" alanının görünürlüğü (depo bulunan aşama)",
          "documentIdentifierTranslationKey": "\"İrsaliye numarası\" alanı için çeviri anahtarı",
          "duplicateStepCountryFieldToNewSteps": "İlk depolama dışı aşamadaki değere göre, yeni eklenen aşamadaki \"Ülke\" alanının otomatik olarak doldurulup doldurulmayacağını belirler",
          "generateTransportOrder": "Nakliye oluşturulduğunda atanan nakliye siparişinin oluşturulup oluşturulmayacağını belirler",
          "generatingTransportWeightReceiptEnabled": "Türde ağırlık makbuzu oluşturulup oluşturulmayacağını belirler",
          "generatingWeightReceiptEnabled": "Ağırlık makbuzu oluşturma seçeneği",
          "guardCheckpointConfirmationSingleColumnLayout": "Belirli bir aşama için, tek bir sütunda aşamaların düğmelerinin ve durum onay tarihlerinin güvenliğe gösterildiği bir görünüm oluşturup oluşturmayacağımızı belirler",
          "hasCmrDocumentEnabled": "Nakliye türünde bir CMR belgesi olup olmadığını belirler",
          "hasDiscrepancyReportEnabled": "Şu kısımların görünürlüğü: Nakliye formundaki \"Sapma kaydı\"",
          "hasEntrancePassDocumentEnabled": "Nakliye türünde bir CMR belgesi olup olmadığını belirler",
          "hasProductSpecificationDocumentEnabled": "Nakliye türünde bir \"Ürün Teknik Özellikleri\" belgesi olup olmadığını belirler",
          "hasVisibleSteps": "Bir formda nakliye aşamaları kısmının görünürlüğü",
          "idx": "Idx",
          "isAmountOfCoverVisible": "Şu alanın görünürlüğü: \"Sigorta tutarı\"",
          "isContactDetailsFieldsRequired": "Zorunlu alanlar: iletişim kurulacak kişi, iletişim kurulacak kişinin e-postası ve telefon numarası",
          "isContactDetailsFieldsVisible": "Şu alanların görünürlüğü: sipariş formunda iletişim kurulacak kişi, iletişim kurulacak kişinin e-postası ve telefon numarası",
          "isInternalReleaseNumberVisibleInContractorStep": "Sipariş formundaki \"İrsaliye numarası\" alanının görünürlüğü (depo bulunmayan aşama)",
          "isInternalReleaseNumberVisibleInWarehouseStep": "Sipariş formundaki \"İrsaliye numarası\" alanının görünürlüğü (depo bulunan aşama)",
          "isLoadStepInWarehouseByDefault": "Yükleme aşaması varsayılan olarak depodadır",
          "isReadyToPlanObligatory": "Şu alanın zorunluluğu: Nakliye formundaki \"Planlamaya hazır\"",
          "isReadyToPlanVisible": "Şu alanın görünürlüğü: Nakliye formundaki \"Planlamaya hazır\"",
          "isUnloadStepInWarehouseByDefault": "Boşaltma aşaması varsayılan olarak depodadır",
          "isVolumeVisibleInContractorStep": "Sipariş formundaki \"Hacim\" alanının görünürlüğü (depo bulunmayan aşama)",
          "isVolumeVisibleInWarehouseStep": "Sipariş formundaki \"Hacim\" alanının görünürlüğü (depo bulunan aşama)",
          "isWarehouseLocationObligatory": "Şu alanın zorunluluğu: Nakliye formundaki \"Konum\"",
          "isWarehouseLocationVisible": "Şu alanın görünürlüğü: Nakliye formundaki \"Konum\"",
          "isWarehouseZoneVisible": "Nakliye formunda \"Depo bölgesi/Tasnif\" alanının görünürlüğü",
          "isWeightVisibleInContractorStep": "Sipariş formundaki \"Ağırlık\" alanının görünürlüğü (depo bulunmayan aşama)",
          "isWeightVisibleInWarehouseStep": "Sipariş formundaki \"Ağırlık\" alanının görünürlüğü (depo bulunan aşama)",
          "maximumPermissableVehicleWeight": "Araç için izin verilen ağırlığın aşıldığına dair bir mesaj göster",
          "newAddressAsDefaultInLoadStep": "Yükleme aşamasında şirket seçtikten sonra yeni adres ekleme görünümü gösterilir",
          "newAddressAsDefaultInUnloadStep": "Boşaltma aşamasında şirket seçtikten sonra yeni adres ekleme görünümü gösterilir",
          "newAddressCityAutocompleteEnabled": "Sipariş formunda yeni adres eklerken \"şehir\" alanını otomatik olarak doldur",
          "numberOfDaysAddedToFirstStep": "Nakliye formunda ilk aşama için planlanan tarihe veya zaman aralığına eklenecek gün sayısı:",
          "numberOfDaysAddedToFirstStepEnd": "Nakliye formunda ilk aşama için planlanan tarihin bitişine veya zaman aralığına eklenecek gün sayısı:",
          "numberOfDaysAddedToNextSteps": "Nakliye formunda ikinci aşama ve sonraki her bir aşama için planlanan tarihe veya zaman aralığına eklenecek gün sayısı:",
          "numberOfDaysAddedToNextStepsEnd": "Nakliye formunda ikinci aşama ve sonraki her bir aşama için planlanan tarihin bitişine veya zaman aralığına eklenecek gün sayısı:",
          "pastingFromExcelEnabled": "Excel'den ihbar verisi yapıştırma seçeneği",
          "purchaseOrderNumberTranslationKey": "\"Satın alma emri numarası\" alanı için çeviri anahtarı",
          "purchaseOrderNumberVisibleInContractorStep": "Sipariş formundaki \"Satın alma emri numarası\" alanının görünürlüğü (depo bulunmayan aşama)",
          "purchaseOrderNumberVisibleInWarehouseStep": "Sipariş formundaki \"Satın alma emri numarası\" alanının görünürlüğü (depo bulunan aşama)",
          "quantitySumCheckValue": "Hangi adet sayısından sonra bir uyarı gösterileceği",
          "randomTransportInspection": "Rastgele kontrollerin etkinleştirilmesi",
          "requiresAddress": "Depo içermeyen bir aşamada adres verileri zorunludur",
          "requiresAdvice": "İhbar verisi zorunluluğu",
          "requiresAdviceDateRestrictions": "\"İhbar zaman aralığı\" alanının görünürlüğü ve zorunluluğu",
          "requiresAltIdentifier": "Şu alanın zorunluluğu: \"altIdentifier\"",
          "requiresArrivalAndDepartureDate": "Görünürlük ve zorunlu alan: \"Sipariş edilen alış tarihi\"/\"Sipariş edilen teslimat tarihi\" (yüklenici bulunan aşama)",
          "requiresArrivalAndDepartureDateInWarehouseStep": "Görünürlük ve zorunlu alan: \"Sipariş edilen alış tarihi\"/\"Sipariş edilen teslimat tarihi\" (depo bulunan aşama)",
          "requiresCarRegistrationNumber": "Şu alanın zorunluluğu: \"Araç ruhsat numarası\"",
          "requiresCarrier": "Şu alanın zorunluluğu: \"Taşıyıcı\"",
          "requiresContractor": "Veri zorunluluğu: Tedarikçi/Alıcı",
          "requiresContractorStepCompanyGroup": "Şu alanın zorunluluğu: \"Yüklenici grubu\"",
          "requiresDriverDocumentNumber": "Şu alanın zorunluluğu: \"Sürücünün'belge numarası\"",
          "requiresDriverLanguage": "\"Sürücünün dili\" alanının görünürlüğü ve'zorunluluğu",
          "requiresDriverName": "Şu alanın zorunluluğu: \"Sürücünün adı ve soyadı\"",
          "requiresErpIdentifier": "Şu alanın zorunluluğu: \"erpIdentifier\"",
          "requiresModeOfTransportationVerification": "Nakliyat biçimi doğrulaması (işlev, yalnızca \"taşıyıcıya aktarma\" işlevi etkinken çalışır)",
          "requiresService": "Şu alanın zorunluluğu: \"Hizmet\"",
          "requiresStepsWeight": "Şu alanın zorunluluğu: \"Ağırlık\"",
          "requiresTimeWindow": "Nakliye oluştururken zaman dilimi seçimi zorunluluğu",
          "requiresTrailer": "Şu alanın zorunluluğu: \"Römork/Yarı Römork ruhsat numarası\"",
          "requiresTransportSupervisor": "\"İletişim Kurulacak Kişi\" alanının zorunluluğu",
          "requiresUniqueErpIdentifier": "\"erpIdentifier\" alanının özgünlüğü",
          "requiresWarehouse": "Nakliye oluştururken depo seçimi zorunluluğu",
          "requiresWorkers": "\"Çalışanlar\" alanının zorunluluğu",
          "selectionOfWindowBeforeAdvice": "Dilim seçiminin ihbar verisi eklenmeden önce yapılıp yapılmayacağını belirler",
          "shouldCopyAdviceOnTransportCopy": "\"Benzerini oluştur\" seçeneğiyle ihbar verilerinin kopyalanıp kopyalanmayacağını belirler",
          "showAddressFieldsInContractorSteps": "Yüklenici bulunan aşamalardaki adres alanlarının görünürlüğü",
          "supplierFieldTranslationKey": "\"Tedarikçi/Alıcı\" alanı için çeviri anahtarı (CLIENT, COMPANY, COURIER)",
          "transportSupervisorTranslationKey": "\"İletişim Kurulacak Kişi\" alanı için çeviri anahtarı (CONTACT_PERSON, INVITEE, ORDERER, PERSON_TO_NOTIFY, HOST_PERSON)",
          "transportTypeName": "Nakliye türünün adı",
          "transportTypeNameEn": "Nakliye türünün İngilizce adı",
          "usedForDeliveries": "Teslimat oluşturma seçeneği",
          "windowColorDependsOnWarehouse": "Zaman dilimi renginin, yalnızca ilgili dilimle ilişkili durumlara bağlı olup olmadığını belirler",
          "workerFieldTranslationKey": "\"Çalışanlar\" alanı için çeviri anahtarı (WORKERS, GUESTS, SERVICEMEN)"
        },
        "settingsSaveSuccess": "Nakliye türü ayarlarını başarıyla kaydettik",
        "transactionSetting": "İşlem ayarları",
        "workflow": "Aşamalar ve durumlar (İş akışı)"
      },
      "updateSuccessMessage": "Ayarlar güncellendi.",
      "value": "Değer"
    },
    "square": {
      "SQUARE": {
        "addQueue": "Kuyruk ekle",
        "buffer": "Tampon",
        "carsInFrontOfSmelter": "Kalite kontrolü öncesi araçlar",
        "classificationTab": "Sınıflandırma",
        "create": "Ham madde dış sahası oluştur",
        "createPlace": "Yer ekle",
        "label": "Ham madde dış sahaları",
        "placeLabel": "Yer",
        "queueLabel": "Kuyruk",
        "queueStatusTab": "Kuyruk durumu",
        "queuesStatus": "Kuyruk durumu (kalite kontrolü sonrası)",
        "queuesStatusTableHeader": "Güncel kuyruk durumunun özeti",
        "queuesTab": "Kuyruklar",
        "square": "Dış daha",
        "unload": "Boşaltma"
      },
      "createQueueAfterSave": "Dış saha kaydedildikten sonra kuyruklar yapılandırılabilecek.",
      "disableConfirm": "Bu dış sahayı devre dışı bırakmak istediğinizden emin misiniz?",
      "updateSuccessMessage": "{squareName} dış sahasının ayarları başarıyla güncellendi."
    },
    "squareSetting": {
      "bufferSecondTableColumnVisibility": "\"Tampon\" sütununun görünürlüğü (2 tablo)",
      "carRegistrationFirstTableColumnVisibility": "\"Araç ruhsat numarası\" sütununun görünürlüğü (1 tablo)",
      "hdsFirstTableColumnVisibility": "\"HDS\" sütununun görünürlüğü (1 tablo)",
      "label": "Dış saha ve kuyruk ayarları",
      "orderNumberFirstColumnVisibility": "\"Sipariş numarası\" sütununun görünürlüğü (1 tablo)",
      "queueFirstTableColumnVisibility": "\"Kuyruk\" sütununun görünürlüğü (1 tablo)",
      "queueSecondTableColumnVisibility": "\"Kuyruk\" sütununun görünürlüğü (2 tablo)",
      "squareFirstTableColumnVisibility": "\"Dış saha\" sütununun görünürlüğü (1 tablo)",
      "squareSecondTableColumnVisibility": "\"Dış saha\" sütununun görünürlüğü (2 tablo)",
      "translationKey": "Çeviri anahtarı",
      "unloadSecondTableColumnVisibility": "\"Boşaltma\" sütununun görünürlüğü (2 tablo)"
    },
    "statuses": {
      "deleted": "Silindi",
      "finished": "Tamamlandı",
      "initial": "İhbar edildi",
      "movedToArchiveDueDelay": "Gecikti",
      "waitingForConfirmation": "Şunun onayı bekleniyor:"
    },
    "statute": {
      "accept": {
        "disabled.title": "Lütfen Hizmet Şartları kabul beyanını kontrol edin.",
        "header": "Sayın Kullanıcımız",
        "inTouch": "Anlıyorum, irtibatta olacağız.",
        "informed": "Anlıyorum, bilgilendirildiğime inanıyorum.",
        "label": "Yukarıdakileri anlıyor ve kabul ediyorum",
        "manual": "Güvenlik Kılavuzu'nu okudum, anladım ve kabul ediyorum.",
        "paragraph_1": "1. Gdańsk, Lęborska 3b, KRS 0000426238, NIP 584-272-80-64, REGON 221707056 adresinde bulunan LOGINTEGRA Sp. z o. o., GDPR'de belirtilen Sistem'e kayıtlı kişisel verilerinizin denetçisidir.  Denetçi İletişim Bilgileri: telefon: +48 58 535 78 06, e-posta: info@logintegra.com. Verileriniz, diğer sistem kullanıcıları ve aşağıda açıklanan başka tüzel kişilerle paylaşılabilir.",
        "paragraph_10": "10. Verileriniz hakkında sorularınız, şüpheleriniz veya talepleriniz varsa bize ulaşmanın en kolay yolu şu e-posta adresidir: info@logintegra.com",
        "paragraph_2": "2. Kullanıcı'nın kişisel verileri, yalnızca Denetçi ile diğer Kullanıcılar arasında varılan anlaşmaların uygulanması, yapılan anlaşmalarla ilgili yükümlülüklerin ve hakların icra edilmesi ve kanun hükümlerinden kaynaklananların yanı sıra Kullanıcı'nın verilerin işlenmesini kabul ettiği kapsam dahilinde yükümlülüklerin yerine getirilmesi amacıyla işlenir.",
        "paragraph_3": "3. Bu Sistem, şirketler arasında iş birliği ve Kullanıcıların şirketlerinin lojistik süreçlerinin yürütülmesi için kullanılır. Kullanıcının kişisel verilerinin, Sistemin diğer Kullanıcılarına görünür olabileceği ve bu kullanıcılara gönderilebileceği veya başka bir şekilde aktarılabileceği esasıyla çalışır. Kullanıcının kişisel verileri, kanun hükümlerinin şartlarına uygun olarak, başta şunlar olmak üzere, başka durumlarda da aktarılabilir: ",
        "paragraph_3_1": "vergi yetkililerine, vergi hükümlerince kişisel veri aktarımının zorunlu kılındığı ölçüde;",
        "paragraph_3_2": "devlet yetkililerine, diğerlerinin yanı sıra kolluk kuvvetlerine, mahkemelere, savcılara, sosyal güvenlik yetkililerine; kanun hükümlerince kişisel veri aktarımının zorunlu kılındığı ölçüde;",
        "paragraph_3_3": "adımıza teknik destek veya barındırma hizmetleri sağlayan, güvenilir alt yüklenicilere;",
        "paragraph_3_4": "muhasebe, vergi ve hukuk alanında hizmet sağlayan taraflara.",
        "paragraph_4": "4. Kullanıcı'nın kişisel verileri, yalnızca 2. paragrafta belirtilen amaçların yerine getirilmesi için gereken süre boyunca veya rıza geri çekilene kadar işlenir. Bazı veri kategorileri, kanun hükümleri tarafından böyle bir yükümlülük zorunlu kılındığı takdirde daha uzun süre saklanabilir; ör. düzenlenmiş faturalarda sağlanan veriler, denetçi tarafından, vergiyle ilgili amaçlar doğrultusunda faturaların saklaması için belirlenen kanuni süre dolana kadar saklanır.",
        "paragraph_5": "5. Kullanıcı, kişisel verileri kendi isteğiyle sağlar ancak Sistem'in yapısı gereği, Sistem'i kullanabilmek için belirli veri kategorilerinin sağlanması gerekir. Kullanıcı, kayıt formunda gerekli tüm kişisel verileri sağlamazsa veya işlenmesine dair rızasını geri çekerse Sistem'i kullanmak mümkün olmaz.",
        "paragraph_6": "6. Gerçek kişi olan her Kullanıcı şu haklara sahiptir:",
        "paragraph_6_1": "kendi verilerine erişme;",
        "paragraph_6_2": "verilerini düzeltme;",
        "paragraph_6_3": "verilerini silme veya işlenmesini kısıtlama;",
        "paragraph_6_4": "verilerinin işlenmesine ve",
        "paragraph_6_5": "verilerinin aktarılmasına itiraz etme;",
        "paragraph_6_6": "herhangi bir zamanda verilerin işlenmesine dair rızasını geri çekme; bu durum, geri çekilmesinden önce verilen rızaya uygun olarak yapılan işleme hakkına uyumluluğu etkilemez.",
        "paragraph_6_bottom": "Ayrıca Kullanıcı, kişisel verilerinin işlenmesinin kişisel veri koruma düzenlemelerini ihlal ettiğine karar verirse denetleyici bir kuruma şikayette bulunma hakkına da sahiptir.",
        "paragraph_7": "7. Veri korumayla ilgili ayrıntılı konular, belgelerimizde belirtilmiştir:",
        "paragraph_7_1": "Veri İşleme Politikası;",
        "paragraph_7_2": "Güvenlik Kılavuzu;",
        "paragraph_7_3": "Sistem Hizmet Şartları.",
        "paragraph_7_bottom": "Belgelere erişmek için adlarına tıklayın.",
        "paragraph_8": "8. Sistemimiz çerez kullanır.",
        "paragraph_9": "9. Sistem'de kişisel verilerin işlendiğini unutmayın. Örneğin sürücüler gibi üçüncü şahısların kişisel bilgilerini Sistem'e girdikten sonra, GDPR'de belirtildiği gibi, kişisel veri Denetçisi'nin taşıdığı yükümlülüklerin yerine getirildiğinden emin olmalısınız. Bu yükümlülükler arasında, bunlarla sınırlı olmamak üzere, veri sahibini şu konularda bilgilendirmek de vardır:",
        "paragraph_9_1": "Denetçi'nin kimliği ve iletişim bilgileri;",
        "paragraph_9_2": "kişisel verilerin işlenmesini gerektiren amaçların yanı sıra bu tür işlemenin hukuki temeli;",
        "paragraph_9_3": "varsa kişisel verilerin alıcıları veya alıcı kategorileri;",
        "paragraph_9_4": "kişisel verilerin saklanacağı süre;",
        "paragraph_9_5": "Denetçi'den veri sahibini ilgilendiren kişisel verilere erişim, bunları düzeltme veya silme talebinde bulunma veya veri sahibini ilgilendiren işlemenin kısıtlanmasını talep etme ya da bu tür işlemeye itiraz etmenin yanı sıra veri taşınabilirliği haklarının varlığı;",
        "paragraph_9_6": "ilgili kişinin rızasının ardından işlemenin yapılacağı yer; rıza geri çekilmeden önce rızaya dayanarak yapılan işlemenin kanuna uygunluğunu etkilemeden, herhangi bir zamanda rızayı geri çekme hakkının varlığı;",
        "paragraph_9_7": "denetleyici bir kuruma şikayette bulunma hakkı;",
        "paragraph_9_8": "kişisel verilerin sağlanmasının kanuna veya sözleşmeye dayalı bir şart mı olduğu yoksa bir sözleşme yapmak için gerekli bir şart mı olduğu; ayrıca veri sahibinin kişisel veri sağlama zorunluluğu olup olmadığı ve bu tür verilerin sağlanmamasının olası sonuçları.",
        "paragraph_9_bottom": "Denetçi'nin yükümlülüklerinin yerine getirildiğinden emin olmayı unutmayın. Kişisel veri koruma kurallarına yönelik olası bir ihlalden haberdar olursanız bizi derhal bilgilendirin.",
        "policy": "Veri İşleme Politikası'nı okudum, anladım ve kabul ediyorum.",
        "statute": "Sistem Hizmet Şartları'nı okudum, anladım ve kabul ediyorum.",
        "subHeader": "25 Mayıs 2018'de, kişisel verilerin işlenmesiyle ilgili olarak gerçek kişilerin korunması ve bu verilerin serbestçe taşınması hakkındaki AVRUPA PARLAMENTOSU VE KONSEYİNİN 2016/679 NUMARALI DÜZENLEMESİ'NİN (AB) hükümleri ve 2016'da Avrupa Birliği Resmi Gazetesi'nde L 119 s. 1'de yayınlanan ilga edici 95/46/EC Direktifi (Genel Veri Koruma Düzenlemesi) yürürlüğe girdi. İçeriği netleştirmek amacıyla, yukarıdaki direktif bundan sonra GDPR olarak anılacaktır. Kişisel veri denetçilerine ve işleyicilerine GDPR uyarınca getirilen yükümlülüğe uygun şekilde, burada sizi bununla ilgili birkaç önemli konu hakkında bilgilendiriyoruz. Aşağıda bulunan içerikleri dikkatli bir şekilde öğrenmenizi rica ediyoruz:"
      },
      "appliesManualLabel": "Uygulama yönetimi kılavuzunu aç",
      "label": "Hizmet Şartları",
      "label.ablative": "Hizmet Şartları",
      "links": {
        "manual": "https://pl.logintegra.com/static/Manual.pdf",
        "policy": "https://pl.logintegra.com/static/Policy.pdf",
        "statute": "https://pl.logintegra.com/static/Statute-2018-05-24.pdf"
      },
      "openKnowledgeBase": "Bilgi tabanını aç",
      "policyLabel": "Veri işleme politikası",
      "reject": {
        "button": "İptal (bu hesap engellenecek)",
        "buttonForInvitations": "Çekilme",
        "confirmation": "Emin misiniz? Hesabınız engellenecek."
      },
      "safetyManualLabel": "Güvenlik kılavuzu"
    },
    "step": {
      "CUSTOMERS_ORDER_NUMBER.label": "Müşteri sipariş numarası",
      "add": {
        "title": "Başka bir aşama ekle"
      },
      "address": "Adres",
      "canMoveLoadStep": "Yetki seviyenizde yalnızca yükleme aşamaları taşınabilir",
      "canMoveUnloadStep": "Yetki seviyenizde yalnızca boşaltma aşamaları taşınabilir",
      "companyGroup": "Yüklenici grubu",
      "contactPerson": {
        "email": "E-posta adresi",
        "label": "İletişim kurulacak kişi",
        "phoneNumber": "Telefon numarası"
      },
      "delete": {
        "confirmMessage": "Bu nakliyeden bu aşamayı silmek istediğinizden emin misiniz?",
        "confirmMessageWithIndexes": "Bu nakliyeden bu aşamayı silmek istediğinizden emin misiniz? İlişkili tüm dizinler, bu aşamayla birlikte silinir.",
        "title": "Bu nakliyeden aşamayı kaldır"
      },
      "dimensions": {
        "DEFAULT.label": "Boyutlar",
        "DIMENSIONS.label": "Boyutlar"
      },
      "documentIdentifier": {
        "CUSTOMERS_ORDER_NUMBER.label": "Müşterinin sipariş numarası",
        "DEFAULT.label": "İrsaliye numarası",
        "DELIVERY_NOTE_NUMBER.label": "İrsaliye numarası",
        "ISSUE_ORDER_NUMBER.label": "Sipariş emri numarası",
        "REFERENCE_NUMBER.label": "Referans numarası"
      },
      "errors": {
        "arrivalDate": {
          "isInPast": "Teslimat tarihi geçmişte olamaz."
        },
        "company": {
          "isRequired": "Bu nakliye türü için yüklenici eklenmesi gerekiyor. Herhangi bir şirket göremiyorsanız bir ortaklık başlatın."
        },
        "companyGroup": {
          "blank": "En az bir yüklenici grubu seçmeniz gerekiyor.",
          "wrongValue": "Seçilen yüklenici grubu, seçilen tedarikçi şirketin gruplarından biri değil."
        },
        "contactPersonEmail": {
          "invalidEmailAddress": "Lütfen geçerli bir e-posta adresi girin"
        },
        "dimensions": {
          "length": "Girilen metin çok uzun. Maksimum uzunluk 255 karakterdir."
        },
        "maxAdviceDate": {
          "beforeWindowStop": "Girilen aralık ayarlanamıyor çünkü bu durumda daha önce eklenen bir ihbar zaman dilimi bunun dışında kalır. Lütfen önce zaman dilimini taşıyın.",
          "earlierThanMinAdviceDate": "Mümkün olan en geç ihbar zamanı, mümkün olan en erken ihbar zamanından önce gelecek şekilde ayarlanamaz.",
          "inThePast": "İhbarın gönderilebilmesi için ihbarın zaman aralığının bitişi, ileride bir zaman olmalıdır.",
          "timeHasPassed": "Ne yazık ki ihbar için izin verilen süre doldu. Lütfen yöneticiye ulaşın."
        },
        "minAdviceDate": {
          "afterWindowStart": "Girilen aralık ayarlanamıyor çünkü bu durumda daha önce eklenen bir ihbar zaman dilimi bunun dışında kalır. Lütfen önce zaman dilimini taşıyın."
        },
        "minQuantityValue": "İzin verilen minimum palet sayısı {minQuantityValue}",
        "outsideOfInterval": "İzin verilen aralık {windowInterval} dakika çünkü seçilen depoda zaman dilimlerinin taşınması için ayarlanan zaman aralığı bu.",
        "outsideWarehouseWorkHours": "Seçilen tarih, deponun çalışma saatleri olan şu aralığın dışında: {workStart}-{workEnd}.",
        "purchaseOrderNumber": {
          "length": "Girilen metin çok uzun. Maksimum uzunluk 255 karakterdir."
        },
        "warehouse": {
          "sameWarehousesInTransfer": "Tek bir depoda bir aktarım oluşturamazsınız"
        }
      },
      "label": "Aşama",
      "load": {
        "place": "Yükleme yeri",
        "term": "Alım tarihi"
      },
      "maxAdviceDate": "Mümkün olan en geç ihbar zamanı",
      "minAdviceDate": "Mümkün olan en erken ihbar zamanı",
      "orderInTransport": "Bir siparişteki dizi",
      "purchaseOrderNumber": {
        "DEFAULT.label": "SD Numarası",
        "SD_NUMBER.label": "SD Numarası"
      },
      "quantityLoadAndUnloadSumCheckMessage": "Yükleme/boşaltmada birden fazla mal römorku için bir ihbar göndermek istediğinizden emin misiniz?",
      "quantityLoadSum": "Yüklemede palet sayısı",
      "quantityLoadSumCheckMessage": "Yüklemede birden fazla mal römorku için bir ihbar göndermek istediğinizden emin misiniz?",
      "quantityUnloadSum": "Boşaltmada palet sayısı",
      "quantityUnloadSumCheckMessage": "Boşaltmada birden fazla mal römorku için bir ihbar göndermek istediğinizden emin misiniz?",
      "timeRangeForAdvice": "Bir ihbarın zaman aralığı",
      "timeRangeForAdviceDoesNotApply": "Yalnızca bilgi amaçlı; sizin için geçerli değil",
      "timeRangeForLoad": "Yükleme için zaman aralığı",
      "timeRangeForUnload": "Boşaltma için zaman aralığı",
      "timeWindow": "Zaman dilimi",
      "unload": {
        "place": "Boşaltma yeri",
        "term": "Teslimat tarihi"
      },
      "weightLoadSum": "Yükleme aşamalarının toplam ağırlığı",
      "weightUnloadSum": "Boşaltma aşamalarının toplam ağırlığı"
    },
    "table": {
      "loadMore": "Daha fazla veri yükle",
      "loading": "Nakliyeler yükleniyor",
      "numberOfTransports": "{n} nakliye",
      "scanBarcode": "Barkod Tarama",
      "scanBarcodeError": "Barkod taranırken bir hata oluştu. Tarama cihazını kontrol edin ve tekrar deneyin veya yöneticinize ulaşın.",
      "transports": {
        "scrollToType": "Bu türün nakliye kısmına gidin.",
        "selectType": "Türü seçin",
        "showAll": "Tüm nakliyeleri göster",
        "showAllActive": "Tüm etkin siparişleri göster",
        "showLimited": "Sınırlı siparişleri göster",
        "showMine": "Yalnızca benim nakliyelerimi göster",
        "showWithinGroup": "Benim grubumdaki nakliyeleri göster"
      }
    },
    "tabs": {
      "announcements": "Duyurular",
      "archive": "Arşiv",
      "carriers": "Taşıyıcılar",
      "companies": "Şirketler",
      "invitations": "Davetler",
      "modeOfTransportation": "Nakliyat biçimi",
      "planning": "Planlama",
      "priceLists": "Fiyat listeleri",
      "report": "İstatistikler",
      "schedule": "Plan",
      "settings": "Ayarlar",
      "superAdmin": "YÖNETİCİ",
      "transports": "Nakliyeler",
      "users": "Kullanıcılar"
    },
    "task": {
      "alias": "Aşama rumuzu",
      "altCheckpointColor": "Alternatif durum rengi",
      "altCheckpointName": "Alternatif aşama adı TR",
      "altCheckpointNameEn": "Alternatif aşama adı EN",
      "associations": {
        "CONTRACTOR": "Yüklenici",
        "WAREHOUSE": "Depo",
        "WAREHOUSE_AND_CONTRACTOR": "Depo ve Yüklenici",
        "label": "Şununla ilişkili görev:"
      },
      "beforeConfirmation": {
        "willChangeColorTo": "ve tablodaki satırı ve zaman dilimi değişir",
        "willChangeStateTo": "Bu aşama onaylanana kadar nakliyenin durumu şu olur: "
      },
      "blockade": {
        "customFieldsRestriction": "Durum ancak nakliye formundaki alanlar doldurulup kaydedildikten sonra onaylanabilir:",
        "documentRestriction": "Durum ancak nakliye belgeleri eklendikten sonra onaylanabilir"
      },
      "canBeBlocked": "Durum engellenebilir (engel kaldırılmadan durumun kilidini açmak mümkün olmaz) ve ROLE_CAN_BLOCK_TASK yetkisi de gerekir",
      "canBeConfirmAfterAddingDocumentsForTransport": "Nakliye belgeleri eklendikten sonra onaylanabilir",
      "canBeConfirmedByGuard": "Nöbetçi tarafından onaylanabilir",
      "canBeConfirmedWithSms": "Aşama SMS ile onaylanabilir",
      "canBeRejected": "Durum reddedilebilir",
      "canBeRevoked": "Durum iptal edilebilir",
      "canConfirmPreviousConfirm": "Bu siparişin çıkışını onaylamak istediğinizden emin misiniz? Boşaltma durumları onaylanmadı.",
      "checkpointName": "Aşama adı TR",
      "checkpointNameEn": "Aşama adı EN",
      "checkpointSortId": "Aşamanın sıralamadaki yeri",
      "checkpointSortIdSettingLabel": "Nakliye tablosunda durumlar sıralanırken aşama sırası",
      "color": "DURUM rengi",
      "confirmCheckpointAfterCarrierSelect": "Taşıyıcı atandıktan sonra otomatik onay",
      "confirmCheckpointAfterCourierDelivered": "Kurye teslim edildi durumunu onayladıktan sonra otomatik onay",
      "confirmCheckpointAfterCourierPickup": "Kurye alındı durumunu onayladıktan sonra otomatik onay",
      "customFieldDefinition": "Özel alanla ilişkili görev",
      "customFieldOption": "İlgili özel alan seçeneği",
      "customModalComponentName": "Görevde openCustomModal ayarı etkinleştirilmişse açılan iletişim kutusu olarak kullanılan bileşen adı (yukarıya bakın)",
      "defaultStagePrefix": "Onaylandı",
      "disableConfirm": "Bu aşamayı devre dışı bırakmak istediğinizden emin misiniz?",
      "editLabel": "Aşamayı düzenle: {id} - {transportType}",
      "errors": {},
      "expectedDateMode": {
        "reference": "en geç tarih"
      },
      "expectedDateReference": "Referans",
      "group": {
        "0": "Hazırlık",
        "1": "Yüklemeler ve boşaltmalar",
        "2": "Bitiş:"
      },
      "isDriverCheckInTask": "Infokiosk'ta nakliye aramasını destekleyen bir işaret. Aradığımız nakliyede bu durum onaylanmış olamaz.",
      "label": "Aşama",
      "markIfWasDelayed": {
        "disabled": {
          "label": "Bir gecikmeyi işaretle",
          "title": "Bu aşama geç onaylanmışsa zaman diliminin etrafında kalıcı bir kırmızı çerçeve gösterilmesini etkinleştirmek için simgeye tıklayın."
        },
        "enabled": {
          "label": "Bir gecikmeyi işaretle",
          "title": "Bir aşama çok geç onaylanırsa zaman dilimi kırmızı bir çerçeveyle işaretlenir. Ayarları değiştirmek için simgeye tıklayın."
        }
      },
      "moveTransportToArchiveIfDelayed": "Gecikme halinde arşive otomatik sipariş atama",
      "newSubTasksCheckpoints": "Yeni kontrol noktaları oluşturulacak",
      "openCustomModal": "Bu görevi onaylamak için, açılan iletişim kutusunu onaylamanız gerekir.",
      "recreateMasterTaskSubTasks": "Onaydan sonra bu görevin ana görevine ait tüm alt görevleri yeniden oluştur (ana görev: {n}).",
      "reference": {
        "after": {
          "earliestStepStart": "ilk yükleme/boşaltmanın başlangıcı",
          "latestStepStop": "ilk yükleme/boşaltmanın bitişi",
          "minAdviceDate": "ihbar gönderme tarih aralığının \"başlangıç\" tarihi",
          "stepStart": "şunun başlangıcı:",
          "stepStop": "şunun bitişi:",
          "transportCreated": "nakliye oluşturma"
        },
        "before": {
          "earliestStepStart": "ilk yükleme/boşaltmanın başlangıcı",
          "latestStepStop": "ilk yükleme/boşaltmanın bitişi",
          "minAdviceDate": "ihbar gönderme tarih aralığının \"başlangıç\" tarihi",
          "stepStart": "şunun başlangıcı:",
          "stepStop": "şunun bitişi:",
          "transportCreated": "nakliye oluşturma"
        }
      },
      "repeatSendApiMessage": "Başarısız olması halinde mesajı yeniden gönder",
      "requiresWarehouseLocation": "Bir depo konumunun durumu onaylaması gerekir",
      "saveSubTasks": "Tüm alt görevleri kaydet",
      "sendApiMessage": "API mesajı gönderme",
      "sendDocumentDelayedEmail": "Belge ekleme gecikmesi hakkında e-posta bildirimi gönderme",
      "settingsLabel": "Aşama ayarları",
      "shouldBeApprovedWithAdvice": "İhbar eklendikten sonra otomatik durum onayı",
      "shouldBeApprovedWithCFTypeEmailAdvice": "CF_DATA türü e-posta ihbarından alınan nakliye verileri eklendikten sonra otomatik durum onayı.",
      "shouldBeApprovedWithTimeWindow": "Zaman dilimi eklendikten sonra otomatik durum onayı",
      "shouldBeConfirmedBy": "şuradan onay almalı:",
      "shouldBeConfirmedByRelatedCheckpoint": "[DELIVERY] \"shouldConfirmRelatedCheckpoint\" işaretli ilgili kontrol noktası onaylandığında onaylanacak kontrol noktasını belirtir",
      "shouldBeSavedAsApproved": "Bir sipariş oluşturulurken otomatik durum onayı",
      "shouldConfirmRelatedCheckpoint": "[DELIVERY] Onaylandığında, \"shouldBeConfirmedByRelatedCheckpoint\" işaretli ilgili kontrol noktasının da onaylanacağı bir kontrol noktası belirtir",
      "shouldFindFreeSpotAfterDelay": "Gecikme halinde zaman diliminin aktarımı",
      "shouldGenerateDocument": "Otomatik irsaliye oluşturma",
      "shouldSendLpToSapMessages": "[DELIVERY] Sonrasında MSP mikro hizmetine mesaj göndereceğimiz kontrol noktasını tanımlar",
      "showPunctualityAlertAfterConfirmation": "Durum onayının dakikliğiyle ilgili bilgi göster",
      "sign": "Önce/Sonra",
      "stageName": "TR aşaması onaylanmadan önceki durum adı",
      "stageNameEn": "EN aşaması onaylanmadan önceki durum adı",
      "stayAtTheFacility": "Tesiste kalmayla ilişkili aşama",
      "stepType": {
        "firstLoad": "ilk yükleme",
        "firstUnload": "ilk boşaltma",
        "lastLoad": "son yükleme",
        "lastUnload": "son boşaltma",
        "load": "yükleme",
        "placeholder": "İşlem türü",
        "unload": "boşaltma"
      },
      "taskIsNotSavedHint": "Bu görev henüz kaydedilmedi, eklenen görevi düzenleyebilmek için Kaydet'e tıklayın",
      "timeWindowCssClassName": "Zaman dilimi için ek css sınıfı",
      "tracked": {
        "disabled": {
          "label": "",
          "title": "Aşama onayı için beklenen son tarih yalnızca emsal olarak gösterilir; bir aşama zamanında onaylanmazsa satırın veya dilimin rengi değiştirilmez ve gecikmeyle ilgili bildirim gönderilmez. Ayarları değiştirmek için simgeye tıklayın."
        },
        "enabled": {
          "label": "Dakikliği izleme",
          "title": "bir aşama zamanında onaylanmazsa nakliyenin durumu \"geç kaldı\" olarak değişir, tablodaki satırın rengi ve zaman dilimi kırmızı olur ve gecikmeyle ilgili e-postalar gönderilir. Ayarları değiştirmek için simgeye tıklayın."
        }
      },
      "unit": "Birim",
      "userHasToAddCheckpointConfirmationDate": "Durum onay tarihi gerekir",
      "visibleInDeliveryDocument": "İrsaliyede görünür",
      "weighingIncluded": "Tartım aşaması"
    },
    "tasks": {
      "empty": "Burada görev yok."
    },
    "temporaryAccountAlert": {
      "daysLeft": "Blokaja kalan gün: {days}.",
      "limitReached": "Şirketiniz zaten kendi fiyatlandırma planındaki kullanıcı sınırına ulaştı, dolayısıyla bu hesap geçicidir.",
      "pricingPlans": "bulabilirsiniz.",
      "title": "Kullanıcı sınırına ulaşıldı",
      "upgrade": "Kullanıcı sınırını artırmak istiyorsanız farklı bir fiyatlandırma planı satın almalısınız. Ayrıntıları fiyat listemizde"
    },
    "timeWindow": {
      "cancelCreation": "İptal (bir zaman diliminin konumunu ayarlayın)",
      "errors": {
        "start": {
          "notConsistentWithWarehouseOffset": "Zaman diliminin başlangıcı, depo ayarlarıyla uyumlu değil. Seçilen saat için zaman diliminin olası başlangıçları: {possibleWindowStarts}",
          "outsideWarehouseWorkHours": "Seçilen tarih, deponun çalışma saatleri olan şu aralığın dışında: {workStart}-{workEnd}.",
          "startsEarlierThenPreviousWindowStops": "Zaman diliminin başlangıcı, nakliyenin önceki zaman diliminin bitişinden önce olamaz."
        },
        "stop": {
          "notConsistentWithWarehouseOffset": "Zaman diliminin bitişi, depo ayarlarıyla uyumlu değil. Seçilen saat için zaman diliminin olası bitişi: {possibleWindowStops}",
          "outsideWarehouseWorkHours": "Seçilen tarih, deponun çalışma saatleri olan şu aralığın dışında: {workStart}-{workEnd}."
        },
        "time": {
          "outsideOfAdviceRange": "Bu zaman dilimini buraya ekleyemezsiniz çünkü bu durumda ihbar zaman aralığının dışında kalır: {minAdviceDate} - {maxAdviceDate}."
        },
        "warehouseHasNoSpaceThisDay": "<date> tarihinde kullanılabilecek zaman dilimi yok. Lütfen farklı bir tarih seçin.",
        "wrongWarehouse": "Bir nakliyeye atanmamış bir depoya zaman dilimi yerleştiremezsiniz"
      },
      "ramp": "Rampa",
      "start": "Bir zaman diliminin başlangıcı",
      "stop": "Bir zaman diliminin bitişi"
    },
    "transaction": {
      "accept": "Kabul et",
      "autoPass": "Oto. aktar",
      "autoPassConfirmation": "Bu nakliye için sonraki taşıyıcılara otomatik aktarmayı etkinleştirmek istediğinizden emin misiniz?",
      "carrier": "Taşıyıcı",
      "carrierMustAcceptTransport": "Aktarım için taşıyıcının kabul'etmesi gerekir",
      "carrierMustAcceptTransportHint": {
        "checked": "Sipariş aktarıldıktan sonra, seçilen taşıyıcı siparişi kabul edebilir veya reddedebilir. Taşıyıcı, kendisine atanması için siparişi onaylamalıdır.",
        "unchecked": "Seçilen taşıyıcı şirket hemen siparişe atanır."
      },
      "chooseCarrier": "Bir taşıyıcı seçin",
      "chooseCourier": "Bir kurye seçin",
      "courier": "Kurye",
      "currency": "Para birimi",
      "dateCreated": "Oluşturma:",
      "errors": {
        "expectedConfirmationDate": {
          "isInPast": "Girilen tarih geçmişte olamaz"
        },
        "invoicePrice": {
          "wrongNumberFormat": "Fiyat bir sayı olmalıdır"
        }
      },
      "expectedConfirmationDate": "Onay için son tarih",
      "invoiceCurrency": "Fatura para birimi",
      "invoiceNumber": "Fatura numarası",
      "invoicePrice": "Fatura ücreti",
      "isRejected": "Reddedildi",
      "label": "İşlem",
      "notYetImplemented": "Bu işlev henüz uygulamaya konmadı.",
      "offer": "Teklif",
      "pass": "Aktar",
      "price": "Fiyat",
      "rate": "Ücret",
      "reject": "Reddet",
      "transport": "Nakliye",
      "updateDisabledInfo": {
        "route": "Ücret, {routeId} güzergahından olduğu için'düzenleyemezsiniz."
      },
      "waitingForAcceptanceInfo": "Kabul bekleniyor",
      "withInvoice": "faturalı",
      "withoutInvoice": "faturasız"
    },
    "transactionSetting": {
      "description": {
        "canChooseCarrierFromTransportTable": "Nakliye tablosundaki nakliye satırında \"Aktar\" düğmesinin kullanılıp kullanılamayacağını belirler (AuthorityRestriction seçenekleri uygun şekilde ayarlandığında).",
        "defaultCurrency": "Nakliye formunda seçilen varsayılan para birimini belirtir.",
        "defaultInvoiceCurrency": "Fatura formunda seçilen varsayılan para birimini belirtir.",
        "priceLabel": "Varsayılan fiyat etiketini belirtir.",
        "requireCarrierConfirmation": "Sipariş taşıyıcıya aktarıldığında onay gerekip gerekmediğini belirler. \"Aktarım için taşıyıcının kabul etmesi gerekir\" işareti'TRUE ise \"Aktarım için taşıyıcının kabul etmesi gerekir\" alanının varsayılan değerini'belirtir.",
        "requiresReasonField": "\"Neden\" alanı gereklidir.",
        "showAndRequireReasonFieldIfPriceIsZero": "\"Yük\" alanına 0 değeri girildiğinde \"Neden\" alanının görünürlüğü ve zorunluluğu",
        "showCarrierConfirmationCheckbox": "Aktarım formunda şu onay kutusunun görünüp görünmeyeceğini belirler: \"Aktarım için taşıyıcının kabul etmesi gerekir\"'alanı",
        "showExpectedConfirmationDate": "Aktarım formunda şu onay kutusunun görünüp görünmeyeceğini belirler: \"Onay için son tarih\"",
        "showPriceField": "Aktarım formunda şu onay kutusunun görünüp görünmeyeceğini belirler: \"Fiyat\" alanı",
        "showPriceFieldForDeliveries": "Her teslimatta \"Yük\" alanının görünürlüğü",
        "showReasonField": "\"Neden\" alanının görünürlüğü."
      },
      "label": {
        "canChooseCarrierFromTransportTable": "Nakliye tablosunda \"Aktar\" düğmesini göster",
        "defaultCurrency": "Varsayılan işlem para birimi",
        "defaultInvoiceCurrency": "Varsayılan fatura para birimi",
        "priceLabel": "Varsayılan fiyat etiketi",
        "requireCarrierConfirmation": "Taşıyıcıdan işlem onayı gerekir",
        "requiresReasonField": "\"Neden\" alanı gereklidir.",
        "showAndRequireReasonFieldIfPriceIsZero": "\"Yük\" alanına 0 değeri girildiğinde \"Neden\" alanının görünürlüğü ve zorunluluğu",
        "showCarrierConfirmationCheckbox": "\"Aktarım için taşıyıcının kabul etmesi gerekir\" alanının'görünürlüğü",
        "showExpectedConfirmationDate": "\"Onay için son tarih\" alanının görünürlüğü",
        "showPriceField": "\"Fiyat\" alanının görünürlüğü",
        "showPriceFieldForDeliveries": "Her teslimatta \"Yük\" alanının görünürlüğü",
        "showReasonField": "\"Neden\" alanının görünürlüğü."
      },
      "priceLabel": {
        "FREIGHT": "Yük",
        "FREIGHT_FOR_CARRIER": "Taşıyıcı için yük",
        "PRICE": "Fiyat",
        "RATE": "Ücret"
      },
      "saveSuccess": "İşlem ayarlarını doğru şekilde kaydettik"
    },
    "transport": {
      "ARTR": "ARTR iletişim durumu",
      "CARTONS": "Koliler",
      "CHEP_PALLETS": "CHEP paletler",
      "DISPOSABLE_PALLETS": "Kullan-at paletler",
      "Delivery": "Teslimat",
      "EURO_PALLETS": "EURO paletler",
      "LOADING_PLACES": "Yükleme yerleri",
      "PALLETS": "Paletler",
      "PALLET_PLACES": "Palet yerleri",
      "THE_NUMBER_OF_ITEMS": "Öge sayısı",
      "accountExpired": "Etkinlik görülmemesi nedeniyle askıya alınan hesap",
      "actionType": "İşlem türü",
      "addNewCompany": {
        "assignmentCompany": "Bu şirketi nakliye yüklenicisi olarak atamak istiyor musunuz?",
        "cooperatingCompanies": "Benzer bir ada sahip bir şirketle zaten iş birliği yapıyorsunuz",
        "emailInfo": "Verilen e-posta adresine bir iş birliği daveti gönderilecek.",
        "emailRequired": "İş birliği daveti göndermek istiyorsanız bir e-posta adresi girmelisiniz",
        "existingCompany": "Seçilen Vergi Kimlik Numarasına (NIP) sahip bir şirket veri tabanımızda zaten var",
        "existingCompanyAndPartnership": "{taxIdNumber} Vergi Kimlik Numarasına (NIP) sahip şirket sistemde zaten kayıtlı ve iş arkadaşlarınıza ait",
        "label": "Henüz sisteme girilmemiş yeni bir şirket ekleyin.",
        "sendInvitation": "İş birliği daveti gönder",
        "wrongEmail": "Girilen e-posta adresi doğru değil"
      },
      "additionalDescription": "Ek notlar",
      "advice": "İhbar",
      "altIdentifier": "Alternatif sipariş numarası",
      "amountOfCover": "Sigorta tutarı",
      "archived": "Arşivlendi",
      "arrivalDate": "Alım tarihi",
      "assetId": "Varlık kimliği",
      "authorityRange": "Yetki aralığı",
      "canNotEditAfterProcessStarts": "Yalnızca kendi oluşturduğunuz bir ihbarın verilerini, bir sürücü gelmeden önce düzenleyebilirsiniz.",
      "canNotEditBecauseOfOtherContractors": "Birden çok yükleniciden gelen bir ihbarın verilerini düzenleyemezsiniz.",
      "carrier": {
        "label": "Taşıyıcı"
      },
      "carrierCompany": "Taşıyıcı şirket",
      "carrierCompanyChooser": "Taşıyıcıyı seçen kişi",
      "carrierCompanySelectedBy": "Taşıyıcı {companyName} tarafından seçildi",
      "combinedWeight": "Toplam nakliye ağırlığı: ",
      "company": "Tedarikçi",
      "company.autoCompletePlaceholder": "Şirket aramak için yazmaya başlayın",
      "companyNotQualified": "Şirketiniz şunun için koşullara uymuyor:",
      "completed": "Tamamlandı",
      "courier": {
        "dateInPast": "Girilen alım tarihi geçmişte. Nakliyatı kuryeye yönlendirmek için lütfen sipariş ayrıntılarındaki alım tarihini güncelleyin.",
        "dhlMissingDocument": "Seçilen ülke(ler) AB'nin dışında ve bunlar için PLT gerekiyor. Nakliyeyi DHL Express'e aktarmak için lütfen nakliyenin Mal faturası kısmında bir fatura tarihi ile fatura numarası ayarlayın ve faturayı pdf formatında ekleyin.",
        "dhlMissingExportDeclaration": "Seçilen ülke(ler) AB'nin dışında. Nakliyeyi DHL Express'e aktarmak için lütfen nakliyenin Mal faturası kısmında bir fatura tarihi ve fatura numarası ayarlayın.",
        "label": "Kurye",
        "loadCity.label": "Yükleme yeri:",
        "missingContactDetails": "Nakliye aşamalarının herhangi birinde iletişim kurulacak kişinin adı, telefon numarası veya e-posta adresi yoksa nakliye kuryeye aktarılamaz.",
        "pickUpDate.label": "Alım Tarihi",
        "pickUpHour.label": "Alım Saati",
        "service.label": "Hizmet",
        "unloadCity.label": "Boşaltma yeri:"
      },
      "courierInvoiceSection": {
        "button": "Fatura ekle",
        "date": "Fatura tarihi",
        "file": "Eklenmiş fatura",
        "label": "Mal faturası",
        "number": "Fatura numarası"
      },
      "courierSection": {
        "deliveryDate": "Teslimat tarihi",
        "goodsValue": "Mal değeri",
        "goodsValueCurrency": "Para birimi",
        "incoterms": "Incoterms",
        "label": "Kuryeler",
        "orderBy": "Sipariş veren:",
        "serviceType": "Hizmet türü",
        "totalPrice": "Toplam fiyat"
      },
      "createSimilarMultiple": "Benzer nakliyeler oluştur",
      "createSimilarMultipleInProgress": "Nakliye kopyalama devam ediyor, tüm nakliyeler oluşturulunca pencere kapatılacak",
      "createSimilarMultiplePlaceholder": "Kopya sayısı",
      "createSimilarMultipleValue": "Oluşturulacak nakliye sayısı",
      "createTimeWindow": "Zaman dilimi oluştur",
      "createTitle": "Teslimat planla",
      "createdAsCopy": "sipariş kopyası olarak",
      "createdBy": "Oluşturan:",
      "createdMessage": "{date} tarihinde {person} ({company}) tarafından oluşturuldu.",
      "creator": "Oluşturan",
      "dateCreated": "Oluşturulma tarihi",
      "dateOfAdvice": "İhbar tarihi",
      "dateOfArchiving": "Arşivlenme tarihi",
      "dateOfDeletion": "Silinme tarihi",
      "delete": "Nakliyeyi sil",
      "deleteTransport": "Bu nakliyeyi silmek istediğinizden emin misiniz?",
      "departureDate": "İstenen teslimat tarihi",
      "description": "Notlar",
      "details": "Teslimatın ayrıntıları",
      "documentType": "Belge türü",
      "documents": {
        "dragAndDrop": "Belgeleri sürükleyip bırakın veya tıklayın",
        "empty": "Bu teslimata henüz belge eklenmedi.",
        "header": "Teslimat belgeleri {identifier}",
        "label": "Belgeler"
      },
      "earliestTimeWindowStart": "İlk zaman diliminin başlangıcı",
      "edit": "Nakliyeyi düzenle",
      "email": "E-posta adresi",
      "erpIdentifier": "Sipariş numarası",
      "erpIdentifier.placeholder": "ör. 110011",
      "errors": {
        "altIdentifier": {
          "regex": "Verilen değer Ayarlardaki normal ifadeyle eşleşmiyor"
        },
        "carrierCompany": {
          "info": "Bu nakliye türü için taşıyıcı şirketin girilmesi gerekiyor. Şirket'görmüyorsanız bir ortaklık başlatın."
        },
        "contractors": {
          "notUnique": "Lütfen özgün bir yüklenici listesi girin"
        },
        "dateOfAdvice": {
          "isInPast": "Geçmişte teslimat planlayamazsınız"
        },
        "erpIdentifier": {
          "minLength": "ERP kimliği en az 3 karakter uzunluğunda olmalıdır"
        },
        "firstContractor": {
          "info": "Bu nakliye türü için yüklenici şirketin girilmesi gerekiyor. Şirket'görmüyorsanız bir ortaklık başlatın."
        },
        "requiresWorker": {
          "info": "Lütfen çalışanları seçin."
        }
      },
      "forAcceptOrReject": " sipariş edilen nakliyatları kabul etme/reddetme",
      "forAuction": " ihalelerde fiyat teklifi verme sürecine katılma.",
      "forProcess": " bu sürece bir ihbar ekleme.",
      "generateLOPReport": "Nokta listesini içeren raporu indir",
      "getCourierQuotes": "Kurye fiyat teklifi al",
      "goodsValue": "Mal değeri",
      "goodsValueCurrency": "Para birimi",
      "group": "Teslimat grubu",
      "history": "Sipariş geçmişi",
      "incompleteLoading": "Tamamlanmamış yükleme",
      "incoterms": "Incoterms",
      "indexes": {
        "overwritePreviousIndexes": "Önceki dizinlerin üzerine yaz",
        "pasteFromExcel": "Excel'den yapıştır"
      },
      "indicesTransportNumbers": {
        "label": "Sipariş türü"
      },
      "isCombinedTransport": "Ortak nakliye: ",
      "isRequiredFor": "Şunun için gerekiyor: ",
      "isWaitingFor": "Şu bekleniyor: ",
      "label": "Nakliye",
      "lastLoginDate": "Sisteme giriş yap",
      "latestTimeWindowStop": "Son zaman diliminin bitişi",
      "load": "Yükle",
      "logs": {
        "apiIntegration": "Entegrasyon",
        "artr": "ARTR kayıtları",
        "carrierCompany": "Taşıyıcı şirket",
        "dateOfArchiving": "Nakliye arşivlendi",
        "dateOfDeletion": "Nakliye kaldırıldı",
        "initialStartInfo": "Zaman dilimi {initialDate} saatinden {startDate} saatine yaslandı",
        "label": "Değişiklik geçmişi",
        "name": "Dosya",
        "ramp": "Rampa",
        "start": "Zaman diliminin başlangıcı",
        "stop": "Zaman diliminin bitişi"
      },
      "mobilePhoneNumber": "Telefon numarası",
      "mobilePhonePrefix": "Telefon numarası - alan kodu",
      "multiArchive": {
        "alert": "Şu numaralara sahip nakliyeleri arşivleme izniniz yok: ",
        "confirm": "Şu nakliyeleri arşivlemek istediğinizden emin misiniz? ",
        "error": "Nakliyeleri toplu olarak arşivlemeye çalışırken beklenmedik bir hata oluştu. ",
        "label": "Grup arşivleme"
      },
      "noFilesForOldBrowsers": "Bu tarayıcı görünümünde dosya ekleyemezsiniz. Lütfen tablo seviyesinden bir dosya ekleyin.",
      "option": "İşaretlenen seçenek",
      "otherRequirements": "Diğer şartlar",
      "packs": {
        "boxes": "Kutular",
        "label": "Paket",
        "pallets": "Paletler",
        "pallets.abbr": "p."
      },
      "periodicTransport": "Düzenli ihbar",
      "periodicTransportInternalIndex": "Düzenli ihbar sayısı",
      "periodicTransportMessage": "Sipariş düzenli ihbar numarasına {periodicTransportInternalIndex} bağlandı.",
      "print": "Görünümü yazdır",
      "quantities": {
        "CARTONS": "Kutu sayısı",
        "CHEP_PALLETS": "CHEP palet sayısı",
        "CUBIC_METERS": "Metreküp değeri",
        "DISPOSABLE_PALLETS": "Kullan-at palet sayısı",
        "EURO_PALLETS": "EURO palet sayısı",
        "LOADING_PLACES": "Yükleme yeri sayısı",
        "PALLETS": "Palet sayısı",
        "PALLETS_WITH_RETURNED_GOODS": "İade edilen mallar içeren palet sayısı",
        "PALLET_PLACES": "Palet yeri sayısı",
        "RETURNABLE_PALLETS": "İade edilebilen boş palet sayısı",
        "SPATIAL_METERS": "Metrekare değeri",
        "THE_NUMBER_OF_ITEMS": "Öge sayısı",
        "shortName": {
          "CARTONS": "Koliler",
          "CHEP_PALLETS": "CHEP",
          "DISPOSABLE_PALLETS": "Kullan-at",
          "EURO_PALLETS": "EURO",
          "LOADING_PLACES": "Yükleme",
          "PALLETS": "Paletler",
          "PALLETS_WITH_RETURNED_GOODS": "İade edilenler",
          "PALLET_PLACES": "Yerler",
          "RETURNABLE_PALLETS": "İade edilebilenler",
          "THE_NUMBER_OF_ITEMS": "Ögeler"
        }
      },
      "quantity": "Palet sayısı",
      "quantity.placeholder": "ör. 33",
      "readyToPlan": "Planlamaya hazır",
      "recipient": "Alıcı",
      "relations": {
        "bind": "İlişki oluştur",
        "combineClearingAlert": "Seçilen nakliyeleri birleştirmek istediğinizden emin misiniz? Onaylanırsa seçilen nakliyelerin zaman dilimleri ve ihbar verileri silinecek.",
        "combinedTransports": "Birleştirilen nakliyeler: ",
        "combining": "\"Nakliyeler birleştiriliyor\" (TRANSPORT_COMBINING)",
        "defaultLabel": "Seçilen nakliyeleri şu ilişki türüyle birleştirebilirsiniz: ",
        "deletedSuccessfully": "Nakliye ilişkisi başarıyla kaldırıldı",
        "hasToWaitFor": "\"Nakliye şunu bekliyor:\" (HAS_TO_WAIT_FOR)",
        "mainTransport": "Ana nakliye: ",
        "maxWeight": "Maksimum ağırlık: ",
        "noTypeForSelection": "Üzgünüz, seçilen nakliyelerde ilişkiye izin verilmiyor",
        "overweight": "Maksimum ağırlık aşıldığı için nakliyeler birleştirilemiyor",
        "relatedTransports": "İlişkili nakliyeler: ",
        "removeRelation": "İlişkiyi sil",
        "title": "Nakliye ilişkileri"
      },
      "restoredSuccessfully": "Sipariş başarıyla geri yüklendi",
      "roleGroup": "User's role",
      "routeOffer": "Güzergah teklifi",
      "scheduledDateOfAdvice": "Planlanan ihbar tarihi",
      "selectCountry": "Ülke",
      "selectSupplier": "Tedarikçi \\\\ alıcı seç",
      "selectTransportType": "Tedarik türünü seçin",
      "selectUnloadStartDate": "En erken boşaltma tarihi",
      "selectUnloadStartDateTitle": "En erken boşaltma tarihini seçin.",
      "selectUnloadStopDate": "En geç boşaltma tarihi",
      "selectUnloadStopDateTitle": "En geç boşaltma tarihini seçin.",
      "selectWarehouse": "Depo seçin",
      "selectedCombinedTransportsWindows": "Nakliyelere atanan zaman dilimleri ",
      "selectedWindows": "{transportIdentifier} nakliyesine atanan zaman dilimleri",
      "serviceCompany": "Hizmet şirketi",
      "stepType": "Aşama türü",
      "sureToSaveMessage": "Değişiklikleri kaydetmek istediğinizden emin misiniz? Bazı alanlar, başka bir kullanıcı tarafından değiştirildi.",
      "task": "Görev",
      "title": "Nakliye {ident}",
      "tooManyCopies": "Aynı anda en fazla {maxValue} nakliye kopyası oluşturabilirsiniz",
      "transportRelation": "İlişki",
      "transportSupervisor": {
        "autoCompletePlaceholder": "İletişim kurulacak bir kişi aramak için yazmaya başlayın",
        "label": "İletişim kurulacak kişi"
      },
      "transportType": "Nakliye türü",
      "truck": "Nakliyat biçimi",
      "type": {
        "ALL": "Tümü",
        "COLLECTION": "Alım",
        "DELIVERY": "Teslimat",
        "SERVICE": "Kurye, hizmet vb.",
        "SIMPLIFIED_DELIVERY": "Basitleştirilmiş teslimat",
        "TRANSFER_BETWEEN_WAREHOUSES": "Depolar arası aktarım",
        "TRANSPORT": "Nakliye"
      },
      "typeOfService": "Courier's service",
      "unload": "Boşalt",
      "value": "Değer",
      "volume": "Hacim",
      "warehouse": "Depo",
      "wasMovedAutomatically": "Zaman dilimi otomatik olarak taşındı",
      "weight": "Ağırlık",
      "weight.placeholder": "ör. 23",
      "weightSection": {
        "downloadWeightReceipt": "Ağırlık makbuzunu indir",
        "entryWeight": "1. Tartım",
        "errors": {
          "cannotGetWeight": "Ne yazık ki ağırlığın indirilmesi mümkün değil. Lütfen daha sonra tekrar deneyin. Sorun devam ederse lütfen sistem yöneticisine ulaşın.",
          "trySaveCheckpointWithoutWeight": "Bu kontrol noktası için tartım gerekiyor. Ne yazık ki ağırlığın indirilmesi mümkün değil. 0 ağırlıklı kontrol noktasını onaylamak istiyor musunuz?"
        },
        "exitWeight": "2. Tartım",
        "label": "Ağırlık",
        "netWeight": "Net ağırlık",
        "notSynchronized": "Ağırlık sunucu ile senkronize edilmedi",
        "overweightMessage": "Yüklendiğinde araç maksimum ağırlığı aşacak. Bu araç yüklenmemelidir",
        "overweightNote": "Araç ayarlanan ağırlık sınırını aştı!",
        "unit": "kg",
        "weighAgain": "Tekrar tart",
        "weight": "ağırlık"
      },
      "wzDocument": "İrsaliye numarası",
      "wzNumber": "İrsaliye numarası"
    },
    "transport-type": {
      "errors": {
        "defaultLoadStepEndHour": {
          "regex": "Girilen değer HH:SS formatında bir saat olmalıdır"
        },
        "defaultLoadStepStartHour": {
          "regex": "Girilen değer HH:SS formatında bir saat olmalıdır"
        }
      }
    },
    "transportPanel": {
      "ADVICE": "İhbar",
      "DISCREPANCY_REPORT": "Sapma kaydı",
      "DOCUMENTS": "Belgeler",
      "INSPECTION": "İnceleme",
      "LOGS": "Değişiklik geçmişi",
      "WEIGHT": "Ağırlık",
      "selectPlaceholder": "Panel seçin"
    },
    "transportSupervisorTranslationKeys": {
      "CONTACT_PERSON": "İletişim kurulacak kişi",
      "HOST_PERSON": "Ağırlayan kişi",
      "INVITEE": "Davetli",
      "ORDERER": "Sipariş veren",
      "PERSON_TO_NOTIFY": "Bilgilendirilecek kişi"
    },
    "transportsToExcel": {
      "XLSGenerateMessage": "Oluşturulan sipariş raporu, raporu oluşturan kişinin e-posta adresine gönderilir.",
      "activeArchiveType": "Etkin/arşivlenmiş siparişler",
      "advancedExportType": "Gelişmiş",
      "advancedExportTypeDescription": "Gelişmiş sürümde iki sayfa vardır. İlk sayfada, nakliye durumu hakkındaki bilgiler hariç olmak üzere, Temel sürümdekiyle aynı veriler bulunur. İkinci sayfada, durum onaylarıyla ilgili tüm bilgiler yer alır.",
      "adviceReportLabel": "Bu sürümde ihbar raporu bulunur. Rapor şu türlerde olabilir: ",
      "adviceReportLabelEmpty": "Nakliye türü yok",
      "adviceReportType": "İhbar raporu",
      "alsoExportActiveOrders": "Etkin siparişleri de dışa aktar",
      "basicExportType": "Temel",
      "basicExportTypeDescription": "Temel sürümde tüm veriler tek sayfadadır.",
      "billingExportType": "Faturalandırma raporu",
      "billingExportTypeDescription": "Bu sürümde faturalandırma verileri bulunur.",
      "errors": {
        "DaysLimitExceededMessage": "Hata. Rapor oluşturulamadı. Rapor oluşturma için izin verilen tarih aralığı aşıldı.",
        "XLSGenerateEmailErrorMessage": "Sipariş raporu, raporu oluşturan kişinin e-posta adresine gönderildi; lütfen Hesabım sekmesinde e-posta adresini tamamlayın.",
        "XLSGenerateErrorMessage": "Hata. Rapor oluşturulamadı. Lütfen daha sonra tekrar deneyin veya info@logintegra.com adresi üzerinden iletişime geçin."
      },
      "export": "Dışa Aktar",
      "exportType": "Dosya sürümü",
      "noTypeChosen": "Lütfen en az bir nakliye türü seçin",
      "ownColumnExportType": "Tablodaki sütunlar",
      "ownColumnExportTypeDescription": "Tablo sütunu sürümünde yalnızca nakliye tablosunda görünen bilgiler bulunur",
      "title": "Excel dosyası parametreleri",
      "transportTypes": "Sipariş türleri"
    },
    "truck": {
      "addTruck": "Bir kamyon ekle",
      "description": "Açıklama",
      "disableConfirm": "Bu kamyonu kaldırmak istediğinizden emin misiniz?",
      "errors": {
        "registrationNumber": {
          "regex": "Ruhsat numarası, 4 ila 50 karakter uzunluğunda olmalıdır ve yalnızca harf, sayı ve şu karakterleri içerebilir: , ; / \\\\ & _"
        },
        "trailer": {
          "regex": "Ruhsat numarası, 4 ila 50 karakter uzunluğunda olmalıdır ve yalnızca harf, sayı ve şu karakterleri içerebilir: , ; / \\\\ & _"
        }
      },
      "hds": "HDS",
      "label": "Kamyonlar",
      "lastUpdated": "Son güncelleme",
      "registrationNumber": "Ruhsat numarası",
      "timeWindow": "Zaman dilimi",
      "trailer": "Römork/Yarı Römork",
      "truckType": "Tür"
    },
    "trucks": {
      "label": "Kamyonlar"
    },
    "unit": {
      "KG": "kg",
      "LITERS": "litre",
      "PIECES": "parça",
      "label": "Birim",
      "placeholder": "Bir birim seçin"
    },
    "user": {
      "accountCreatedBy": "Hesabı oluşturan:",
      "accountUpdated": "Hesap ayarlarındaki değişiklikler kaydedildi.",
      "activeNotifications": "Etkin bildirimler",
      "availableQueues": "Kullanılabilecek kuyruklar",
      "availableRamps": "Kullanılabilecek rampalar",
      "availableWarehouses": "Kullanılabilecek depolar",
      "contractorGroupName": "Grup",
      "createUser": "Kullanıcı oluştur",
      "deleteConfirm": "Bu kullanıcıyı silmek istediğinizden emin misiniz?",
      "deletedMessage": "{date} tarihinde, {company} şirketindeki {person} tarafından silindi.",
      "deletedUser": "Kullanıcı kaldırıldı",
      "disableConfirm": "Bu kullanıcıyı devre dışı bırakmak istediğinizden emin misiniz?",
      "disabledUser": "Kullanıcı devre dışı bırakıldı",
      "editUser": "Kullanıcıyı düzenle",
      "email": "E-posta",
      "errors": {
        "mobilePhoneNumberLengthError": "Telefon numarası, 9 rakamdan oluşmalıdır.",
        "mobilePhoneNumberRegexError": "Telefon numarası, yalnızca rakamlardan oluşabilir."
      },
      "groupName": "Bölüm",
      "hasRoleAccessAllRamp": "Yöneticilerin \"Plandaki tüm rampalara/dilimlere erişim\" izni vardır. Bu görünümdeki kullanılabilecek rampa seçeneklerinden bağımsız olarak, kullanıcı tüm rampalara erişebilecek.",
      "invitedBy": "Davet edildi: {date} tarihinde {person} tarafından.",
      "lastLoginDate": "Son oturum açma tarihi",
      "ldapUser": "LDAP entegrasyonu için kullanıcı",
      "limitedDataHint": "{user} kullanıcısının bazı ayrıntılarının görüntülenmesi ve düzenlenmesi sınırlanmış; bunlar yalnızca {company} şirketindeki Yöneticiler tarafından yönetilebilir.",
      "list": "Kullanıcı listesi",
      "localAdmin": "Yerel yönetici",
      "mobilePhoneNumber": "Telefon",
      "myAccount": "Hesabım",
      "name": "Ad ve soyadı",
      "noActiveNotifications": "Etkin bildirim yok",
      "noAvailableRamps": "Kullanılabilecek rampa yok",
      "registrationDate": "Kayıt tarihi",
      "roleDescription": "Rolün açıklaması",
      "selectRole": "Rol seç",
      "status": {
        "ACTIVE": "Etkin",
        "DELETED": "Silindi",
        "EXPIRED": "Etkin değil",
        "TEMPORARY": "Geçici",
        "label": "Kullanıcı durumu"
      },
      "temporaryAccountDaysLeft": "Blokaja kadar olan gün sayısı",
      "userGroup": {
        "allTransports": "Tüm nakliyeler",
        "myGroupsTransports": "Grubumun'nakliyeleri",
        "myTransports": "Nakliyelerim"
      },
      "username": "Oturum aç"
    },
    "userGroups": {
      "canSeeOtherGroupsTransports": "Diğer grupların nakliyelerine' erişim",
      "identifier": "Kimlik",
      "label": "Kullanıcı grupları",
      "name": "Ad"
    },
    "users": {
      "activity": "Etkinlik",
      "data": "Kullanıcı'için veriler",
      "label": "Kullanıcılar",
      "notifications": "Bildirimler",
      "queues": "Kuyruk erişimi",
      "ramps": "Rampa/dilim erişimi"
    },
    "warehouse": {
      "addRamp": "Rampa ekle",
      "adviceHasBeenSavedInfo": "İhbar şu saate kaydedildi: ",
      "autoCompletePlaceholder": "Depo, sokak vb. aramak için yazmaya başlayın",
      "backToDeliveries": "Teslimat tablosu",
      "configureAlgorithmAfterSave": "Zaman dilimi uzunluğunu hesaplama algoritması, depo kaydedildikten sonra ayarlanabilir.",
      "configureAssortmentGroupsAfterSave": "Tasnif grupları, depo kaydedildikten sonra ayarlanabilir.",
      "confirmDeleteDedicateWindow": "{company} şirketi için özel dilimi kaldırmak istediğinizden emin misiniz?",
      "create": "Depo oluştur",
      "createRampsAfterSave": "Depolama bölgeleri, konumlar ve rampalar, depo kaydedildikten sonra yapılandırılabilir.",
      "createTransportInfo": "Bir nakliye türü oluşturmak için planda bir boşluk seçin: {transportTypeName}.",
      "disableConfirm": "Bu depoyu devre dışı bırakmak istediğinizden emin misiniz?",
      "disabledMessage": "\"{warehouseName}\" deposunu başarıyla sildik.",
      "enableConfirm": "Bu depoyu etkinleştirmek istediğinizden emin misiniz?",
      "errors": {
        "idx": {
          "invalidIdxNumber": "Deponun kimlik numarası 0 ile {numberOfWarehouses} arasında olmalıdır"
        },
        "snapToClosestWindowInterval": {
          "info": "Zaman diliminin otomatik olarak yaslanması için kenarlar arasındaki maksimum mesafeyi girin. Olası değerler 0, 15, 30 veya 60'tır."
        },
        "timeWindowOffsetInterval": {
          "info": "Dilimlerin taşınabileceği dakika sayısını girin."
        },
        "windowConstantTime": {
          "info": "Zaman dilimlerindeki boşaltma uzunluğuna eklenecek sabit değeri dakika olarak girin."
        },
        "workEndHour": {
          "endBeforeStart": "Deponun kapanış saati, açılış saatinden sonra olmalıdır.",
          "invalidNumber": "Lütfen 1 ile 24 arasında bir sayı seçin."
        },
        "workStartHour": {
          "invalidNumber": "Lütfen 0 ile 23 arasında bir sayı seçin."
        }
      },
      "hdOff": "HD özelliğini kapat",
      "hint": "Teslimat zamanını seçmek için lütfen plandaki boşluğa tıklayın.",
      "idx": "Deponun kimlik numarası.",
      "indexesDetailsTitle": "Dizinleri görmek için tıklayın",
      "integrationCode": "MSP entegrasyonu için depo kodu",
      "label": "Depo",
      "numberOfRamps": "Rampa sayısı",
      "reserveFreePlace": "{initialStart} için planda bir boşluk ayırın",
      "selectCompany": "Bir boşluğa tıkladıktan sonra şirket için özel bir dilim oluşturun",
      "selectCountry": "Ülke seç",
      "selectDepartment": "veya bölüm seçin",
      "selectNewPlaceForWindow": "Seçilen zaman dilimi için yeni bir yer seçin",
      "selectWindowInfo": "{identifier} nakliyesine bir ihbar eklemek için planda bir boşluk seçin.",
      "selectWindowWarning": "En geç {date} tarihine bir ihbar planlayabilirsiniz.",
      "selectedWindowsCombinedTransports": "Birleştirilen nakliyelere bir ihbar eklemek için planda bir boşluk seçin",
      "shouldFindFreeSpotAfterDelay": "Belirtilen aşamada bir gecikme halinde zaman diliminin aktarımı",
      "slider": {
        "title": "Plandaki satırları yeniden boyutlandır"
      },
      "snapToClosestWindowInterval": "Zaman diliminin otomatik olarak yaslanması için kenarlar arasındaki maksimum mesafe. Yalnızca bir dilimini yaslarız -> yeni olarak yerleştirdiğimiz dilimi.",
      "sumOfPallets": "Toplam palet",
      "templates": {
        "copyFrom": "Şu günden sonraki şablonları kopyala:",
        "datesWithErrorMessage": "Not: Zaman değişikliği nedeniyle şu günlerdeki zaman dilimlerinin bazılarını kaydedemedik: {windows}.",
        "header": "Depo için zaman dilimi şablonları",
        "label": "Şablonlar",
        "saveFor": "şunun için şablonu kaydet:",
        "savedMessage": "Şablon başarıyla kaydedildi."
      },
      "timeWindowDefaultLengthInMinutes": "Zaman dilimlerinin dakika cinsinden varsayılan boyutu",
      "timeWindowLengthLongerThanWarehouseWorkingHours": "Zaman dilimi, deponun çalışma saatlerinden daha uzun olabilir",
      "timeWindowMustOccupyEntireRamp": "Zaman dilimi, seçilen gündeki rampanın tamamını kapsamalıdır",
      "timeWindowMustOccupyEntireRampDisclaimer": "Bu depodaki zaman dilimi rampanın tamamını kapsar",
      "timeWindowOffsetInterval": "Zaman dilimlerinin ayarlanabileceği dakika sayısı",
      "timeWindowSummaryInfo": "Select a free spot in the schedule to add",
      "timeWindowSummaryInfoSubtitle": "Nakliye için {timeWindowsNumber} zaman dilimi eklenmesi gerekiyor",
      "transportTypes": "Deponun kullanılabileceği nakliye türleri",
      "updateSuccessMessage": "{warehouseName} deposunun ayarları güncellendi.",
      "warehouseLocationAdd": "Depo konumu ekle",
      "warehouseLocationLabel": "Depo konumu",
      "warehouseLocationLabelPlural": "Depo konumları",
      "warehouseLocationNewLocation": "Yeni depo konumu",
      "warehouseLocationNextLocation": "Farklı bir depo konumuna taşınıyor?",
      "weighingIncluded": "Tartım işlemi bu depoda yapılır",
      "windowConstantTime": "Zaman dilimlerindeki boşaltma uzunluğuna eklenen, dakika cinsinden sabit değer.",
      "windowIsLoad": "Y",
      "windowIsUnload": "B",
      "windowNoteLabel": "Not içeriği",
      "windowNoteRemoveWarning": "Şu notu içeren dilimi silmek istiyor musunuz: {note}?",
      "windowNoteTitle": "Notunuzu girin",
      "windowRemoveWarning": "Blokaj penceresini kaldırmak istediğinizden emin misiniz?",
      "workEndHour": "Kapanış saati",
      "workStartHour": "Açılış saati",
      "workTime": "Depo çalışma saatleri",
      "youAreEditingThisWindow": "bu zaman dilimini düzenliyorsunuz",
      "youAreInWarehouse": "Bir depodasınız",
      "youWereMovedToWarehouse": "Depoya yönlendirildiniz"
    },
    "warehouseZone": {
      "addZone": "Depo bölgesi ekle",
      "algorithmSettings": "Bir zaman diliminin hesaplanması için kullanılan algoritma",
      "deliveryDefaultZone": "[DELIVERY] Herhangi bir kalıba uymayan nakliye aracının yönlendirildiği varsayılan bölge",
      "deliveryModeOfTransport": "[DELIVERY] Noktalı virgülle ayrılmış olarak, bu depolama bölgesine yönlendirilen nakliye aracı, ör.: Yüksek Brandalı;Standart Brandalı;Standart Brandalı EXW",
      "disableConfirm": "Bu bölgeyi devre dışı bırakmak istediğinizden emin misiniz?",
      "englishName": "İngilizce bölge adı",
      "errors": {},
      "hint": "{warehouseZoneLabel} - zaman dilimini planda taşıyarak bunu değiştirebilirsiniz.",
      "idx": "Bölgenin dizisi",
      "label": "Depo bölgesi",
      "noTransportTypesAssigned": "Atanmış depo bölgesi yok. Nakliye türleri, depo bölgesi ayrıntılarından depo bölgesine atanabilir.",
      "noTransportTypesSelected": "Depo bölgesini nakliye türü atanmamış olarak bırakmak istediğinizden emin misiniz? Bu bölgeye ihbar eklemek mümkün olmayacak.",
      "selectWarehouse": "{warehouseZoneLabel} - lütfen önce bir {warehouseLabel} seçin",
      "settings": "Bölge ayarları",
      "transportTypes": "Depo bölgesinin kullanılabileceği nakliye türleri",
      "updateSuccessMessage": "Bölgenin ayarları uygun şekilde değiştirildi"
    },
    "warehouses": {
      "label": "Depolar"
    },
    "warehousesToExcel": {
      "label": "Depolar",
      "placeholder": "Dışa aktarılacak depoyu seçin",
      "title": "Excel dosyası parametreleri"
    },
    "weightLog": {
      "ENTRY_WEIGHT": "Girişte tartım",
      "ENTRY_WEIGHT_AGAIN": "Girişte tartım (yeniden tartım)",
      "EXIT_WEIGHT": "Çıkışta tartım",
      "EXIT_WEIGHT_AGAIN": "Çıkışta tartım (yeniden tartım)"
    },
    "worker": {
      "GUESTS": "Misafirler",
      "SERVICEMEN": "Hizmet görevlileri",
      "WORKERS": "Çalışanlar",
      "addGuest": "Yeni misafir ekle",
      "addServiceman": "Yeni hizmet görevlisi ekle",
      "addWorker": "Yeni çalışan ekle",
      "autoCompletePlaceholder": "Bir çalışanı bulmak için yazmaya başlayın",
      "chooseWorkers": "Çalışan seç",
      "deleteWorkerMsg": "Bu çalışanı silmek istediğinizden emin misiniz?",
      "editGuest": "Misafiri düzenle",
      "editServiceman": "Hizmet görevlisini düzenle",
      "editWorker": "Çalışanı düzenle",
      "errors": {
        "phoneNumber": {
          "regex": "Numara 9 ila 12 basamaklı olmalıdır"
        }
      },
      "name": "Ad ve Soyadı",
      "phoneNumber": {
        "GUESTS": "Misafirin telefon numarası",
        "SERVICEMEN": "Hizmet görevlisinin telefon numarası",
        "WORKERS": "Çalışanın telefon numarası"
      },
      "trained": "Eğitimli",
      "workersList": "Çalışan listesi"
    },
    "workflows": {
      "addSubTask": "Bir alt görev ekle",
      "addTask": "Bir görev ekle",
      "finalStageNamesLabel": "Tüm aşamalar onaylandıktan sonra nakliyenin durumu şu şekilde değişmeli:",
      "label": "İş akışı (aşama ve durum ayarları)",
      "noTasks": "İşlemin bu kısmına görev atanmadı",
      "saveSuccessMessage": "Aşama ve durum (iş akışı) ayarlarını başarıyla kaydettik.",
      "updateDisclaimer": "Not: Zamanla ilgili değişiklikler yalnıza YENİ nakliyelere uygulanır."
    },
    "wrongUrl": "Erişim yok veya istediğiniz sayfa yok",
    "yolsuzlukla-mücadele-kısmı": {
      "errors": {
        "signedFiles": "Bir doğrulama talebi göndermek için imzalı yolsuzlukla mücadele beyanını eklemelisiniz"
      },
      "internalNotes": "Notlar",
      "patternFileDescription": "Form şablonu",
      "signedFile": "İmzalı dosyayı ekle",
      "statementFileDescription": "Beyan {company} tarafından indirilmek üzere imzalandı"
    },
    "yüklenici-grubu": {
      "addGroup": "Grup ekle",
      "description": {
        "details": "Ayrıntılar",
        "ifYouDoNotHaveDocument": "Zorunlu bir belgeniz yoksa seçilen grubu devre dışı bırakın.",
        "label": "Grup seçmek için uygun yetki belgesinin ve sigorta belgelerinin eklenmesi gerekir",
        "notApplicable": "Geçerli değil",
        "optionalDocuments": "ve bu belgelerden biri:"
      },
      "errors": {
        "obligatoryDocuments": "{groupName} grubuna girmesi için şirketinizde bu belgeler olmalıdır: {documents}.",
        "optionalDocuments": "{groupName} grubuna girmesi için şirketinizde bu belgelerden en az biri olmalıdır: {documents}."
      },
      "label": "Gruplama",
      "name": "Ad",
      "nameEn": "İngilizce ad",
      "selectADRInfo": "Bu seçeneği işaretledikten sonra şirket, tehlikeli maddelerin nakliyatına yönelik siparişleri yerine getirebilecek Taşıyıcılar grubuna katılacak. Şirketinizin tehlikeli madde taşıma yetkisi olduğundan emin olun.",
      "shortName": "Kısa ad",
      "shortNameEn": "İngilizce kısa ad",
      "unSelectADRInfo": "Bu seçeneğin işaretini kaldırdıktan sonra şirket, tehlikeli maddelerin nakliyatına yönelik siparişleri yerine getirebilecek Taşıyıcılar grubundan çıkarılacak. ADR grubundan çıkmak istediğinizden emin misiniz?"
    },
    "yüklenici-grubu-türü": {
      "ADR": "Mal ve tehlikeli yük nakliyesine göre ayrım",
      "AVAILABLE_VEHICLES": "Mevcut araçlara göre ayrım",
      "PRIVATE": "Özel gruplar; yükleniciye görünmez",
      "SUPPORTED_REGIONS": "Desteklenen bölgelere göre ayrım",
      "SUPPORTED_VOIVODESHIPS": "Desteklenen voyvodalığa göre ayrım",
      "TERRITORIAL_DIVISION": "Bölgesel ayrım",
      "TYPES_OF_BUSINESS": "İşletme faaliyeti türüne göre ayrım",
      "TYPES_OF_TRANSPORT": "Desteklenen nakliye türlerine göre ayrım",
      "errors": {
        "canSelectOnlyOneContractorsGroup": "Lütfen yalnızca bir grup seçin",
        "requiresContractorsGroup": "Lütfen \"{groupType}\" kısmından en az bir grup seçin"
      }
    },
    "zaman-dilimi": {
      "details": {
        "carRegistrationNumber": "Kamyon",
        "erpIdentifier": "Sipariş no.",
        "trailerRegistrationNumber": "Römork/Yarı Römork"
      },
      "errors": {
        "start": {
          "isInPast": "Geçmişte bir nakliye planlayamazsınız.",
          "outsideOfAdviceRange": "Bu tarihi seçemezsiniz çünkü bu durumda zaman dilimi ihbar zaman aralığının dışında kalır."
        },
        "stop": {
          "earlierThanStart": "Zaman diliminin bitişi başlangıcından sonra olmalıdır.",
          "outsideOfAdviceRange": "Bu tarihi seçemezsiniz çünkü bu durumda zaman dilimi ihbar zaman aralığının dışında kalır."
        }
      },
      "start": "Dilim süresi"
    },
    "özel-alan-değeri": {
      "autoCompletePlaceholder": "Bir seçenek aramak için yazmaya başlayın.",
      "errors": {
        "maxValue": {
          "value": "Maksimum değer {maxValue}"
        },
        "minValue": {
          "value": "Minimum değer {minValue}"
        },
        "value": {
          "notProperValueType": "Yanlış değer türü; bir numara gerekiyor"
        }
      }
    },
    "özel-alan-seçeneği": {
      "add": "Bir seçenek ekle",
      "dependenciesHint": "Lütfen seçilen anahtarın bağlı olacağı özel alanı ve seçenekleri seçin",
      "editDependencies": "Bağlılıkları düzenle",
      "errors": {},
      "optionKey": "Değer - anahtar",
      "relatedDefinition": "Özel alan",
      "relatedOptions": "İlişkili seçenekler",
      "remove": "Bir seçeneği kaldır"
    },
    "özel-alan-tanımı": {
      "addNewField": "Yeni bir alan ekle",
      "amountOfDelayedTimeForTaskToBeRequired": "Alanın zorunlu olması için görev gecikmesinin süresi",
      "autocompleteEnabled": "Otomatik doldurma etkin",
      "cancel": "Güncellemelerinizi iptal etmek istediğinizden emin misiniz?",
      "create": "Bir form alanı tanımı oluştur",
      "defaultOption": {
        "hint": "Verilen seçenek varsayılan seçilen değer olur",
        "label": "Varsayılan seçeneğin adı",
        "placeholder": "Kullanılabilecek seçeneklerden birini seçin",
        "title": "Varsayılan seçenek"
      },
      "delete": "Bu alanı silmek istediğinizden emin misiniz?",
      "deleteSuccessMessage": "Alan ayarını başarıyla sildik.",
      "deliveryAlias": "MSP entegrasyonu için rumuz",
      "englishName": "İngilizce ad",
      "errors": {
        "maxValue": {
          "dotSeparator": "Ondalık ayracı bir nokta \".\" olmalıdır",
          "maxLowerThenMin": "Maksimum değer, minimum değerden küçük olamaz"
        },
        "minValue": {
          "dotSeparator": "Ondalık ayracı bir nokta \".\" olmalıdır",
          "minHigherThanMax": "Minimum değer, maksimum değerden büyük olamaz"
        },
        "numberDecimalFormat": {
          "regex": "Numara formatı yalnızca şu karakterleri içerebilir: # , . 0 i _"
        },
        "obligatory": {
          "invalid": "\"Zorunlu\" alanını seçerseniz aşağıdaki seçeneklerden en az birini seçin: \"Kontrol noktası onaylanırken ve sonrasında bir alanın zorunluluğu\", \"Nakliye oluşturma sırasında görünür\""
        },
        "options": {
          "empty": "Lütfen en az bir seçeneği belirleyin"
        },
        "textAreaRowsNumber": {
          "textAreaSize": "Satır sayısı 0'dan büyük olmalıdır"
        }
      },
      "filterInArchive": "Arşive filtreleri uygula",
      "filterLabel": "Özel filtreler:",
      "idx": "Idx",
      "includedInPriceList": "Fiyat listesine ekle",
      "makeFieldDependentOn": {
        "definitionPlaceholder": "Bağlı bir alan için bir ad seçin",
        "dependsOnTaskInfo": "Seçilen alanda atanmış kontrol noktası var",
        "fieldCompleted": "Alan dolduruldu",
        "hint": {
          "obligatory": "Koşul karşılanırsa alan görünür ve zorunlu olur",
          "voluntary": "Koşul karşılanırsa alan görünür ve isteğe bağlı olur"
        },
        "title": "Bir alanın görünürlüğünü başka bir alanın değerine bağlı kıl"
      },
      "maxValue": "İzin verilen maksimum değer:",
      "minValue": "İzin verilen minimum değer:",
      "name": "Ad",
      "noTaskSelected": "Görevden bağımsız",
      "numberDecimalFormat": {
        "comma": "Gruplama ayracı",
        "dot": "Ondalık ayracı veya para için ondalık ayracı",
        "hash": "Rakam; sıfır, mevcut olmadığını gösterir",
        "label": "Numara formatı",
        "meaning": "Anlamı",
        "minus": "Eksi işareti",
        "symbol": "Simge",
        "zero": "Rakam"
      },
      "obligatory": "Zorunlu",
      "order": "(idx) siparişini göster",
      "requiredInDelayedTransport": "Nakliye gecikmesi halinde zorunlu",
      "requiredTaskInDelayedTransport": "Nakliyenin gecikmesi halinde görev onayı sırasında zorunlu alan",
      "saveSuccessMessage": "Alan ayarlarını başarıyla kaydettik.",
      "saveSuccessMessageForTimeWindow": "Alanların ayarlarını başarıyla kaydettik. Değişiklikler birkaç dakika içinde görünecek.",
      "settingsFields": "Alan ayarları",
      "side": "Bir formdaki alan sayısı {number} özel alanı aşarsa alanın görüneceği taraf",
      "sideLeft": "Sol",
      "sideRight": "Sağ",
      "sideShort": "Taraf",
      "task": "Görev onayı sırasında ve sonrasında zorunlu olan bir alan",
      "taskHint": "Şu alan zorunluluğunu ayarlamak için: \"Görevden bağımsız\", yukarıda seçilen görevin yanındaki çarpı işaretine tıklayın",
      "textAreaRowsNumber": "Alan için satır sayısı",
      "type": {
        "date": "Tarih",
        "datetime": "Tarih ve saat",
        "number": "Numara",
        "select": "Seç",
        "text": "Metin",
        "text_area": "Metin alanı"
      },
      "typeField": "Alan türü",
      "visibilityDependsOnDefinition": "Bağlı bir alanın adı",
      "visibilityDependsOnOption": "Bağlı bir alanın değeri",
      "visibleDuringTransportCreation": "Nakliye oluşturma sırasında görünür",
      "visibleInAdviceSection": "İhbar formunda görünür",
      "visibleInDeliveryDocument": "İrsaliyede görünür",
      "visibleInDiscrepancyReportSection": "\"Sapma Kaydı\" kısmında görünür",
      "visibleInTable": "Tabloda görünür",
      "visibleInTransportWeightReceipt": "Nakliye ağırlık makbuzunda görünür",
      "visibleInWeightSection": "\"Tartım\" kısmında görünür",
      "visibleOnTimeWindow": "Bir zaman diliminde görünür"
    }
  };
  _exports.default = _default;
});